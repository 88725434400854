import React, { useEffect, useState } from "react";
import classes from "./About.module.css";
import coinIcon from '../../../../Assets/Logo/coin-icon.svg';
import languageIcon from '../../../../Assets/Logo/language-icon.svg';
import nameEdit from '../../../../Assets/Logo/content-edit-icon.svg';
import locationIcon from '../../../../Assets/Logo/location-icon.svg';
import contactIcon from '../../../../Assets/Logo/contact-icon.svg';
import birthIcon from '../../../../Assets/Logo/birth-icon.svg';
import statusIcon from '../../../../Assets/Logo/status-icon.svg';
import starIcon from '../../../../Assets/Logo/star-icon.svg';
import genderIcon from '../../../../Assets/Logo/gender-icon.svg';
import calenderIcon from '../../../../Assets/Logo/calender-icon.svg';
import graduationCap from '../../../../Assets/Logo/graduation-cap-icon.svg';
import programIcon from '../../../../Assets/Logo/program-icon.svg';
import lineIcon from '../../../../Assets/Logo/line-hr.svg';
// import deleteICon from '../../../../Assets/Logo/delete-icon.svg';
import jobTitleIcon from '../../../../Assets/Logo/job-title-icon.svg';
import upperArrowICon from '../../../../Assets/Logo/uper-arrow-icon.svg';
import dropArrowICon from '../../../../Assets/Logo/drop-down_icon.svg';
import emailIcon from '../../../../Assets/Logo/email-icon.svg';
import { Loader } from "react-bootstrap-typeahead";
import ApplicationService from "../../../../Services/ApplicationServices";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import ProfileService from "../../../../Services/ProfileService";

const skills=["Design", "Graphics", "Printing"]
const competencies=["Creativity and Innovation: 5", "Creativity and Innovation: 6"]

function About({rowData}) {
 
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (id) => {
    setOpenDropdown(prevOpenDropdown => prevOpenDropdown === id ? null : id);
  };
  
  const [openDropdownForCompany, setOpenDropdownForCompany] = useState(null);

  const toggleDropdownForCompany = (id) => {
    setOpenDropdownForCompany(prevOpenDropdown => prevOpenDropdown === id ? null : id);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [interests, setInterests] = useState([]);
  const [competencies, setCompetencies] = useState();
  const [skills, setSkills] = useState([]);
  const { studentId } = useParams();
  const [educationData, setEducationData] = useState([]);
  const getInterestsInfo = (studentId) =>{
    setIsLoading(true)
    ProfileService.getInterestsInfo(studentId).then((res)=>{
        setInterests(res);
        setIsLoading(false);
    }).catch((error)=>{
      console.log('error: ', error);
      setIsLoading(false);
    });
  }  
  const getCompetencyInfo = () =>{
    setIsLoading(true)
    ProfileService.getCompetencyInfo(studentId).then((res)=>{
        setCompetencies(res);
        setIsLoading(false);
    }).catch((error)=>{
      console.log('error: ', error);
      setIsLoading(false);
    });
  }
  const getSkills = () => {
    setIsLoading(true);
    ProfileService.getStudentSkills(studentId)
      .then((res) => {
        if (res.length > 0) {
          setSkills(res);
          setIsLoading(false);
        }
        
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  };
  const getSchoolDetailList = () => {
    let data = {
      ParentType: 6,
      ParentId: studentId,
    };
    ApplicationService.getSchoolDetailList(data)
      .then((res) => {
        setEducationData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [workData, setWorkData] = useState([]);
  const getWorkExperienceList = () => {
    setIsLoading(true);
    ProfileService.getWorkExperienceList(studentId)
      .then((res) => {
        const mappedWorkData = res.map((item) => ({
          id: item.StudentWorkExpID,
          company: item.CompanyName,
          jobTitle: item.JobTitle,
          start: item.StartDate,
          end: item.EndDate,
          location: item.CountryName,
          countryId: item.CountryID,
        }));
        setWorkData(mappedWorkData);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  };
  const formatBirthdayDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
  
    return `${day} ${month} ${year}`;
  };

  const birthday = formatBirthdayDate(rowData.DateOfBirth);
  const age = calculateAge(rowData.DateOfBirth);
  function calculateAge(dateOfBirth) {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  const getCountryList = () => {
    setIsLoading(true)
    ApplicationService.getCountryList()
      .then((res) => {
        setIsLoading(false)
        setCountryList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };
  useEffect(()=>{
    getCountryList();
    getSchoolDetailList();
    getWorkExperienceList();
    getInterestsInfo(studentId);
    getCompetencyInfo();
    getSkills();
  },[]);
  return (
    <>
{!rowData && (
  <Loader></Loader>
)}
    <div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
          <div>
            <p className={classes["heading-text"]}>Overview</p>
          </div>
          <div className={classes["icon-alignment"]}>
          <div>
            <span><img className={classes["over-view-icon"]} src={nameEdit} alt=''></img></span>
            <span className={classes["grey-text"]}>{rowData.FirstName?rowData.FirstName:'N/A'}</span>
          </div>
          <div>
          
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div>
            <span><img className={classes["over-view-icon"]} src={nameEdit} alt=''></img></span>
            <span className={classes["grey-text"]}>{rowData.LastName?rowData.LastName:'N/A'}</span>
          </div>
          <div>
          
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div>
            <span><img className={classes["over-view-icon"]} src={emailIcon} alt=''></img></span>
            <span className={classes["grey-text"]}>{rowData.Email?rowData.Email:'N/A'}</span>
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div>
            <span><img className={classes["over-view-icon"]} src={nameEdit} alt=''></img></span>
            <span className={classes["grey-text"]}>{rowData.TagLine?rowData.TagLine:'N/A'}</span>
          </div>
          <div>
          
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div>
            <span><img className={classes["over-view-icon"]} src={birthIcon} alt=''></img></span>
            <span className={classes["grey-text"]}>{birthday?birthday:'N/A'}</span>
          </div>
          <div>
          <span className={classes["coin-count"]}>5</span>
          <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span>
          
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div>
            <span><img className={classes["over-view-icon"]} src={calenderIcon} alt=''></img></span>
            <span className={classes["grey-text"]}>{age ? age:'N/A'}</span>
          </div>
          <div>
          <span className={classes["coin-count"]}>5</span>
          <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span>
          
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div>
            <span><img className={classes["over-view-icon"]} src={genderIcon} alt=''></img></span>
            <span className={classes["grey-text"]}>{rowData.Gender?rowData.Gender:'N/A'}</span>
          </div>
          <div>
          
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div>
            <span><img className={classes["over-view-icon"]} src={statusIcon} alt=''></img></span>
            <span className={classes["grey-text"]}>{rowData.MaritialStatus?rowData.MaritialStatus:'N/A'}</span>
          </div>
          <div>
          
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div className="col-md-4">
            <span><img className={classes["over-view-icon"]} src={locationIcon} alt=''></img></span>
            <span className={classes["black_text"]}>Origin : </span>
            <span className={classes["grey-text"]}>{rowData.CountryName ? rowData.CountryName:'N/A'}</span>
            
          </div>
          <div className="col-md-4">
          <span className={classes["grey-text"]}>{rowData.ProvinceName ? rowData.ProvinceName:'N/A'}</span>
          </div>
          <div className="col-md-4 text-end">
          <span className={classes["grey-text"]}>{rowData.City ? rowData.City:'N/A'}</span>
          <span className={classes["coin-count"]}>5</span>
          <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span>
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div className="col-md-4">
            <span><img className={classes["over-view-icon"]} src={locationIcon} alt=''></img></span>
            <span className={classes["black_text"]}>Residence : </span>
            <span className={classes["grey-text"]}>{countryList.find((country) => country.CountryId === rowData.MailingCountry)?.CountryName}</span>
            
          </div>
          <div className="col-md-4">
          <span className={classes["grey-text"]}>{rowData.MailingProvinceName ? rowData.MailingProvinceName:'N/A'}</span>
          </div>
          <div className="col-md-4 text-end">
          <span className={classes["grey-text"]}>{rowData.MailingCity ? rowData.MailingCity:'N/A'}</span>
          <span className={classes["coin-count"]}>5</span>
          <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span>
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div>
            <span><img className={classes["over-view-icon"]} src={contactIcon} alt=''></img></span>
            <span className={classes["grey-text"]}>Contact</span>
          </div>
          <div>
          
          </div>
          </div>
        </div>
      </div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
          <div className={classes["heading-align"]}>
            <div>
            <p className={classes["heading-text"]}>About</p>
            </div>
            <div>
                
            </div>
          </div>
          <div className={classes["about-section"]}>
          <p className={classes["about-section-text"]}>
            {rowData.About?rowData.About:'N/A'}
            </p>
          </div>
        </div>
      </div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
          <div>
            <p className={classes["heading-text"]}>Language</p>
          </div>
          <div>
         <div className={classes["icon-alignment"]}>
          <div>
            <span><img  className={classes["languageIcon-image-size"]} src={languageIcon} alt=''></img></span>
            <span className={classes["blank-content-text"]}>
                  {rowData.Language ? rowData.Language : 'N/A'}
                </span> 
                <span className={classes["star-mark"]}>*</span>
          </div>
          <div>
          <span className={classes["coin-count"]}>5</span>
          <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span>
          
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div>
            <span><img  className={classes["languageIcon-image-size"]} src={languageIcon} alt=''></img></span>
            <span className={classes["blank-content-text"]}>
                  {rowData.SecLanguage ? rowData.SecLanguage : 'N/A'}
                </span>
          </div>
          <div>
          <span className={classes["coin-count"]}>5</span>
          <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span>
          
          </div>
          </div>
          </div>
        </div>
      </div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
        <div className={classes["heading-align"]}>
            <div>
            <p className={classes["heading-text"]}>Education</p>
            </div>
            <div>
                <span className={classes["coin-count"]}>5</span>
                <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span>
                
            </div>
          </div>
            <div>
            {educationData.map((school, index) => (
                <div key={school.id}>
                  <div className={classes["icon-alignment"]}>
                    <div className="d-flex">
                      <div><span><img className={classes["educationIcon-image-size"]} src={graduationCap} alt=''></img></span></div>
                      <div  className={` ${classes["grey-text"]}`}>{school.NameOfInstitution}</div>
                      <div className="ms-4">
                        <span>
                          <img
                            className={classes["edit-icon"]}
                            src={openDropdown === school.SchoolId ? upperArrowICon : dropArrowICon}
                            alt=''
                            onClick={() => toggleDropdown(school.SchoolId)}
                          />
                        </span>
                         </div>
                     </div>
                    <div className="mx-4">
                     {/* <span><img  className={classes["edit-icon"]} src={deleteICon} alt=''  onClick={() => showConfirmModalhandler(school.SchoolId)}></img></span>
                     <span> <img className={classes["edit-icon"]} src={editIcon} alt='' onClick={() => handleSchoolEditClick(school)}></img></span>*/}
                     </div>
                  </div>
                  {openDropdown === school.SchoolId && (
                    <div className="dropDownContainer">
                      <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["educationIcon-image-size"]} src={programIcon} alt=''></img></span></div>
                          <div className={` ${classes["grey-text"]}`}>{school.LevelOfEducationName?school.LevelOfEducationName:"NA"}</div>
                        </div>
                      </div>
                      <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["educationIcon-image-size"]} src={starIcon} alt=''></img></span></div>
                          <div className={` ${classes["grey-text"]}`}>{school.Marks.length > 0 ? school.Marks[0].GradeName : "NA"}</div>
                        </div>
                      </div>
                      <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["educationIcon-image-size"]} src={starIcon} alt=''></img></span></div>
                          <div className={` ${classes["grey-text"]}`}>{school.Marks.length > 0 ? school.Marks[0].ObtainMarks : "NA"}</div>
                        </div>
                      </div>
                      <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["educationIcon-image-size"]} src={calenderIcon} alt=''></img></span></div>
                          <div className={` ${classes["grey-text"]}`}>
                            {school.AttendendFrom? new Date(school.AttendendFrom).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }):"NA"}

                          </div>
                        </div>
                      </div>
                      <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["educationIcon-image-size"]} src={calenderIcon} alt=''></img></span></div>
                          <div className={` ${classes["grey-text"]}`}>
                            {school.AttendendTo ? new Date(school.AttendendTo).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }):"NA"}</div>
                        </div>
                      </div>
                      <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["educationIcon-image-size"]} src={locationIcon} alt=''></img></span></div>
                          <div className={`${classes["grey-text"]}`}>{school.CountryOfInstitutionName?school.CountryOfInstitutionName:"NA"}</div>
                        </div>
                      </div>
                    </div>
                  )}
                      {index < educationData.length - 1 && <img className={`${classes.imageLineHeight}`} src={lineIcon}></img>}
                </div>
              ))}
            </div>
        </div>
      </div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
        <div className={classes["heading-align"]}>
            <div>
            <p className={classes["heading-text"]}>Work</p>
            </div>
            <div>
                <span className={classes["coin-count"]}>5</span>
                <span><img  className={classes["workIcon-image-size"]} src={coinIcon} alt=''></img></span>
                
            </div>
          </div>
          <div>
          {workData?.length===0 && (
             <div>
             <p className={classes["blank-content-text"]}>
                No work experience
             </p>
           </div>
      )}
      {workData?.length > 0 && (
        <div>
          {workData.map((rowData) => (
            <div key={rowData.id}>
              <div className={classes["icon-alignment"]}>
                {/* <div className="d-flex"> */}
                  <div className="d-flex" >
                  <div><span><img className={classes["workIcon-image-size"]} src={graduationCap} alt=''></img></span></div>
                  <div className={`ms-3 + ${classes["grey-text"]}`}>{rowData.company}</div>
                  <div className="ms-4">
                    <span>
                      <img
                        className={classes["edit-icon"]}
                        src={openDropdownForCompany == rowData.id ? upperArrowICon : dropArrowICon}
                        alt=''
                        onClick={() => toggleDropdownForCompany(rowData.id)}
                      />
                    </span>
                  </div>
                  </div>
                  <div>
                  <div className="mx-4">
                    {/* <span><img className={classes["edit-icon"]} src={editIcon} alt='' onClick={() => viewSet(rowData, 'Work', 'edit')}></img></span>
                    <span><img className={classes["edit-icon"]} src={deleteICon} alt='' onClick={() => removeWorkExperience(rowData.id)}></img></span> */}
                  </div>
                  </div>
                  
                {/* </div> */}
              </div>
              {openDropdownForCompany === rowData.id && (
                <div className="dropDownContainer">
                  <div className={classes["icon-alignment"]}>
                    <div className="d-flex">
                      <div className="ms-4"><span><img className={classes["workIcon-image-size"]} src={jobTitleIcon} alt=''></img></span></div>
                      <div className={` ${classes["grey-text"]}`}>{rowData.jobTitle}</div>
                    </div>
                    {/* <div className="mx-4"><span> <img className={classes["edit-icon"]} src={editIcon} alt=''></img></span></div> */}
                  </div>
                  <div className={classes["icon-alignment"]}>
                    <div className="d-flex">
                      <div className="ms-4"><span><img className={classes["workIcon-image-size"]} src={calenderIcon} alt=''></img></span></div>
                      <div className={` ${classes["grey-text"]}`}>
                      {rowData.start ? new Date(rowData.start).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }):"NA"}
                      </div>
                    </div>
                    {/* <div className="mx-4"><span> <img className={classes["edit-icon"]} src={editIcon} alt=''></img></span></div> */}
                  </div>
                  <div className={classes["icon-alignment"]}>
                    <div className="d-flex">
                      <div className="ms-4"><span><img className={classes["workIcon-image-size"]} src={calenderIcon} alt=''></img></span></div>
                      <div className={` ${classes["grey-text"]}`}>
                      {rowData.end ? new Date(rowData.end).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }):"NA"}
                      </div>
                    </div>
                    {/* <div className="mx-4"><span> <img className={classes["edit-icon"]} src={editIcon} alt=''></img></span></div> */}
                  </div>
                  <div className={classes["icon-alignment"]}>
                    <div className="d-flex">
                      <div className="ms-4"><span><img className={classes["workIcon-image-size"]} src={locationIcon} alt=''></img></span></div>
                      <div className={` ${classes["grey-text"]}`}>{rowData.location}</div>
                    </div>
                    {/* <div className="mx-4"><span> <img className={classes["edit-icon"]} src={editIcon} alt=''></img></span></div> */}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
    )}
            </div>
        </div>
      </div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
        <div className={classes["heading-align"]}>
            <div>
            <p className={classes["heading-text"]}>Interests</p>
            </div>
            <div>
                <span className={classes["coin-count"]}>5</span>
                <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span>
            </div>
          </div>
          {interests?.length===0 && (
             <div>
             <p className={classes["blank-content-text"]}>
                NO data interests
             </p>
           </div>
          )}
         { interests?.length>0 && (
          <div className={classes["space"]}>
             <div className={classes["added-content"]}>
                {interests.map(item => (
                    <div className={classes["div_position"]}>
                    <div className={classes["added-content-text"]}>
                        {item.Interest}
                    </div>
                    {/* <div>
                       <img title="Remove" className={classes["corss-icon"]} src={corssIcon} alt='' onClick={() => handleRemoveInterests(item.StudentInterestID)}></img></div> */}
                    </div>
 
                ))}
             </div>
             </div>
          )}

        </div>
      </div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
        <div className={classes["heading-align"]}>
            <div>
            <p className={classes["heading-text"]}>Skills</p>
            </div>
            <div>
                <span className={classes["coin-count"]}>5</span>
                <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span>
                
            </div>
          </div>
          <div>
          {skills?.length === 0 && (
        <div>
          <p className={classes["blank-content-text"]}>
        No data skills
          </p>
        </div>
      )}
      {skills?.length > 0 && (
        <div className={classes["added-content"]}>
          {skills.map(item => (
            <div className={classes["div_position"]} key={item.StudentSkillID}>
              <div className={classes["added-content-text"]}>
              {item.Skill}
              </div>
            </div>
          ))}
        </div>
      )}
          </div>
        </div>
      </div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
        <div className={classes["heading-align"]}>
            <div>
            <p className={classes["heading-text"]}>Competencies</p>
            </div>
            <div>
                <span className={classes["coin-count"]}>5</span>
                <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span>
                
            </div>
          </div>
          <div>
          {competencies?.length===0 && (
             <div>
             <p className={classes["blank-content-text"]}>
             No data competencies
             </p>
           </div>
          )}
         { competencies?.length>0 && (
          <div className={classes["space"]}>
             <div className={classes["added-content"]}>
                {competencies.map(item => (
                    <div className={classes["div_position"]}>
                    <div className={classes["added-content-text"]}>
                        {item.Competency}
                    </div>
                    {/* <div> <img title="Remove" className={classes["corss-icon"]} src={corssIcon} alt='' onClick={()=>handleRemoveCompetency(item.StudentCompetencyId)}></img></div> */}
                    </div>
 
                ))}
             </div>
             </div>
          )}
          </div>
        </div>
      </div>
    </div></>
  );
}
export default About;
