import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import classes from "./AddEducationModal.module.css";
import SearchModal from "../../../../Search/SearchModal/SearchModal";
import corssIcon from "../../../../../../../Assets/Logo/cross-icon.svg";
import deleteIcon from "../../../../../../../Assets/Logo/delete-icon-red.svg";
import eduEdit from "../../../../../../../Assets/Logo/eduEdit.svg"
import eduDelete from "../../../../../../../Assets/Logo/eduDelete.svg"
import ConfirmationModal from "../../../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";

import ApplicationService from "../../../../../../../Services/ApplicationServices";
import useLocalStorage from "../../../../../../GlobleShared/CustomHooks/useLocalStorage";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { get, set } from "draft-js/lib/DefaultDraftBlockRenderMap";
import Loader from "../../../../../../GlobleShared/Loader/Loader";

function AddEducationModal({addMoreEducationPopup, updateFlag, handleClose, selectedSchoolData, refreshSchoolList}) {
  const initial = useRef(null);
  const [provinceList, setProvinceList] = useState([]);

  const [cityList, setCityList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [streamList, setStreamList] = useState([]);

  const [degreeList, setDegreeList] = useState([]);

  const [educationLevelListbyApllication, setEducationLevelListbyApllication] =
    useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [schoolData,setSchoolData]=useState()
  const [isLoading, setIsLoading] = useState(false);
  const [userDetail] = useLocalStorage("userDetail");
  const [studentId, setStudentId] = useState(userDetail?.refId);
  const [grdSchemeData, setGrdSchemeData] = useState([]);
  
  const [nextId, setNextId] = useState(1);





  const [gradeSchemeDDL, setGradeSchemeDDL] = useState([]);
//   const [addMoreEducationPopup, setAddMoreEducationPopup] = useState(false);
  const [dropDownGradingSchemeSelected, setDropDownGradingSchemeSelected] = useState(false);
  const [boardOfEducation, setBoardOfEducation] = useState(false);
  const [updateShow, setUpdateShow] = useState(false);
  const GradeDropdownValuesArray = [1,2,14,19,21,22,25];
  const [confirmationRepostModal, setConfirmationRepostModal]=useState(false);
  const [schoolId, setSchoolId]=useState(0);


  useEffect(() => {
    if (!initial.current) {
      initial.current = true;
      addEducationDetail();
      if(updateFlag){
        getSchoolToUpdate(selectedSchoolData);
      }
    }
  }, [updateFlag]);


  const addEducationDetail = () => {
    getCountryList();
    // getEduLevelList(); need to implemen
    getEduLevelForDDLByApplication(studentId);
    getSubjectList();
    getLanguageList();
    getDegreeList();
    getStreamList();
  };

  const getCountryList = () => {
    setIsLoading(true);
    ApplicationService.getCountryList()
      .then((res) => {
        setIsLoading(false);
        setCountryList(res);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  };

  const getProvinceList = (value) => {
    setIsLoading(true);
    ApplicationService.getProvince(value)
      .then((res) => {
        setIsLoading(false);
        setProvinceList(res);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  };

  const getCityList = (value) => {
    setIsLoading(false);
    ApplicationService.getCity(value)
      .then((res) => {
        setIsLoading(false);
        setCityList(res);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  };

  const getLanguageList = () => {
    setIsLoading(true);
    ApplicationService.getLanguageList()
      .then((res) => {
        setIsLoading(false);
        setLanguageList(res);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  };

  const getSubjectList = () => {
    setIsLoading(true);
    ApplicationService.getSubjectList()
      .then((res) => {
        setIsLoading(false);
        setSubjectList(res);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  };
  const getDegreeList = () => {
    setIsLoading(true);
    ApplicationService.getDegreeList()
      .then((res) => {
        setIsLoading(false);
        setDegreeList(res);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  };
  const getStreamList = () => {
    setIsLoading(true);
    ApplicationService.getStreamList()
      .then((res) => {
        setIsLoading(false);
        setStreamList(res);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  };
  const getEduLevelForDDLByApplication = (studentId) => {
    let data = {
      ParentType: 6,
      ParentId: studentId,
      SchoolId: selectedSchoolData.SchoolId ? selectedSchoolData.SchoolId : 0,
    };
    setIsLoading(true);
    ApplicationService.getEduLevelForDDLByApplication(data)
      .then((res) => {
        setIsLoading(false);
        setEducationLevelListbyApllication(res);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };



  const onEducationCountryChange = (value) => {
    if (value != null) {
    setGrdSchemeData([
    ]) 
    }// remove this and 
    ApplicationService.getGradingSchemeAsync(value).then((res)=>{
        setGrdSchemeData(res);
      }).catch((error)=>{
       
      })
}

const getSchoolDetailList = () => {
    let data = {
      ParentType: 6,
      ParentId: studentId,
    };
    ApplicationService.getSchoolDetailList(data)
      .then((res) => {
        setSchoolList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const getSchoolDetail = (SchoolId) => {
    setIsLoading(true)
    ApplicationService.getSchoolDetail(SchoolId)
      .then((res) => {
        setIsLoading(false)
        setSchoolData(res);
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });
  };

  const deleteSchoolDetail = (SchoolId) => {
    ApplicationService.deleteSchoolDetail(SchoolId)
      .then((res) => {
        toast.success(res?.message);
        getSchoolDetailList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const addSchoolDetail = (data) => {
  //   setIsLoading(true)
  //   ApplicationService.addSchoolDetail(data)
  //     .then((res) => {
  //       setIsLoading(false)
  //       // toast.success(res?.message);
  //       toast.success("School Added successfully!");
  //       refreshSchoolList();
  //     })
  //     .catch((error) => {
  //       setIsLoading(false)
  //       console.log(error);
  //     });
  // };


  // const updateSchoolDetail = (data) => {
  //   setIsLoading(true)
  //   ApplicationService.updateSchoolDetail(data)
  //     .then((res) => {
  //       setIsLoading(false)
  //       // toast.success(res?.message);
  //       toast.success("School updated successfully!");
  //       refreshSchoolList();
  //     })
  //     .catch((error) => {
  //       setIsLoading(false)
  //       console.log(error);
  //     });
  // };



 



  const onGradeChange = (gradingSchemeID) => {
    // eduInfo.GradeAverage = "";
    // Reset GradeAverage correctly
    if (GradeDropdownValuesArray.includes(Number(gradingSchemeID))) {
      setDropDownGradingSchemeSelected(true);
    }
    else{
      setDropDownGradingSchemeSelected(false);
      return;
    }
    const selectedGrade = grdSchemeData.find((d) => d.GrdSchemeId === Number(gradingSchemeID)) || null;

    if (selectedGrade) {
      setGradingScheme(selectedGrade);
    }
  };

  const setGradingScheme = (selectedGrade) => {
    if (selectedGrade) {
      setDropDownGradingSchemeSelected(true);
      // const hints = selectedGrade.InputHint ? selectedGrade.InputHint.replaceAll(" ", "").split(",") : [];
        const hints = selectedGrade['InputHint'].replaceAll(" ","").split(",");
      setGradeSchemeDDL(hints);
    } else {
      setDropDownGradingSchemeSelected(false);
    }
  };



  const handleEducationCountryChange = (e) => {
    // eduInfo.GradingScheme = "";
    // eduInfo.GradeAverage = "";
    onEducationCountryChange(e.target.value);
  };

  //********************** */




// *******************************************************
  // for popup add more education detail

  const addMoreEducation = () => {
    // addMoreEducationDetail();  // for update add data we called multiple apis
    // setAddMoreEducationPopup(true);
    setUpdateShow(false);
  };

  //to store and save data while adding more educatoin detail
  const [educationDetails, setEducationDetails] = useState({
    SchoolId: 0,
    ParentId: studentId, // set according to your parentId
    ParentType: 6, // set according to your parentType
    LevelOfEducation: 0,
    LevelOfEducationName: '',
    CountryOfInstitution: '',
    CountryOfInstitutionName: '',
    NameOfInstitution: '',
    Language: '',
    AttendendFrom: 0,
    AttendendTo: 0,
    Degree: '',
    DegreeAwardedOn: 0,
    Addres: '',
    City: '',
    Province: '',
    Pincode: '',
    Marks: [],
    StreamId: 0,
    institutionId: '',
    ExamDocUrl: '',
  });


//   const formatDate = (dateString) => {
//     const object = new Date(dateString); // Convert to Date or return null if empty
// };

  const handleInfoAddEducationChange = (e) => {
    const { name, value } = e.target;
    setEducationDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

// on change of  country of institution
  const handleCountryInstitutionChange = (e) => {
    educationDetails.Province="";
    educationDetails.City="";
    formData.Subject="";
    formData.GradeId="";
    getProvinceList(e.target.value);
    onEducationCountryChange(e.target.value);
    // setCountryInstitution(e.target.value);
  };
  const handleProvinceChange = (e) => {
    educationDetails.City="";
    getCityList(e.target.value);
    // setCountryInstitution(e.target.value);
  };

  const getEduLevelName = (eduLevelId) => {

    if (educationLevelListbyApllication && educationLevelListbyApllication.length > 0) {
      const selectedLevel = educationLevelListbyApllication.find(c => c.EduLevelId === Number(eduLevelId));
      
      if (selectedLevel) {
        const levelName = selectedLevel.EduLevelName;
        setEducationDetails((prevDetails) => ({
          ...prevDetails,
          LevelOfEducationName: levelName,
        }));

        // Check if LevelOfEducationName includes 'grade' or 'diploma'
        const isBoardOfEducation = levelName.toLowerCase().includes('grade') ||
                                   levelName.toLowerCase().includes('diploma');
        setBoardOfEducation(isBoardOfEducation);
      }
    }
  };

  // *****************//

  // ****************************************************************************************
  const [formData, setFormData] = useState({
    SubjectId: 0, // You might want to set this based on your logic
    SubjectName: "",
    MaxMarks: "",
    MinMarks: "",
    ObtainMarks: "",
    GradeId: 0,
    GradeName: "",
  });
  
  const [rows, setRows] = useState([]);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));

    if (name === 'SubjectId') {
        if (subjectList && subjectList.length > 0) {
            const selectedSubject = subjectList.find(item => item.SbjId === Number(value));
            
            if (selectedSubject) {
                const SubjectName = selectedSubject.SbjName;
                
                // Update formData with the selected SubjectName
                setFormData(prevData => ({
                    ...prevData,
                    SubjectName: SubjectName,  // Add or update the SubjectName
                }));
            }
        }
    }

    if (name === 'GradeId') {
        onGradeChange(value);  
    }
};
  
  const handleAddRow = () => {
    if (formData.SubjectId && formData.GradeId) {
      // Create a new marks entry based on formData
      const newMark = {
        SubjectId: formData.SubjectId,
        SubjectName: formData.SubjectName,
        MaxMarks: formData.MaxMarks,
        MinMarks: formData.MinMarks,
        ObtainMarks: formData.ObtainMarks,
        GradeId: formData.GradeId,
        GradeName: grdSchemeData.find((d) => d.GrdSchemeId === Number(formData.GradeId)).GrdSchemeName || "",
      };
  
      // Update rows state
      setRows([...rows, newMark]);
  
      // Update educationDetails state to include new mark
      setEducationDetails((prevDetails) => ({
        ...prevDetails,
        Marks: [...(prevDetails.Marks || []), newMark],
      }));
  
      // Reset formData state
      setFormData({
        SubjectId: 0,
        SubjectName: "",
        MaxMarks: "",
        MinMarks: "",
        ObtainMarks: "",
        GradeId: "",
        GradeName: "",
      });
    }
    // setNextId((prevId) => prevId + 1);
  };
  
  const handleRemoveRow = (subjectId) => {
    // Remove the row based on SubjectId
    const updatedRows = rows.filter(row => row.SubjectId !== subjectId);
    setRows(updatedRows);
  
    // Remove the mark from educationDetails based on SubjectId
    setEducationDetails((prevDetails) => ({
      ...prevDetails,
      Marks: prevDetails.Marks.filter(mark => mark.SubjectId !== subjectId),
    }));
  };
  

  
  // update and get school and add school
  // *******************************************************
  

 //add school for submitting data
 const addSchoolData=()=>{
    const data = {
      SchoolId: educationDetails.SchoolId || 0,
      ParentId: educationDetails.ParentId || studentId, // Use current or default value
      ParentType: educationDetails.ParentType || 6,
      LevelOfEducation: educationDetails.LevelOfEducation || 0,
      LevelOfEducationName: educationDetails.LevelOfEducationName || '',
      CountryOfInstitution: educationDetails.CountryOfInstitution || 0,
      CountryOfInstitutionName: educationDetails.CountryOfInstitutionName || '',
      NameOfInstitution: educationDetails.NameOfInstitution || '',
      Language: educationDetails.Language || '',
      AttendendFrom: educationDetails.AttendendFrom || 0,
      AttendendTo: educationDetails.AttendendTo || 0,
      Degree: educationDetails.Degree || '',
      DegreeAwardedOn: educationDetails.DegreeAwardedOn || 0,
      Addres: educationDetails.Addres || '',
      City: educationDetails.City || '',
      Province: educationDetails.Province || '',
      Pincode: educationDetails.Pincode || '',
      Marks: educationDetails.Marks.length > 0 ? educationDetails.Marks : [], // Use existing marks or empty array
      StreamId: educationDetails.StreamId || 0,
      institutionId: educationDetails.institutionId || '',
      ExamDocUrl:educationDetails.ExamDocUrl||'',
      
    };
  
      setIsLoading(true)
      ApplicationService.addSchoolDetail(data)
        .then((res) => {
          setIsLoading(false)
          // toast.success(res?.message);
          toast.success("School Added successfully!");
          refreshSchoolList();
          setEducationDetails("");
          setRows([]);
          // setAddMoreEducationPopup(false);
          handleClose();
        })
        .catch((error) => {
          setIsLoading(false)
          console.log(error);
        });
   }
  
  
  
  
  
   //****************************get school to update data and open modal
   const getSchoolToUpdate = (data) => {
    // getSchoolDetail(schoolId); 
  
    //addEducationDetail(); // for update school data we called multiple apis
    getProvinceList(data.CountryOfInstitution);
    getCityList(data.Province);
    onEducationCountryChange(data.CountryOfInstitution);
  
    setEducationDetails(prevDetails => ({
      ...prevDetails, // Keep existing values
      SchoolId: data.SchoolId,
      LevelOfEducation: data.LevelOfEducation,
      LevelOfEducationName: data.LevelOfEducationName,
      CountryOfInstitution: data.CountryOfInstitution,
      CountryOfInstitutionName: data.CountryOfInstitutionName,
      NameOfInstitution: data.NameOfInstitution,
      Language: data.Language,
      AttendendFrom: new Date(data.AttendendFrom).toISOString().split('T')[0], // 
      AttendendTo: new Date(data.AttendendTo).toISOString().split('T')[0], // 
      Degree: data.Degree,
      DegreeAwardedOn: new Date(data.DegreeAwardedOn).toISOString().split('T')[0], // Convert if needed
      Addres: data.Addres,
      City: data.City,
      Province: data.Province,
      Pincode: data.Pincode,
      Marks: data.Marks, 
      StreamId: streamList.find(s => s.StreamName === data.StreamName)?.StreamId || null, 
      institutionId: prevDetails.institutionId, 
      ExamDocUrl:data.ExamDocUrl,
  }));

    const marksForRows = data.Marks.map(mark => ({
      SubjectId: mark.SubjectId,
      SubjectName: mark.SubjectName,
      MaxMarks: mark.MaxMarks,
      MinMarks: mark.MinMarks,
      ObtainMarks: mark.ObtainMarks,
      GradeId: mark.GradeId,
      GradeName: mark.GradeName,
    }));
  
    setRows(marksForRows);
    // setAddMoreEducationPopup(true);
    setUpdateShow(true);
  }
  
  
  
  //******************** */ implement this update school data must pass data
  const updateSchoolData= ()=>{
    const data = {
      SchoolId: educationDetails.SchoolId || 0,
      ParentId: educationDetails.ParentId || studentId, // Use current or default value
      ParentType: educationDetails.ParentType || 6,
      LevelOfEducation: educationDetails.LevelOfEducation || 0,
      LevelOfEducationName: educationDetails.LevelOfEducationName || '',
      CountryOfInstitution: educationDetails.CountryOfInstitution || 0,
      CountryOfInstitutionName: educationDetails.CountryOfInstitutionName || '',
      NameOfInstitution: educationDetails.NameOfInstitution || '',
      Language: educationDetails.Language || '',
      AttendendFrom: educationDetails.AttendendFrom || 0,
      AttendendTo: educationDetails.AttendendTo || 0,
      Degree: educationDetails.Degree || '',
      DegreeAwardedOn: educationDetails.DegreeAwardedOn || 0,
      Addres: educationDetails.Addres || '',
      City: educationDetails.City || '',
      Province: educationDetails.Province || '',
      Pincode: educationDetails.Pincode || '',
      Marks: educationDetails.Marks.length > 0 ? educationDetails.Marks : [], // Use existing marks or empty array
      StreamId: educationDetails.StreamId || 0,
      institutionId: educationDetails.institutionId || '',
      ExamDocUrl:educationDetails.ExamDocUrl||'',
    };

      setIsLoading(true)
      ApplicationService.updateSchoolDetail(data)
        .then((res) => {
          setIsLoading(false)
          // toast.success(res?.message);
          toast.success("School updated successfully!");
          refreshSchoolList();
          setEducationDetails("");
          setRows([]);
          // setAddMoreEducationPopup(false);
          handleClose();
        })
        .catch((error) => {
          setIsLoading(false)
          console.log(error);
        });
  }
  
   const showConfirmModalhandler = (id) => {
    setSchoolId(id);
    setConfirmationRepostModal(true);
  };
  const hideConfirmModalhandler = () => {
    setConfirmationRepostModal(false);
  };
  
  


  const handleClosePopup = () => {
    handleClose();
    // setAddMoreEducationPopup(false);
    setRows([]);
    setEducationDetails("")
    // setCountryInstitution("");
    // setEducationLevel("");
  };

  
  return (
    <>
  <div>
    <SearchModal onShow={addMoreEducationPopup} onHide={handleClose} size="xl">
          <div className={classes.ModelContainer}>
            <div className={`${classes.headerContainer}`}>
              <div className={classes.title}>
                <span>Add Education Details</span>
              </div>
              <div>
                <img
                  title="Remove"
                  className={classes.corssIcon}
                  src={corssIcon}
                  alt=""
                  onClick={handleClosePopup}
                />
              </div>
            </div>
          </div>

          <div className={classes.mainContainer}>
            <div className="row">
              <div className="col-md-4 form-group">
                <Form.Group className="mb-3" controlId="educationLevel">
                  <Form.Label>Level of Education</Form.Label>
                  <Form.Select
                    name="LevelOfEducation"
                    aria-label="Level of Education"
                    value={educationDetails.LevelOfEducation}
                    onChange={(e) => {
                      handleInfoAddEducationChange(e);
                      getEduLevelName(e.target.value);
                    }}
                  >
                    <option className={classes["option-default"]} value="">Select Education Level</option>
                    {educationLevelListbyApllication.map((level) => (
                      <option key={level.EduLevelId} value={level.EduLevelId}>
                        {level.EduLevelName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="col-md-4 form-group">
                <Form.Group className="mb-3" controlId="countryInstitution">
                  <Form.Label>Country of Institution</Form.Label>
                  <Form.Select
                    name="CountryOfInstitution"
                    aria-label="Country of Institution"
                    value={educationDetails.CountryOfInstitution}
                    onChange={(e) => {
                      handleCountryInstitutionChange(e);
                      handleInfoAddEducationChange(e);
                    }}
                  >
                    <option className={classes["option-default"]} value="">Country of Institution</option>
                    {countryList.map((country) => (
                      <option key={country.CountryId} value={country.CountryId}>
                        {country.CountryName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="col-md-4 form-group">
                <Form.Group className="mb-3" controlId="institutionName">
                  <Form.Label>Select Name of Institution</Form.Label>
                  <Form.Control
                    type="text"
                    name="NameOfInstitution"
                    placeholder="Institution Name"
                    value={educationDetails.NameOfInstitution}
                    onChange={handleInfoAddEducationChange}
                  />
                </Form.Group>
              </div>

              {/* Additional fields based on education level */}
              {boardOfEducation && (
                <div className="col-md-12 form-group">
                  <Form.Group className="mb-3" controlId="boardOfEducation">
                    <Form.Label>Board of Education</Form.Label>
                    <Form.Control
                      type="text"
                      name="Addres"
                      placeholder="Board of Education"
                      value={educationDetails.Addres}
                      onChange={handleInfoAddEducationChange}
                    />
                  </Form.Group>
                </div>
              )}

              <div className="col-md-4 form-group">
                <Form.Group className="mb-3" controlId="province">
                  <Form.Label>Province</Form.Label>
                  <Form.Select
                    name="Province"
                    aria-label="Province/State"
                    value={educationDetails.Province}
                    onChange={(e) => {
                      handleProvinceChange(e);
                      handleInfoAddEducationChange(e);
                    }}
                    required
                  >
                    <option className={classes["option-default"]} value="">Select a province/state</option>
                    {provinceList.map((province) => (
                      <option
                        key={province.ProvinceId}
                        value={province.ProvinceId}
                      >
                        {province.ProvinceName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="col-md-4 form-group">
                <Form.Group className="mb-3" controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Select
                    name="City"
                    aria-label="City/Town"
                    className="form-control"
                    value={educationDetails.City}
                    onChange={handleInfoAddEducationChange}
                    required
                  >
                    <option className={classes["option-default"]} value="">Select a city/town</option>
                    {cityList.map((city) => (
                      <option key={city.CityName} value={city.CityName}>
                        {city.CityName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="col-md-4 form-group">
                <Form.Group className="mb-3" controlId="primaryLanguage">
                  <Form.Label>Primary Language of Instruction</Form.Label>
                  <Form.Select
                    name="Language"
                    aria-label="Language"
                    value={educationDetails.Language}
                    onChange={handleInfoAddEducationChange}
                  >
                    <option className={classes["option-default"]} value="">Select Language</option>
                    {languageList.map((language) => (
                      <option
                        key={language.LanguageId}
                        value={language.LanguageName}
                      >
                        {language.LanguageName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="col-md-4 form-group">
                <Form.Group className="mb-3" controlId="startDate">
                  <Form.Label>Start Date (MM/DD/YY)</Form.Label>
                  <Form.Control
                    type="date"
                    name="AttendendFrom"
                    value={educationDetails.AttendendFrom}
                    onChange={handleInfoAddEducationChange}
                  />
                </Form.Group>
              </div>

              <div className="col-md-4 form-group">
                <Form.Group className="mb-3" controlId="endDate">
                  <Form.Label>End Date (MM/DD/YY)</Form.Label>
                  <Form.Control
                    type="date"
                    name="AttendendTo"
                    value={educationDetails.AttendendTo}
                    onChange={handleInfoAddEducationChange}
                    min={educationDetails.AttendendFrom}
                  />
                </Form.Group>
              </div>

              <div className="col-md-4 form-group">
                <Form.Group className="mb-3" controlId="degreeAwardedDate">
                  <Form.Label>Degree Awarded Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="DegreeAwardedOn"
                    value={educationDetails.DegreeAwardedOn}
                    onChange={handleInfoAddEducationChange}
                  />
                </Form.Group>
              </div>

              <div className="col-md-12 form-group">
                <Form.Group className="mb-3" controlId="degree">
                  <Form.Label>Degree Awarded / Program Name</Form.Label>
                  <Form.Select
                    name="Degree"
                    aria-label="degree/program"
                    value={educationDetails.Degree}
                    onChange={handleInfoAddEducationChange}
                  >
                    <option className={classes["option-default"]} value="">Select a degree/program</option>
                    {degreeList.map((degree) => (
                      <option key={degree.DegreeId} value={degree.DegreeName}>
                        {degree.DegreeName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>

              {Number(educationDetails.LevelOfEducation) === 1108 && (
                <div className="col-md-4 form-group">
                  <Form.Group className="mb-3" controlId="Stream">
                    <Form.Label>Stream</Form.Label>
                    <Form.Select
                      name="StreamId"
                      aria-label="Stream"
                      value={educationDetails.StreamId}
                      onChange={handleInfoAddEducationChange}
                      required
                    >
                      <option className={classes["option-default"]} value="">Select Stream</option>
                      {/* Populate with your stream options */}
                      {streamList.map((stream) => (
                        <option key={stream.StreamId} value={stream.StreamId}>
                          {stream.StreamName}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
              )}

              {/* for marks */}
              {educationDetails.CountryOfInstitution && (
                <>
                  {/* Form Row */}
                  <div className="row">
                    <div className="col-md-2 form-group">
                      <Form.Group className="mb-3" controlId="subjectName">
                        <Form.Label>Subject</Form.Label>
                        <Form.Select
                          aria-label="Name of Subject"
                          name="SubjectId"
                          value={formData.SbjId}
                          onChange={handleInputChange}
                        >
                          <option className={classes["option-default"]} value="">Select Subject</option>
                          {subjectList.map((subject) => (
                            <option key={subject.SbjId} value={subject.SbjId}>
                              {subject.SbjName}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div className="col-md-2 form-group">
                      <Form.Group className="mb-3" controlId="grade">
                        <Form.Label>Grade</Form.Label>
                        <Form.Select
                          aria-label="Grade"
                          name="GradeId"
                          value={formData.GradeId}
                          onChange={handleInputChange}
                        >
                          <option className={classes["option-default"]} value="">Select Grade</option>
                          <option value="0">None</option>
                          {grdSchemeData.map((scheme) => (
                            <option
                              key={scheme.GrdSchemeId}
                              value={scheme.GrdSchemeId}
                            >
                              {scheme.GrdSchemeName}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div className="col-md-2 form-group">
                      <Form.Group className="mb-3" controlId="MinMarks">
                        <Form.Label>Min</Form.Label>
                        <input
                          type="number"
                          className="form-control"
                          name="MinMarks"
                          value={formData.MinMarks}
                          onChange={handleInputChange}
                          disabled={formData.GradeId > 0}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-2 form-group">
                      <Form.Group className="mb-3" controlId="MaxMarks">
                        <Form.Label>Max</Form.Label>
                        <input
                          type="number"
                          className="form-control"
                          name="MaxMarks"
                          value={formData.MaxMarks}
                          onChange={handleInputChange}
                          disabled={formData.GradeId > 0}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-2 form-group">
                      {dropDownGradingSchemeSelected ? (
                        <Form.Group
                          className="mb-3"
                          controlId="ObtainMarksDropdown"
                        >
                          <Form.Label>Score</Form.Label>
                          <Form.Select
                            name="ObtainMarks"
                            value={formData.ObtainMarks}
                            onChange={handleInputChange}
                            required
                          >
                            <option
                              className={classes["option-default"]}
                              value=""
                            >
                              Select Score
                            </option>
                            {gradeSchemeDDL.map((grade, index) => (
                              <option key={index} value={grade}>
                                {grade}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      ) : (
                        <Form.Group
                          className="mb-3"
                          controlId="ObtainMarksInput"
                        >
                          <Form.Label>Score</Form.Label>
                          <input
                            type="number"
                            className="form-control"
                            name="ObtainMarks"
                            value={formData.ObtainMarks}
                            onChange={handleInputChange}
                            required
                          />
                        </Form.Group>
                      )}
                    </div>

                    <div className={`col-md-2 ${classes.addBtnAlgin}`}>
                      <Button variant="secondary" onClick={handleAddRow}>
                        Add
                      </Button>
                    </div>
                  </div>

                  {/* Display Rows in a Table */}
                  {rows.length > 0 && (
                    <Table responsive className={classes.customTable}>
                      <thead>
                        <tr>
                          {/* <th>Sr No.</th> */}
                          <th>Subject</th>
                          <th>Grade</th>
                          <th>Min</th>
                          <th>Max</th>
                          <th>Score</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((row, index) => (
                          <tr key={index}>
                            {/* <td>{index + 1}</td> */}
                            <td>{row.SubjectName}</td>
                            <td>{row.GradeName}</td>
                            <td>{row.MinMarks}</td>
                            <td>{row.MaxMarks}</td>
                            <td>{row.ObtainMarks}</td>
                            <td>
                              <img
                                className={classes.iconSize}
                                src={eduDelete}
                                onClick={() => handleRemoveRow(row.SubjectId)}
                                alt="Delete"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </>
              )}
            </div>
            <div className={` ${classes.submit_btn}`}>
              {!updateShow && (
              <Button
                className={` ${classes.submit_btn_text}`}
                variant="primary"
                onClick={addSchoolData}
              >
                + Add
              </Button>
            )}

              {updateShow && (
              <Button
                className={` ${classes.submit_btn_text}`}
                variant="primary"
                onClick={(e)=>{updateSchoolData(e)}}
              >
                 Update
              </Button>
              )}
            </div>
          </div>
        </SearchModal>
  </div>
  {isLoading && (
        <Loader></Loader>
      )}
  </>
  )
}

export default AddEducationModal;
