import React, { useEffect, useRef, useState } from "react";
import styles from "./NewApplication.module.css";
import ProgramSection from "./ProgramSection/ProgramSection";
import PendingDocumentsSection from "./PendingDocumentsSection/PendingDocumentsSection";
import AssignedCounsellor from "./AssignedCounsellor/AssignedCounsellor";
import ContactInfo from "./ContactInfo/ContactInfo";
import EducationSection from "./EducationSection/EducationSection";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import TestScores from "./TestScores/TestScores";
import ApplicationService from "../../../../../Services/ApplicationServices";
import useLocalStorage from "../../../../GlobleShared/CustomHooks/useLocalStorage";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../../GlobleShared/Loader/Loader";
import StudentServices from "../../../../../Services/StudentServices";
const tabs = [
  { name: "Program Info", id: "Program" },
  { name: "Documents", id: "Documents" },
  { name: "Personal Info", id: "Personal" },
  { name: "Contact Info", id: "Contact" },
  { name: "Education Info", id: "Education" },
  { name: "Test Score", id: "Test" },
  { name: "Assigned Counsellor", id: "Assigned" },
];


const NewApplication = () => {
  const [programDetails, setProgramDetails] = useState();
  const [personalFormData , setPersonalFormData]= useState(null)
  const initial = useRef(null);
  const [userDetail] = useLocalStorage("userDetail");
  const [studentId, setStudentId] = useState(userDetail?.refId);
  const params = useParams();
  const progranTypeId = params.programTypeId;
  const [institionList, setInstitionList] = useState([]);
  const [programId, setProgramId] = useState(0);
  const [institution, setInstitution] = useState(null);
  const [studentData, setStudentData] = useState();
  const [programList, setProgramList] = useState([]);
  const [intakeList, setIntakeList] = useState([]);
  const [programMode, setProgramMode] = useState([]);
  const [programSpecialization, setProgramSpecialization] = useState([]);
  const [studentDataBySectionWise, setStudentDataBySectionWise] = useState([]);
  const [relationList, setRelationList] = useState([]);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [studentDetail, setStudentDetail] = useState([]);
  const [applicationList, setApplicationList] = useState([]);
  const [instituteId, setInstituteId] = useState();
  const [applicationId, setApplicationId] = useState(0);
  const [countryId, setCountryId] = useState(0);
  const [intakeId, setIntakeID] = useState(0);
  const [feeList, setFeeList] = useState([]);
  const [ genContectInfoData,  setGenContectInfoData] = useState();
  const [ testScoreData,  setTestScoreData] = useState();
  const [isLoading, setIsLoading]=useState(false)
  const [campusList, setCampusList]=useState([])
  const [educationInfo,setEducationInfo]=useState()
  const [schoolData,setSchoolData]=useState()
  const [rowData, setRowData] = useState({});
  const navigate=useNavigate()

  const [provinceList, setProvinceList] = useState([]);

    const [cityList, setCityList] = useState([]);

  const [subjectList, setSubjectList] = useState([]);

  const [streamList, setStreamList] = useState([]);

  const [degreeList, setDegreeList] = useState([]);

  const [educationLevelListbyApllication, setEducationLevelListbyApllication] =  useState([]);


  const [educationLevelData, setEducationLevelData] = useState([]);
  const [grdSchemeData, setGrdSchemeData] = useState([]);

  const [modeId, setModeId]=useState()
  const [campusId, setCampusId]=useState()
  const [specializationId, setSpecializationId]=useState()
  const [residingId, setResidingId]=useState();
  const [personalInfoData, setPersonalInfoData]=useState()
  const [documentList, setDocumentList]=useState([]);
  const [pendingDocumentList, setPendingDocumentList]=useState([]);
  const [pendingDoc, setPendingDoc]=useState([])
  const [BProgram1, setBProgram1]=useState(0)
  const [BProgram2, setBProgram2]=useState(0)
  const [BProgram3, setBProgram3]=useState(0)

  const getInstituteId = (value) => {
    setProgramId(0)
    setIntakeID(0)
    setCampusId(0)
    setModeId(0)
    setSpecializationId(0)
    setProgramList([])
    setIntakeList([])
    setCampusList([])
    setProgramSpecialization([])
    setInstituteId(value);
    getProgramList(value);
    getcountryDetails(value);
    getAssignedData(value);
  };

  const getProgramId = (id) => {
    setIntakeID(0)
    setCampusId(0)
    setModeId(0)
    setSpecializationId(0)
    setIntakeList([])
    setCampusList([])
    setProgramSpecialization([])
    setProgramId(id);
    getProgramDetails(id);
    getIntakeList(id);
    getProgramModeList(id);
    getProgramSpecialization(id);
    CheckInstitutionIntakeExists(id, studentId, instituteId, intakeId);
    getAssignedData(instituteId);
    getProgramDocumentList(applicationId, programId, countryId);
    getProgramParentDocumentList(studentId);
  };

  const getIntakeId = (id) => {
    setIntakeID(id)
    setCampusId(0)
    setModeId(0)
    setSpecializationId(0)
    setCampusList([])
    setProgramSpecialization([])
    CheckInstitutionIntakeExists(programId, studentId, instituteId, id);
    getCampusList(id);
    forApplication(id);
  };

  const addEducationDetail = ()=>{
    getCountryList();
    // getEduLevelList(); need to implemen
    getEduLevelForDDLByApplication(studentId)
    getSubjectList();
    getLanguageList();
    getDegreeList();
    getStreamList();
  }

  const getScroll = (value) => {
    document
      .getElementById(value)
      .scrollIntoView({ behavior: "smooth", block: "nearest" });
  };

  const getProgramDetails = (programIdValue) => {
    setIsLoading(true)
    ApplicationService.getProgramDetails(programIdValue)
      .then((res) => {
        setIsLoading(false)
        setProgramDetails(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getIntakeList = (programId) => {
    setIsLoading(true)
    ApplicationService.getProgramIntake(programId, applicationId, countryId)
      .then((res) => {
        setIsLoading(false)
        setIntakeList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getProgramModeList = (programId) => {
    setIsLoading(true)
    ApplicationService.getProgramMode(programId)
      .then((res) => {
        setIsLoading(false)
        setProgramMode(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getProgramSpecialization = (programId) => {
    setIsLoading(true)
    ApplicationService.getProgramSpecialization(programId)
      .then((res) => {
        setIsLoading(false)
        setProgramSpecialization(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getInstituteList = () => {
    setIsLoading(true)
    ApplicationService.getInstituteList(studentId, progranTypeId)
      .then((res) => {
        setIsLoading(false)
        setInstitionList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getProgramList = (value) => {
    let data = {
      InstitutionId: Number(value),
      CountryId: countryId,
      ProgramTypeID: progranTypeId,
    };
    setIsLoading(true)
    ApplicationService.getProgramList(data)
      .then((res) => {
        setIsLoading(false)
        setProgramList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getcountryDetails = (value) => {
    ApplicationService.countryDetailsByInstitutionId(value)
      .then((res) => {})
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const getStudentData = () => {
    setIsLoading(true)
    ApplicationService.getStudentData(studentId, programId)
      .then((res) => {
        setIsLoading(false)
        setStudentData(res);
        if(res?.genInfo)
            setPersonalFormData(res?.genInfo)
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getStudentDataBySectionwise = () => {
    setIsLoading(true)
    ApplicationService.getStudentDataBySection(studentId)
      .then((res) => {
        setIsLoading(false)
        setStudentDataBySectionWise(res);
        setCountryId(res?.genInfo?.Country)
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getRelation = () => {
    ApplicationService.getRelationList()
      .then((res) => {
        setRelationList(res);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const getCountryCode = () => {
    ApplicationService.getCountryCode()
      .then((res) => {
        setCountryCodeList(res);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const getCountryList = () => {
    setIsLoading(true)
    ApplicationService.getCountryList()
      .then((res) => {
        setIsLoading(false)
        setCountryList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getProvinceList = (value) => {
    setIsLoading(true)
    ApplicationService.getProvince(value)
      .then((res) => {
        setIsLoading(false)
        setProvinceList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getCityList = (value) => {
    setIsLoading(false)
    ApplicationService.getCity(value)
      .then((res) => {
        setIsLoading(false)
        setCityList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };


  const getLanguageList = () => {
    setIsLoading(true)
    ApplicationService.getLanguageList()
      .then((res) => {
        setIsLoading(false)
        setLanguageList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getSubjectList = () => {
    setIsLoading(true)
    ApplicationService.getSubjectList()
    .then((res) => {
        setIsLoading(false)
        setSubjectList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };
  const getDegreeList = () => {
    setIsLoading(true)
    ApplicationService.getDegreeList()
      .then((res) => {
        setIsLoading(false)
        setDegreeList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };
  const getStreamList = () => {
    setIsLoading(true)
    ApplicationService.getStreamList()
      .then((res) => {
        setIsLoading(false)
        setStreamList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getApplicationList = () => {
    ApplicationService.getApplicationByStudentId(studentId)
      .then((res) => {
        setApplicationList(res);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const CheckInstitutionIntakeExists = (
    programId,
    studentId,
    instituteId,
    intakeId
  ) => {
    let data = {
      InstitutionId: Number(instituteId),
      StudentId: Number(studentId),
      ProgramId: Number(programId),
      IntakeId: Number(intakeId),
    };
    setIsLoading(true)
    ApplicationService.CheckInstitutionIntakeExists(data)
      .then((res) => {
        setIsLoading(false)
        if (res > 0 && res !== applicationId) {
          toast.error("Application already exists with same program and intake.");
        }
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };
  const getProgramDocumentList = (ApplicationId, ProgramId, countryId) => {
    setIsLoading(true)
    ApplicationService.getProgramDocumentList(ApplicationId, ProgramId, countryId)
      .then((res) => {
        setIsLoading(false)
        setPendingDocumentList(res)
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getProgramParentDocumentList = (studentId) => {
    ApplicationService.getProgramParentDocumentList(studentId)
      .then((res) => {
        setDocumentList(res)
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };


  const getCampusList = (IntakeId) => {
    ApplicationService.getCampusList(IntakeId)
      .then((res) => {
        setCampusList(res)
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const forApplication = (IntakeId) => {
    let data = {
      IntakeId: IntakeId,
      Nationality: 1,
      ApplicationId: applicationId,
    };
    ApplicationService.forApplication(data)
      .then((res) => {
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const getAssignedData = (instituteId) => {
    ApplicationService.getAssignedCounsellor(studentId, instituteId)
      .then((res) => {
        setRowData(res);
      })
      .catch((error) => {
     
      });
  };
  const getEducationLevelData = () => {
    ApplicationService.getEducationLevelasync()
      .then((res) => {
        setEducationLevelData(res);
      })
      .catch((error) => {
     
      });
  };

  const getEduLevelForDDLByApplication = (studentId) => {
    let data = {
      ParentType: 7,
      ParentId: studentId,
      SchoolId: 0,
    };
    setIsLoading(true)
    ApplicationService.getEduLevelForDDLByApplication(data)
      .then((res) => {
        setIsLoading(false)
        setEducationLevelListbyApllication(res);
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });
  };

  const getSchoolDetailList = () => {
    let data = {
      ParentType: 7,
      ParentId: studentId,
    };
    ApplicationService.getSchoolDetailList(data)
      .then((res) => {
        setSchoolList(res);
      })
      .catch((error) =>{
        console.log(error);
      });
  };
  
  const getSchoolDetail = (SchoolId) => {
    setIsLoading(true)
    ApplicationService.getSchoolDetail(SchoolId)
      .then((res) => {
        setIsLoading(false)
        setSchoolData(res);
        console.log(schoolData);
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });
  };

  const deleteSchoolDetail = (SchoolId) => {
    setIsLoading(true)
    ApplicationService.deleteSchoolDetail(SchoolId)
      .then((res) => {
        setIsLoading(false)
        // toast.success(res?.message);
        toast.success("School deleted successfully!");
        getSchoolDetailList();
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });
  };

  const addSchoolDetail = (data) => {
    console.log(data);
    setIsLoading(true)
    ApplicationService.addSchoolDetail(data)
      .then((res) => {
        setIsLoading(false)
        toast.success(res?.message);
        getSchoolDetailList();
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });
  };


  const updateSchoolDetail = (data) => {
    console.log(data);
    setIsLoading(true)
    ApplicationService.updateSchoolDetail(data)
      .then((res) => {
        setIsLoading(false)
        // toast.success(res?.message);
        toast.success("School updated successfully!");
        getSchoolDetailList();
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });
  };



  const onEducationCountryChange = (value) => {
    ApplicationService.getGradingSchemeAsync(value).then((res)=>{
        setGrdSchemeData(res);
      }).catch((error)=>{
       
      })
}


    const getMode=(id)=>{
      setModeId(id)

    }
    const getCampus=(id)=>{
      setCampusId(id)

    }
    const getSpecialization=(id)=>{
      setSpecializationId(id)

    }
    const getResiding=(id)=>{
      setResidingId(id)

    }
    const getPersonalInfoData=(data)=>{
      setPersonalInfoData(data)
    }
    const getGenContectInfo=(data)=>{
      setGenContectInfoData(data)
    }

    const getTestScore=(data)=>{
      setTestScoreData(data)
    }

    const getEducationInfo=(data)=>{
      setEducationInfo(data)
    }
    const getBackupProgramId=(BProgram1Id, BProgram2Id, BProgram3Id)=>{
      setBProgram1(BProgram1Id)
      setBProgram2(BProgram2Id)
      setBProgram3(BProgram3Id)

    }

const addApplication=()=>{
 let data= {
  programInfo: {
    AppSourceCode: "",
    ApplicationId: Number(applicationId),
    ApplicationMode: Number(modeId),
    ApplicationStatus: 0,
    B1ProgramId: Number(BProgram1),
    B2ProgramId: Number(BProgram2),
    B3ProgramId: Number(BProgram3),
    Campus: campusId,
    InstitutionId: Number(instituteId),
    IntekId: Number(intakeId),
    ProgramId: Number(programId),
    ShoreType:Number(residingId),
    SpecializationId: specializationId
  },
  genInfo: {
    About: "",
    Addres: genContectInfoData?.Addres,
    AdmissionExecutive: studentData?.genInfo?.AdmissionExecutive,
    AgentId: Number(studentData?.genInfo?.AgentId),
    App_Password: studentData?.genInfo?.App_Password,
    ApplicationId: applicationId,
    Area: studentData?.genInfo?.Area,
    AssignedTo:Number(studentData?.genInfo?.AssignedTo)?Number(studentData?.genInfo?.AssignedTo):0,
    B2CCounsellorId: Number(studentData?.genInfo?.B2CCounsellorId)?Number(studentData?.genInfo?.B2CCounsellorId):0,
    Citizenship: Number(personalInfoData?.Citizenship),
    City: genContectInfoData?.City,
    ConnectionCount: 0,
    Country:Number(genContectInfoData?.Country),
    CountryName: "",
    DateOfBirth: personalInfoData?.DateOfBirth,
    Email: personalInfoData?.Email,
    EmergencyAddress: genContectInfoData?.EmergencyAddress,
    EmergencyAddressSame: genContectInfoData?.EmergencyAddressSame,
    EmergencyBusinessPhone: studentData?.genInfo?.EmergencyBusinessPhone,
    EmergencyBusinessPhoneCountryCode: studentData?.genInfo?.EmergencyBusinessPhoneCountryCode,
    EmergencyCellPhone:  genContectInfoData?.EmergencyCellPhone,
    EmergencyCellPhoneCountryCode: genContectInfoData?.EmergencyCellPhoneCountryCode,
    EmergencyCity:  genContectInfoData?.EmergencyCity,
    EmergencyCountry:Number(genContectInfoData?.EmergencyCountry)?Number(genContectInfoData?.EmergencyCountry):0,
    EmergencyEmail: genContectInfoData?.EmergencyEmail,
    EmergencyPincode: genContectInfoData?.EmergencyPincode,
    EmergencyProvince: Number(genContectInfoData?.EmergencyProvince)?Number(genContectInfoData?.EmergencyProvince):0,
    EmergencyRelation: genContectInfoData?.EmergencyRelation,
    EmrgencyName: genContectInfoData?.EmrgencyName,
    FirstLogin: false,
    FirstName: personalInfoData?.FirstName,
    Gender: personalInfoData?.Gender,
    GovernmentIdNumber: personalInfoData?.GovernmentIdNumber,
    GovernmentIdType: personalInfoData?.GovernmentIdType,
    HouseNumber: studentData?.genInfo?.HouseNumber,
    Language: personalInfoData?.Language,
    LastName: personalInfoData?.LastName,
    LeadSourceId: 0,
    LeadSourceSecondaryId:0,
    LeadSourceText: "",
    LeadSourceThirdId: 0,
    MailingAddres: genContectInfoData?.MailingAddres,
    MailingAddressSame: genContectInfoData?.MailingAddressSame,
    MailingCity: genContectInfoData?.MailingCity,
    MailingCountry:Number(genContectInfoData?.MailingCountry),
    MailingPincode:genContectInfoData?.MailingPincode,
    MailingProvince:  Number(genContectInfoData?.MailingProvince),
    MailingProvinceName: "",
    MaritialStatus:  personalInfoData?.MaritialStatus,
    MarketingManager: studentData?.genInfo?.MarketingManager,
    MiddleName: personalInfoData?.MiddleName,
    MobileNo: personalInfoData?.MobileNo,
    MobileNoCountryCode: personalInfoData?.MobileNoCountryCode,
    PassportExpiryDate: personalInfoData?.PassportExpiryDate,
    PassportNo: personalInfoData?.PassportNo,
    Pincode: genContectInfoData?.Pincode,
    PreferredCountryId: studentData?.genInfo?.PreferredCountryId,
    PreferredInstitution: studentData?.genInfo?.PreferredInstitution,
    PreferredProgramId: studentData?.genInfo?.PreferredProgramId,
    PreferredProgramLevel: studentData?.genInfo?.PreferredProgramLevel,
    ProgramTypeID: progranTypeId,
    Province: Number(genContectInfoData?.Province),
    ProvinceName: "",
    PunchedFrom: 0,
    Salution: personalInfoData?.Salution,
    SecLanguage: "",
    StreetName: studentData?.genInfo?.StreetName,
    StudentId: studentId,
    TagLine: ""
  },
    eduInfo: {
      ApplicationId: applicationId,
      CountryOfEducation:Number(educationInfo?.CountryOfEducation),
      HighestLevelOfEducation: Number(educationInfo?.HighestLevelOfEducation),
      GradingScheme: Number(educationInfo?.GradingScheme),
      GradeAverage: educationInfo?.GradeAverage,
      GraduatedMostRecent:0,
      EduYearEnd:educationInfo?.EduYearEnd?educationInfo?.EduYearEnd:"",
      EduYearStart: educationInfo?.EduYearStart?educationInfo?.EduYearStart:"",
      StudentId: studentId
    },
    testScore: {
      StudentId: studentId,
      EnglishExamType: Number(testScoreData?.EnglishExamType),
      EnglishExamDate: testScoreData?.EnglishExamDate,
      EnglishScoreL:Number(testScoreData?.EnglishScoreL),
      EnglishScoreR: Number(testScoreData?.EnglishScoreR),
      EnglishScoreW: Number(testScoreData?.EnglishScoreW),
      EnglishScoreS: Number(testScoreData?.EnglishScoreS),
      GREExamDate: testScoreData?.GREExamDate,
      GREScoreV: Number(testScoreData?.GREScoreV),
      GREScoreQ: Number(testScoreData?.GREScoreQ),
      GREScoreW: Number(testScoreData?.GREScoreW),
      GMATExamDate: testScoreData?.GMATExamDate,
      GMATScoreA: Number(testScoreData?.GMATScoreA),
      GMATScoreI: Number(testScoreData?.GMATScoreI),
      GMATScoreQ: Number(testScoreData?.GMATScoreQ),
      GMATScoreV:Number(testScoreData?.GMATScoreV),
      GREExam: Number(testScoreData?.GREExam),
      GMATExam: Number(testScoreData?.GMATExam),
      EnglishScoreOverall: Number(testScoreData?.EnglishScoreOverall),
      EnglishExamUser: testScoreData?.EnglishExamUser,
      EnglishExamPswd: testScoreData?.EnglishExamPswd,
      EnglishExamRemark: testScoreData?.EnglishExamRemark,
      SATScoreTotal: Number(testScoreData?.SATScoreTotal),
      SATScoreMath:Number(testScoreData?.SATScoreMath),
      SATScoreEBRW:Number(testScoreData?.SATScoreEBRW),
      ACTScoreTotal: Number(testScoreData?.ACTScoreTotal),
      ACTScoreEnglish: Number(testScoreData?.ACTScoreEnglish),
      ACTScoreMath:Number(testScoreData?.ACTScoreMath),
      ACTScoreReading:Number(testScoreData?.ACTScoreReading),
      ACTScoreScience: Number(testScoreData?.ACTScoreScience),
      ACTScoreWriting: Number(testScoreData?.ACTScoreWriting),
      EnglishExamDocPath: testScoreData?.EnglishExamDocPath,
      OtherExamDocPath: testScoreData?.OtherExamDocPath,
      OtherExamUser: testScoreData?.OtherExamUser,
      OtherExamPswd:testScoreData?.OtherExamPswd
    }
  }


setIsLoading(true)
ApplicationService.addApplication(data).then(res=>{
  setIsLoading(false)
  if(res?.message==='Program exist for application' || res?.message==='This institution is not accepting application with your citizenship'){
    toast.error(res?.message)
    return;
  }
   setApplicationId(Number(res?.response))
  toast.success("Application created successfully.")
  updateGeninfoData(data?.genInfo);
  updateEducationData(data?.eduInfo);
  updateTestScoreData(data?.testScore)
  preferenceAdd(Number(res?.response))
  navigate('/member/application/applicationstatus/view/' + Number(res?.response))

}).catch(error=>{
  setIsLoading(false)
  toast.error(error?.message)
})

}

const updateGeninfoData=(data)=>{
  setIsLoading(true)
  ApplicationService.getUpdateGenInfo(data).then(res=>{
    setIsLoading(false)
  }).catch(error=>{
    setIsLoading(false)
  })

}

const updateEducationData=(data)=>{
  data.StudentId=studentId
  setIsLoading(true)
  ApplicationService.udateEducation(data).then(res=>{
    setIsLoading(false)
  }).catch(error=>{
    setIsLoading(false)
  })
}

const updateTestScoreData=(data)=>{
  setIsLoading(true)
  ApplicationService.udateTestScore(data).then(res=>{
    setIsLoading(false)
  }).catch(error=>{
    setIsLoading(false)
  })
}

const preferenceAdd=(applicationIdValue)=>{
let data={
  ApplicationId:applicationIdValue,
  ApplicationPreference:[{CampusId:campusId?campusId:0, IndexId:0, ModeId:Number(modeId),
     ProgramId:Number(programId), ShoreType: Number(residingId), SpecializationId:specializationId?specializationId:0}]
}
setIsLoading(true)
ApplicationService.preferenceAdd(data).then(res=>{
  setIsLoading(false)
  uploadBulkDoc(applicationIdValue)

}).catch(error=>{
  setIsLoading(false)
})
}

const getPendingDoc=(data)=>{
  const newArray = data.map(({ DocPath, DocumentTypeId }) => ({
    DocPath,
    DocumentTypeId,
  }));

  setPendingDoc(newArray)
}

const uploadBulkDoc=(applicationIdValue)=>{
let data={
  Documents:pendingDoc,
  ParentId:applicationIdValue,
  ParentType:7
}

StudentServices.pendingDocumentUpload(data).then(res=>{

}).catch(error=>{
  setIsLoading(false)
  toast.error(error?.message)
})
 }

useEffect(() => {
  if (!initial.current) {
    initial.current = true;
    getCountryCode();
    getCountryList();
    getInstituteList();
    getStudentData();
    getStudentDataBySectionwise();
    getRelation();
    getLanguageList();
    getApplicationList();
    getEducationLevelData();
    getSchoolDetailList();
    getProgramParentDocumentList(studentId);
  }
}, []);

return (
  <>
    <div>
      <div>
        <div>
          <p className={styles["heading"]}>MSM Application</p>
        </div>

          <div className={styles["tabs-section"]}>
            <div className={styles["tab-alignment"]}>
              {tabs.map((item) => (
                <div
                  onClick={() => getScroll(item?.id)}
                  className={styles["tab-text"]}
                >
                  <p>{item?.name}</p>
                </div>
              ))}
            </div>
          </div>
          {programDetails && (
            <div className={styles["program-details"]}>
              <div className="row">
                <div
                  className={`${styles["col-white-background"]} col-md-3 col-sm-12`}
                >
                  <img
                    className={styles["institue-image"]}
                    src={programDetails?.InstLogoPath}
                    alt=""
                  ></img>
                </div>
                <div className={`${styles["col-background"]} col-md-9 col-sm-12`}>
                  <div className="row">
                 
                <div
                  className={`${styles["col-background"]} col-md-4 col-sm-12`}
                >
                  <p className={styles["sub-heading"]}>Duration</p>
                  <p className={styles["sub-heading-result"]}>
                    {programDetails?.DurationTime}
                  </p>
                </div>
                <div
                  className={`${styles["col-background"]} col-md-4 col-sm-12`}
                >
                  <p className={styles["sub-heading"]}>
                    Average Processing Time
                  </p>
                  <p className={styles["sub-heading-result"]}>{programDetails?.AverageProcessingDay}</p>
                </div>
                {programDetails?.FeeDetail.map(fee=>(
                <div
                className={`${styles["col-background"]} col-md-4 col-sm-12`}
              >
                <p className={styles["sub-heading"]}>{fee?.FeeType}</p>
                <p className={styles["sub-heading-result"]}>
                 {programDetails?.ProgramCurrency} {fee?.FeeAmount} {fee?.FeeBasis}
                </p>
              </div>
                ))}
                </div>

              </div>
              </div>
            </div>
          )}

          <div id="Program" className="mb-3">
            <div className={styles["header"]}>
              <p>Program Info</p>
            </div>
            <ProgramSection
              institutionData={institionList}
              programData={programList}
              intakeData={intakeList}
              programModeData={programMode}
              campusList={campusList}
              programSpecializationData={programSpecialization}
              onInstituteChnage={getInstituteId}
              onProgramChnage={getProgramId}
              onIntakeChange={getIntakeId}
              onModeChange={getMode}
              onResidingChange={getResiding}
               onSpecializationChange={getSpecialization} onCampusChange={getCampus}
               getBackupProgramId={getBackupProgramId}
            />
          </div>
          <div id="Documents" className="mb-3">
            <div className={styles["header"]}>
              <p>Documents</p>
            </div>
           {pendingDocumentList.length>0 && (
            <PendingDocumentsSection getPendingDoc={getPendingDoc} applicationId={applicationId} pendingDocumentList={pendingDocumentList} documentList={documentList} />
           )}
            
          </div>
          <div id="Personal" className="mb-3">
            <div className={styles["header"]}>
              <p>Personal Info</p>
            </div>
            {studentData && (<PersonalInformation
              studentDataBySectionWise={studentDataBySectionWise}
              countryList={countryList}
              countryCodeList={countryCodeList}
              languageList={languageList}
              personalInfoData={getPersonalInfoData}
              formData={personalFormData}
            />)}
          </div>
          <div id="Contact" className="mb-3">
            <div className={styles["header"]}>
              <p>Contact Info</p>
            </div>
            <div>
              {studentData && (
              <ContactInfo countryList={countryList} contactInfo={studentData?.genInfo}
              getGenContectInfo={getGenContectInfo} relationList={relationList}>
             </ContactInfo>
              )}

            </div>
          </div>
          <div id="Education" className="mb-3">
            <div className={styles["header"]}>
              <p>Education Info</p>
            </div>
            <div>
              {studentData && (
              <EducationSection
                educationLevelData={educationLevelData}
                grdSchemeData={grdSchemeData} 
                onEducationCountryChange={onEducationCountryChange}
                countryList={countryList}
                schoolList={schoolList}
                deleteSchoolDetail={deleteSchoolDetail}
                studentId={studentId}
                educationInfo={studentData?.eduInfo}
                getEducationInfo={getEducationInfo}
                getSchoolDetailList={getSchoolDetailList}
              ></EducationSection>
            )}
            </div>
          </div>

          <div id="Test" className="mb-3">
            <div className={styles["header"]}>
              <p>Test Scores</p>
            </div>
            {studentData && (
              <TestScores getTestScore={getTestScore} testScoreData={studentData?.testScore} />
            )}
            
          </div>

          <div id="Assigned" className="mb-3">
            <div className={styles["header"]}>
              <p>Assigned Counsellor</p>
            </div>
            <AssignedCounsellor rowData={rowData}></AssignedCounsellor>
          </div>

          <div className="text-end">
            <button className={styles["save-btn"]}>Save</button>
            <button onClick={addApplication} className={styles["save-btn"]}>Generate Application</button>
          </div>
        </div>
      </div>
      {isLoading && (
        <Loader></Loader>
      )}
    </>
  );
};
export default NewApplication;
