import { Form } from "react-bootstrap";
import classes from "./ProgramSection.module.css";
import { useEffect, useState } from "react";
import ApplicationService from "../../../../../../Services/ApplicationServices";
import { toast } from "react-toastify";
import crossIcon from '../../../../../../Assets/Logo/cross-icon.svg'

//  import { Typeahead } from "react-bootstrap-typeahead";

function ProgramSection({institutionData, programData, intakeData, programModeData, programSpecializationData, onInstituteChnage, 
    onProgramChnage, onIntakeChange, onModeChange, onResidingChange, onSpecializationChange, onCampusChange, 
    campusList, getBackupProgramId}) {
        const [intakeId, setIntakeId]=useState(0)
        const [programId, setProgramId]=useState(0)
        const [programBackUp, setProgramBackUp]=useState([])
        const [programBackUp2, setProgramBackUp2]=useState([])
        const [programBackUp3, setProgramBackUp3]=useState([])
        const [response,setResponse]=useState()
        const  [showbackupProgram, setShowbackupProgram]=useState(false)
        const [BProgram1, setBProgram1]=useState(0)
        const [BProgram2, setBProgram2]=useState(0)
        const [BProgram3, setBProgram3]=useState(0)

        const[programBackFinal1, setProgramBackFinal1]=useState({
            campusList:[],
            modeList:[],
            shoreTypeList:[],
            specializationList:[]
        
        })
        const[programBackFinal2, setProgramBackFinal2]=useState({
            campusList:[],
            modeList:[],
            shoreTypeList:[],
            specializationList:[]
        
        })
        const[programBackFinal3, setProgramBackFinal3]=useState({
            campusList:[],
            modeList:[],
            shoreTypeList:[],
            specializationList:[]
        
        })
       

        
        const programbackup = (IntakeId) => {
            ApplicationService.programbackup(IntakeId)
              .then((res) => {
               setProgramBackUp(res?.programList)
               setProgramBackUp2(res?.programList)
               setProgramBackUp3(res?.programList)
               setResponse(res)
              })
              .catch((error) => {
                toast.error(error?.message);
              });
          };
       
        const getIntakeId=(value)=>{
            onIntakeChange(value)
            setIntakeId(value)
            programbackup(value)
        }

        const getProgramId=(value)=>{
            onProgramChnage(value)
            setProgramId(value)
            setIntakeId(0)
            programbackup(0)
        }
        const getInstitutionId=(value)=>{
            onInstituteChnage(value)
            setProgramId(0)
            setIntakeId(0)
            setProgramBackUp([])
            setProgramBackUp2([])
            setProgramBackUp3([])
        }

        const getBackUpProgram1=(programID)=>{
            setBProgram1(programID)
            getBackupProgramId(programID, BProgram2, BProgram3)
            let data={
                campusList:response.campusList.filter(item=> item?.ProgramId===Number(programID)),
                modeList:response.modeList.filter(item=> item?.ProgramId===Number(programID)),
                shoreTypeList:response.shoresList.filter(item=> item?.ProgramId===Number(programID)),
                specializationList:response?.specializationList.filter(item=> item?.ProgramId===Number(programID))
            }
            setProgramBackFinal1(data)
            setProgramBackUp2(response.programList.filter(item=> (item?.ProgramId!==Number(programID) && item?.ProgramId!==Number(BProgram3))))
            setProgramBackUp3(response.programList.filter(item=> (item?.ProgramId!==Number(programID) && item?.ProgramId!==Number(BProgram2))))
        }
        const getBackUpProgram2=(programID)=>{
            setBProgram2(programID)
            getBackupProgramId( BProgram1 ,programID, BProgram3)
            let data={
                campusList:response.campusList.filter(item=> item?.ProgramId===Number(programID)),
                modeList:response.modeList.filter(item=> item?.ProgramId===Number(programID)),
                shoreTypeList:response.shoresList.filter(item=> item?.ProgramId===Number(programID)),
                specializationList:response?.specializationList.filter(item=> item?.ProgramId===Number(programID))
            }
            setProgramBackFinal2(data)
            setProgramBackUp(response.programList.filter(item=> (item?.ProgramId!==Number(programID) && item?.ProgramId!==Number(BProgram3))))
            setProgramBackUp3(response.programList.filter(item=> (item?.ProgramId!==Number(programID) && item?.ProgramId!==Number(BProgram1))))
        }

        const getBackUpProgram3=(programID)=>{
            setBProgram3(programID)
            getBackupProgramId( BProgram1,BProgram2, programID )
            let data={
                campusList:response?.campusList.filter(item=> item?.ProgramId===Number(programID)),
                modeList:response?.modeList.filter(item=> item?.ProgramId===Number(programID)),
                shoreTypeList:response?.shoresList.filter(item=> item?.ProgramId===Number(programID)),
                specializationList:response?.specializationList.filter(item=> item?.ProgramId===Number(programID))
            }
            setProgramBackFinal3(data)
            setProgramBackUp(response.programList.filter(item=> (item?.ProgramId!==Number(programID) && item?.ProgramId!==Number(BProgram2))))
            setProgramBackUp2(response.programList.filter(item=> (item?.ProgramId!==Number(programID) && item?.ProgramId!==Number(BProgram1))))
        }

        const backUPprogramShow=(value)=>{
            setShowbackupProgram(value)
        }

        const clearBProgram2=()=>{
            setBProgram2(0)
            setProgramBackFinal2({
                campusList:[],
                modeList:[],
                shoreTypeList:[],
                specializationList:[]
            })

        }
        const clearBProgram3=()=>{
            setBProgram3(0)
            setProgramBackFinal3({
                campusList:[],
                modeList:[],
                shoreTypeList:[],
                specializationList:[]
            })

        }



    return (
        <>
            <div className={classes["program-info-container"]}>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Institute</Form.Label>
                            <Form.Select aria-label="Institute" onChange={(e)=>{getInstitutionId(e.target.value)}} required>
                                <option className={classes["option-default"]} value="">Select an Institute</option>
                                {institutionData.map(institute=>(
                                    
                                <option  value={institute?.InstitutionId}>{institute?.InstName}</option>
                                ))}

-                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            <Form.Label>Program</Form.Label>
                            <Form.Select aria-label="Program" onChange={(e)=>{getProgramId(e.target.value)}} required>
                            <option className={classes["option-default"]} value="">Select an Program</option>
                               {programData.map(program=>(
                                    <option  value={program?.ProgramId}>{program?.ProgramName}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                            <Form.Label>Intake</Form.Label>
                            <Form.Select aria-label="Intake" onChange={(e)=>{getIntakeId(e.target.value)}} required>
                            <option className={classes["option-default"]} value="">Select an Intake</option>
                            {intakeData.map(intake=>(
                                    <option  value={intake?.IntekId}>{intake?.IntakeName}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </div>
                    {campusList && campusList?.length > 0 && (
                    <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                            <Form.Label>Campus</Form.Label>
                            <Form.Select aria-label="Campus" onChange={(e)=>{onCampusChange(e.target.value)}} required>
                            <option className={classes["option-default"]} value="">Select a Campus</option>
                            {campusList.map(campus=>(
                                    <option  value={campus?.CampusId}>{campus?.CampusName}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </div>
                     ) }
                    <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                            <Form.Label>Residing</Form.Label>
                            <Form.Select aria-label="Residing" onChange={(e)=>{onResidingChange(e.target.value)}} required>
                            <option className={classes["option-default"]} value="">Select a Residing</option>
                                <option value="0">Offshore</option>
                                <option value="1">Onshore</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                            <Form.Label>Mode</Form.Label>
                            <Form.Select aria-label="Intake" onChange={(e)=>{onModeChange(e.target.value)}}>
                            <option className={classes["option-default"]}  value="">Select a Mode</option>
                            {programModeData.map(mode=>(
                                    <option  value={mode?.ModeId}>{mode?.ModeName}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </div>
                    {programSpecializationData && programSpecializationData?.length>0 && (
                    <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3" required>
                            <Form.Label>Specialization</Form.Label>
                            <Form.Select aria-label="Intake" onChange={(e)=>{onSpecializationChange(e.target.value)}}>
                            <option className={classes["option-default"]}  value="">Select an Specialization</option>
                            {programSpecializationData.map(item=>(
                                    <option  value={item?.SpecializationId}>{item?.SpecializationName}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </div>
                    )}
                </div>
                <div className="row mb-4 mt-5">
                     <Form.Check disabled={Number(programId) <= 0 }
                      label="Add backup programs"
                      type="checkbox"
                      name="backup"
                      onChange={(e)=>{backUPprogramShow(e.target.checked)}}
                      className={classes[""]}
                    />
                </div>
                {showbackupProgram &&  (
                    <div>
                    <div className="row">
                    <div className="col-md-1 d-flex align-items-center justify-content-center">
                    <div className={`${classes['backprogram-number']} d-flex justify-content-center align-items-center`}>#1</div>
                    </div>
                    <div className="col-md-11">
                        <div className="row">
                    <div className="col-md-3">
                        <div>
                            <Form.Group className="mb-3" controlId="exampleForm.backupProgram1Intake">
                            <Form.Label>Intake</Form.Label>
                            <Form.Select disabled aria-label="Intake" value={intakeId}>
                                <option className={classes["option-default"]} value="">Select an Intake</option>
                                {intakeData.map(intake=>(
                                    <option value={intake?.IntekId}>{intake?.IntakeName}</option>
                                ))}

-                            </Form.Select>
                        </Form.Group>
                        </div>

                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3" controlId="exampleForm.backupProgram1">
                            <Form.Label>Program</Form.Label>
                            <Form.Select aria-label="Program1" value={BProgram1} onChange={(e)=>{getBackUpProgram1(e.target.value)}} required>
                                <option className={classes["option-default"]} value="">Select an Program</option>
                                {programBackUp.map(program=>(
                                <option  value={program?.ProgramId}>{program?.ProgramName}</option>
                                ))}

-                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3" controlId="exampleForm.backupProgram1Campus">
                            <Form.Label>Campus</Form.Label>
                            <Form.Select aria-label="Campus"  required>
                                <option className={classes["option-default"]} value="">Select an Campus</option>
                                {programBackFinal1?.campusList.map(campus=>(
                                <option  value={campus?.CampusId}>{campus?.CampusName}</option>
                                ))}

-                            </Form.Select>
                        </Form.Group>
                    </div>
                    { programBackFinal1?.shoreTypeList?.length>0 && (
                    <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="exampleForm.backupProgram1Residing">
                        <Form.Label>Student Residing</Form.Label>
                        <Form.Select aria-label="Student Residing" required>
                            <option className={classes["option-default"]} value="">Student Residing</option>
                            {programBackFinal1?.shoreTypeList.map(shortype=>(  
                                <option  value={shortype?.OffShore}>OffShore</option> 
                           
                            ))}
                            {programBackFinal1?.shoreTypeList.map(shortype=>(  
                                <option  value={shortype?.OnShore}>OnShore</option> 
                           
                            ))}

-                            </Form.Select>
                    </Form.Group>
                </div>
                    )}
                   
                    {programBackFinal1?.specializationList?.length>0 && (
                    <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="exampleForm.backupProgram1Specialization">
                        <Form.Label>Specialization</Form.Label>
                        <Form.Select aria-label="Specialization" required>
                            <option className={classes["option-default"]} value="">Select an Specialization</option>
                            {programBackFinal1?.specializationList.map(specialization=>(
                                
                            <option  value={specialization?.SpecializationId}>{specialization?.SpecializationName}</option>
                            ))}

-                            </Form.Select>
                    </Form.Group>
               
                   </div>
                    )}
                    
                    <div className="col-md-3">
                        <Form.Group className="mb-3" controlId="exampleForm.backupProgram1Mode">
                            <Form.Label>Mode</Form.Label>
                            <Form.Select aria-label="Mode" required>
                                <option className={classes["option-default"]} value="">Select an Mode</option>
                                {programBackFinal1?.modeList.map(mode=>(
                                    
                                <option  value={mode?.ModeId}>{mode?.ModeName}</option>
                                ))}

-                            </Form.Select>
                        </Form.Group>
                    </div>
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-1 d-flex align-items-center justify-content-center">
                    <div className={`${classes['backprogram-number']} d-flex justify-content-center align-items-center`}>#2</div>
                    </div>
                    <div className="col-md-11">
                        <div className="row">
                    <div className="col-md-3">
                            <div>
                            <Form.Group className="mb-3" controlId="exampleForm.backupProgram2Intake">
                            <Form.Label>Intake</Form.Label>
                            <Form.Select disabled aria-label="Intake" value={intakeId}>
                                <option className={classes["option-default"]} value="">Select an Intake</option>
                                {intakeData.map(intake=>(
                                    <option value={intake?.IntekId}>{intake?.IntakeName}</option>
                                ))}

-                            </Form.Select>
                        </Form.Group>
                            </div>

                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3" controlId="exampleForm.backupProgram2">
                            <Form.Label>Program</Form.Label>
                            <Form.Select aria-label="Program2" value={BProgram2} onChange={(e)=>{getBackUpProgram2(e.target.value)}} required>
                                <option className={classes["option-default"]} value="">Select an Program</option>
                                {programBackUp2.map(program=>(
                                <option  value={program?.ProgramId}>{program?.ProgramName}</option>
                                ))}

-                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3" controlId="exampleForm.backupProgram2Campus">
                            <Form.Label>Campus</Form.Label>
                            <Form.Select aria-label="Campus"  required>
                                <option className={classes["option-default"]} value="">Select an Campus</option>
                                {programBackFinal2?.campusList.map(campus=>(
                                <option  value={campus?.CampusId}>{campus?.CampusName}</option>
                                ))}

-                            </Form.Select>
                        </Form.Group>
                    </div>
                    { programBackFinal2?.shoreTypeList?.length>0 && (
                    <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="exampleForm.backupProgram2Residing">
                        <Form.Label>Student Residing</Form.Label>
                        <Form.Select aria-label="Student Residing" required>
                            <option className={classes["option-default"]} value="">Student Residing</option>
                            {programBackFinal2?.shoreTypeList.map(shortype=>(  
                                <option  value={shortype?.OffShore}>OffShore</option> 
                           
                            ))}
                            {programBackFinal2?.shoreTypeList.map(shortype=>(  
                                <option  value={shortype?.OnShore}>OnShore</option> 
                           
                            ))}

-                            </Form.Select>
                    </Form.Group>
                </div>
                    )}
                   


                    {programBackFinal2?.specializationList?.length>0 && (
                    <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="exampleForm.backupProgram2Specialization">
                        <Form.Label>Specialization</Form.Label>
                        <Form.Select aria-label="Specialization" required>
                            <option className={classes["option-default"]} value="">Select an Specialization</option>
                            {programBackFinal2?.specializationList.map(specialization=>(
                                
                            <option  value={specialization?.SpecializationId}>{specialization?.SpecializationName}</option>
                            ))}

-                            </Form.Select>
                    </Form.Group>
                </div>
                    )}
                    <div className="col-md-3">
                        <div className="d-flex align-items-center">
                            <div className="w-100">
                        <Form.Group className="mb-3" controlId="exampleForm.backupProgram2Mode">
                            <Form.Label>Mode</Form.Label>
                            <Form.Select aria-label="Mode" required>
                                <option className={classes["option-default"]} value="">Select an Mode</option>
                                {programBackFinal2?.modeList.map(mode=>(
                                    
                                <option  value={mode?.ModeId}>{mode?.ModeName}</option>
                                ))}

-                            </Form.Select>
                        </Form.Group>
                        </div>
                        { BProgram2>0 && (
                        <div>
                            <img onClick={clearBProgram2} className={classes['clearIcon']} title="Clear" src={crossIcon} alt=''></img>
                        </div>
                        )}
                    </div>
                    </div>

                </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-1 d-flex align-items-center justify-content-center">
                    <div className={`${classes['backprogram-number']} d-flex justify-content-center align-items-center`}>#3</div>
                    </div>
                    <div className="col-md-11">
                        <div className="row">
                       <div className="col-md-3">
                        <div>
                            <Form.Group className="mb-3" controlId="exampleForm.backupProgram3Intake">
                            <Form.Label>Intake</Form.Label>
                            <Form.Select disabled aria-label="Intake" value={intakeId}>
                                <option className={classes["option-default"]} value="">Select an Intake</option>
                                {intakeData.map(intake=>(
                                    <option value={intake?.IntekId}>{intake?.IntakeName}</option>
                                ))}

-                            </Form.Select>
                        </Form.Group>
                            </div>

                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3" controlId="exampleForm.backupProgram3">
                            <Form.Label>Program</Form.Label>
                            <Form.Select disabled={BProgram2 <= 0} aria-label="Program2" value={BProgram3} onChange={(e)=>{getBackUpProgram3(e.target.value)}} required>
                                <option className={classes["option-default"]} value="">Select an Program</option>
                                {programBackUp3.map(program=>(
                                <option  value={program?.ProgramId}>{program?.ProgramName}</option>
                                ))}

-                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3" controlId="exampleForm.backupProgram3Campus">
                            <Form.Label>Campus</Form.Label>
                            <Form.Select disabled={BProgram2 <= 0}  aria-label="Campus"  required>
                                <option className={classes["option-default"]} value="">Select an Campus</option>
                                {programBackFinal3?.campusList.map(campus=>(
                                <option  value={campus?.CampusId}>{campus?.CampusName}</option>
                                ))}

-                            </Form.Select>
                        </Form.Group>
                    </div>
                    { programBackFinal3?.shoreTypeList?.length>0 && (
                    <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="exampleForm.backupProgram3Residing">
                        <Form.Label>Student Residing</Form.Label>
                        <Form.Select disabled={BProgram2 <= 0}  aria-label="Student Residing" required>
                            <option className={classes["option-default"]} value="">Student Residing</option>
                            {programBackFinal3?.shoreTypeList.map(shortype=>(  
                                <option  value={shortype?.OffShore}>OffShore</option> 
                           
                            ))}
                            {programBackFinal2?.shoreTypeList.map(shortype=>(  
                                <option  value={shortype?.OnShore}>OnShore</option> 
                           
                            ))}

-                            </Form.Select>
                    </Form.Group>
                    </div>
                    )}
                   

           
                    {programBackFinal3?.specializationList?.length>0 && (
                    <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="exampleForm.backupProgram3Specialization">
                        <Form.Label>Specialization</Form.Label>
                        <Form.Select  disabled={BProgram2 <= 0}  aria-label="Specialization" required>
                            <option className={classes["option-default"]} value="">Select an Specialization</option>
                            {programBackFinal3?.specializationList.map(specialization=>(
                                
                            <option  value={specialization?.SpecializationId}>{specialization?.SpecializationName}</option>
                            ))}

-                            </Form.Select>
                    </Form.Group>
                </div>
                    )}
                             <div className="col-md-3">
                    <div className="d-flex align-items-center">
                    <div className="w-100">
                        <Form.Group className="mb-3" controlId="exampleForm.backupProgram3Mode">
                            <Form.Label>Mode</Form.Label>
                            <Form.Select disabled={BProgram2 <= 0}  aria-label="Mode" required>
                                <option className={classes["option-default"]} value="">Select an Mode</option>
                                {programBackFinal3?.modeList.map(mode=>(
                                    
                                <option  value={mode?.ModeId}>{mode?.ModeName}</option>
                                ))}

-                            </Form.Select>
                        </Form.Group>
                        </div>
                        { BProgram3>0 && (
                            <div>
                            <img onClick={clearBProgram3} className={classes['clearIcon']} title="Clear" src={crossIcon} alt=''></img>
                            </div>
                        )}
                  
                    </div>
                    </div>

                </div>
            </div>
                </div>

                    </div>

                )}


            </div>
        </>
    );
}

export default ProgramSection;