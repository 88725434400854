import React, { useRef, useState } from "react";
import classes from "./UploadDocument.module.css";
import CommonServices from "../../../Services/CommonServices";
import crossIcon from "../../../Assets/Logo/cross-icon.svg";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
 
const UploadDocument = ({ uploadFile, btnText, filePathUrl, acceptedFileType, acceptFileSize, fileFormatText }) => {
  const inputFileRef = useRef();
  const [filePath, setFilePath] = useState(filePathUrl);
  const [fileName, setFileName] = useState(filePathUrl);
  const [isLoading, setIsLoading]=useState(false)
  const blobUrl=process.env.REACT_APP_URL_PATH
  const fileExtention= acceptedFileType ? acceptedFileType : ['image/png', 'image/jpg', 'image/jpeg' , 'application/pdf']
  const fileSize=  acceptFileSize ? acceptFileSize : 5
  const fileFormat= fileFormatText ? fileFormatText : 'JPG, JPEG, PNG and PDF.'
  const fileUpload = (event) => {
    const file = event.target.files[0];
    if(fileExtention.includes(file.type)) {
    if (file.size < fileSize * 1024 * 1024) {
      setFileName(file?.name);
      const formData = new FormData();
      formData.append("file", file);
      inputFileRef.current.value = null;
      setIsLoading(true)
      CommonServices.uploadFile(formData).then((res) => {
        setFilePath(res?.fileLocation);
        uploadFile(res?.fileLocation);
        setIsLoading(false)
      }).catch(error=>{
        setIsLoading(false)
        toast.error(error?.message)
      });
    }else{
      inputFileRef.current.value = null;
      toast.error('File size must be less than '+fileSize+' MB.');
   
    }
  }else{
    inputFileRef.current.value = null;
    toast.error('Supported file format is :' +fileFormat);
  }
 
  };
 
  const onFileUpload = () => {
    inputFileRef.current.click();
  };
  const removeFile=()=>{
    setFilePath('')
    setFileName('')
    uploadFile(null);
  }
  return (
    <>
      <div className={classes['file-name-section']}>
        <div>
          {filePath && (
            <div className={classes['file-name']}>
              <div>
                <a href={blobUrl + filePath} target="_blank">{fileName}</a>
              </div>
              <div>
                <img className={classes['cross-icon']} onClick={removeFile} src={crossIcon} alt=""></img>
              </div>
            </div>
          )}
        </div>
 
        {!filePath && (<div>
          <input
            className={classes["file-input"]}
            type="file"
            aria-label="file upload"
            name="file"
            ref={inputFileRef}
            onChange={fileUpload}
          />
          <button onClick={onFileUpload} className="primary-button">
           {btnText}
          </button>
          <div>
            <ul className={classes['note-text']}>
              <li>Acceptable file formats: JPG, JPEG, PNG, and PDF.
              </li>
              <li>File size must be less than 5 MB.</li>
            </ul>
            </div>
        </div> )}
      </div>
      {isLoading && (
        <Loader></Loader>
      )}
    </>
  );
};
export default UploadDocument;
 