import axiosInstance from "./Interceptor";
const CommonServices = {
  uploadFile: async (data) => {
    try {
      const response = await axiosInstance.post('/common/upload-file/v1', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
}
export default CommonServices;