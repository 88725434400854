import React  from 'react'
import classes from "./PendingDocumentsSection.module.css";
import { Table } from 'react-bootstrap';
import documentDownloadArrow from "../../../../../../Assets/Logo/uploadBlueIcon.svg";
const EditPendingDocumentSection = () => {
    const tableData = {
        columns: ["Documents", "Document Specification"],
        documents: [
            {
                id: 1,
                "Documents": 'Third Party Letter of Authorization',
                "Documents Specification": '',
            },
            {
                id: 2,
                "Documents": 'English Proficiency score card',
                "Documents Specification": '',
            },
            {
                id: 3,
                "Documents": 'Higher Secondary School Transcript',
                "Documents Specification": '',
            },
            {
                id: 4,
                "Documents": 'Passport Copy (Front & Last Page)',
                "Documents Specification": '',
            },
            {
                id: 5,
                "Documents": 'Secondary School Transcript',
                "Documents Specification": '',
            },
        ]
      };
      const handleViewRequest = (id) => {
  };


  return (
    <div className={classes.container}>
      <Table responsive className={classes.customTable}>    
        <thead>
          <tr>
            {tableData.columns.map((column, index) => (
              <th key={index}>
                <div className={classes.thAndIconContainer}>
                  <span className={classes.columnText}>{column}</span>
                </div>
              </th>
            ))}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.documents.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {tableData.columns.map((column, colIndex) => (
                <td key={colIndex}>{row[column]}</td>
              ))}
              <td>
                <div className={`d-flex justify-content-center m-2`}>
                <div
                 style={{cursor:'pointer'}}
                  className={`d-flex justify-content-center`}
                  onClick={() => handleViewRequest(row["id"])}>
                    <span className={` ${classes.centerIcon}`}>
                        <img src={documentDownloadArrow} alt="" />
                        <span className='ps-2'>Upload</span>
                    </span>
                </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table> 
   </div>
  )
}

export default EditPendingDocumentSection