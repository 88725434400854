import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import classes from "./CommonPost.module.css";
import userImage from "../../../../../Assets/Images/picture1.png"; // Path to user image
import crossWhite from "../../../../../Assets/Logo/crose-white.svg"; // Path to cross white icon
import dropdownIcon from "../../../../../Assets/Logo/drop-down-icon-2.svg"; // Path to dropdown icon
import icon1 from "../../../../../Assets/Logo/post-video-icon.svg";
import icon3 from "../../../../../Assets/Logo/like.svg";
import icon4 from "../../../../../Assets/Logo/heartIcon.svg";
import textIcon from "../../../../../Assets/Logo/like.svg";
import dummyImageIcon from "../../../../../Assets/Logo/dummy-image-icon.svg"; // Path to dummy image icon
import emojiIcon from "../../../../../Assets/Logo/emogi1.svg"; // Path to emoji icon
import { toast } from "react-toastify";
import Picker from 'emoji-picker-react';
import { Modal } from "react-bootstrap";
import ConfirmationModal from "../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import UserListModal from "../../../../GlobleShared/Modal/UserListModal/UserListModal";
import PostServices from "../../../../../Services/PostServices";
import Loader from "../../../../GlobleShared/Loader/Loader";
import { useBehaviorSubject } from "../../../../GlobleShared/BehaviorSubject/BehaviorSubject"; 
import useLocalStorage from "../../../../GlobleShared/CustomHooks/useLocalStorage";

const accessType=[{id:"1", name:'Public'},   {id:"2", name: 'My Connections'}, {id:"3", name:'Private'}, {id:"4", name:'Groups'}]
const groupData=[{id:"1", name:'Study Group'},   {id:"2", name: "Engineer's Advice"},
                {id:"3", name:'Family Chat'}, {id:"4", name:'Designers Discuss'}, {id:"5", name:'The New New'}]

function CommonPost({ isModalOpen, closeModal, getPostDetails, editPost, imageMediaURLs, updatePostList, isMediaSelected }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState();
  const [selectedType, setSelectedType] = useState('');
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const reactQuillRef = useRef(null);
  const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const allowedVideoTypes = ['video/mp4'];
  const [videoList, setVideoList]=useState()
  const [imageList, setImageList]=useState([]);
  const fileInputRef = useRef(null);
  const [confirmationBox, setconfirmationBox]=useState(false)
  const [tempImage, setTempImage]=useState([])
  const [isLoading, setIsLoading]=useState(false);
  const [deleteConfirmationBox, setDeleteConfirmationBox]=useState(false);
  const [mediaIndex, setmediaIndex]=useState();
  const { postUpdated,setPostUpdated } = useBehaviorSubject();
  const [accessTypeValue, setAccessTypeValue]=useState("1")
  const [groupValue, setGroupValue]=useState("1")
  const dropDownRef=useRef(null)
  const [selectedMediaType, setSelectedMediaType] = useState(0);
  const [usersListModalOpen, setUsersListModalOpen] = useState(false);
  const [groupType, setGroupType] = useState(false);
  const [userDetail] = useLocalStorage('userDetail');
  const profilePic = userDetail.profilePic;
  const username = (userDetail.first_name ? userDetail?.first_name : '') + ' ' + (userDetail.last_name ? userDetail?.last_name : '');
  

  useEffect(() => {
    if (reactQuillRef.current) {
      reactQuillRef.current.getEditor();
    }
  
    if(editPost){
      if (imageMediaURLs && Array.isArray(imageMediaURLs) && imageMediaURLs.length > 0) {
        let postImages = [];
        imageMediaURLs.forEach((mediaObj)=>{
          postImages.push({postMediaId:mediaObj['postMediaId'],mediaTypeId:mediaObj['mediaTypeId'],file:mediaObj['mediaUrl']});
        })
        setImageList(postImages);
      }
      if (getPostDetails && typeof getPostDetails.postCaption === 'string' && getPostDetails.postCaption.trim().length > 0) {
        setEditorContent(getPostDetails.postCaption);
      }
      
    }
    if(isMediaSelected){
      setIsImageModalOpen(isMediaSelected);
    }
  }, [imageMediaURLs,getPostDetails,editPost, isMediaSelected]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeParentModel = () => {
    closeModal();
    setEditorContent('');
    setSelectedImages([]);
    setSelectedVideos([]);
    setImageList([]);
    setVideoList('')
    //setTempImage([])
    setAccessTypeValue("1")
    setGroupValue("1")
  };

  const openImageModal = (value,mediaTypeId) => {
    setSelectedImages([]);
    setSelectedVideos([]);
    setSelectedType(value);
    setIsImageModalOpen(true);
    setSelectedMediaType(mediaTypeId)
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };

  const handleImageChange = (event) => {
    const uploadFile=Array.from(event.target.files);

    const invalidFiles = uploadFile.filter(file => {
      if(selectedMediaType===2){
        if (!allowedVideoTypes.includes(file.type)) {
          return true;
        }
      }
      if(selectedMediaType===1){
        if (!allowedTypes.includes(file.type)) {
          return true;
        }
      }
      return false;
    });
 
    if (invalidFiles.length > 0) {
      if(selectedMediaType===2){
        toast.error('Invalid file type.')
      }
      if(selectedMediaType===1){
        toast.error('Selected file format : PNG, JPEG and JPG.')
      }
 
     
    }
    else{
    if(selectedMediaType===1){
      // if(event.target.files?.length>0){
      //   setSelectedVideos('')
      //   setVideoList('')
      // }
      if((selectedImages.length + uploadFile?.length) <= 5){
        const files = Array.from(event.target.files);
        const mediaList = files.map((file) => ({
          mediaTypeId: selectedMediaType,
          mediaFiles: file
        }));
        //setTempImage([...tempImage, ...mediaList]);
        fileInputRef.current.value = null;
        const imagePromises = files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
        });
 
        Promise.all(imagePromises).then((images) => {
          let finalList = [];
          images.forEach((image,index)=>{
              finalList.push({mediaTypeId:selectedMediaType,file:image,mediaFiles:mediaList[index]['mediaFiles']});
          })
          setSelectedImages((prevImages) => [...prevImages, ...finalList]);
        });
      } else {
        toast.error("Maximum 5 files you can upload");
        fileInputRef.current.value = null;
      }
    } else if (selectedMediaType === 2) {
      const file = event.target.files[0];
      const files = Array.from(event.target.files);
      const mediaList = files.map((file) => ({
        mediaTypeId: selectedMediaType,
        mediaFiles: file
      }));
      //setTempImage([...tempImage, ...mediaList])
      fileInputRef.current.value = null;
      if (file) {
        const fileUrl = URL.createObjectURL(file);
        let finalList = [{mediaTypeId:selectedMediaType,file:fileUrl,mediaFiles:file}];
          setSelectedImages((prevImages) => [...prevImages , ...finalList]);
          //setSelectedVideos(fileUrl);
      }
    }
  }
 
  };
 

  const insertMedia = () => {
    const quill = reactQuillRef.current.getEditor();
    const length = quill.getLength();

    // selectedImages.forEach((image) => {
    //   quill.insertEmbed(length, "image", image);
    // });

    // selectedVideos.forEach((videoUrl) => {
    //   const videoHtml = `<video controls src="${videoUrl}"></video>`;
    //   quill.clipboard.dangerouslyPasteHTML(length, videoHtml);
    // });
    // if(selectedType==='video'){
    //   setVideoList(selectedVideos)
    // }
    // if(selectedType==='image'){
      //}

      if((imageList?.length + selectedImages?.length) <=5){
        setImageList((prevImages) => [...prevImages,...selectedImages]);
      }
      else{
        toast.error("Maximum 5 files you can upload");
      }
      closeImageModal();
  };

  const deleteImageFromPreview = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    //setTempImage((prevImages) => prevImages.filter((_, i) => i !== index));
  };
  const removeImage= (index,media) => {
    // if(editPost){
    //   setDeleteConfirmationBox(true);
    //   setmediaIndex(index);
    // }
    // else{
    //   setImageList((prevImages) => prevImages.filter((_, i) => i !== mediaIndex));
    // }
    if(!media['postMediaId']){
      //new media action
      setImageList((prevImages) => prevImages.filter((_, i) => i !== index));
    }else{
      //update media
      setDeleteConfirmationBox(true);
      setmediaIndex(index);
    }
  };

  const deleteMediaFile = () => {
    setIsLoading(true);
    let id = imageList[mediaIndex].postMediaId;
    PostServices.deletePostMedia(id).then(res=>{
      setIsLoading(false);
      toast.success(res?.message);
    }).catch(error=>{
      setIsLoading(false)
      toast.success(error?.message);
    })
    setImageList((prevImages) => prevImages.filter((_, i) => i !== mediaIndex));
    //setTempImage((prevImages) => prevImages.filter((_, i) => i !== mediaIndex));
    setDeleteConfirmationBox(false);
  }

  const deleteVideoFromPreview = (index) => {
    setSelectedVideos((prevVideos) => prevVideos.filter((_, i) => i !== index));
  };

  const toggleEmojiPicker = () => {
    setIsEmojiPickerOpen(!isEmojiPickerOpen);
  };

  const onEmojiClick = (event, emojiObject) => {
    const quill = reactQuillRef.current.getEditor();
    if (quill) {
      quill.focus();
      const selection = quill.getSelection(true); 
      const cursorPosition = selection ? selection.index : quill.getLength(); 
      quill.insertText(cursorPosition, event.emoji);
      quill.setSelection(cursorPosition + event?.emoji?.length);
    }
    setIsEmojiPickerOpen(false);
  };

  const removeVideo=()=>{
    setSelectedVideos('')
  }
  const  removeVideoList=()=>{
    setVideoList('')
  }

 const cancelData=()=>{
    closeModal();
    setEditorContent('');
    setSelectedImages([]);
    setSelectedVideos([]);
    setImageList([]);
    setVideoList('')
    setconfirmationBox(false)
    setTempImage([])
    setAccessTypeValue("1")
    setGroupValue("1")
  }
  const closePopUp=()=>{
    setconfirmationBox(true)
  }

  const confrimBox=()=>{
    setconfirmationBox(false)
  }

  const closeDeleteConfirmation=()=>{
    setDeleteConfirmationBox(false);
  }


    const uploadFile = async () => {
        setIsLoading(true)
        let filesToUpload = imageList.filter(mediObj =>{return !mediObj['postMediaId']})
        if(!editPost){
          //insert flow
            const uploadPromises = filesToUpload.map(async (item) => {
              const formData = new FormData();
              formData.append('file', item?.mediaFiles);
              try {
                const res = await PostServices.uploadFile(formData);
                //setIsLoading(false)
                return { mediaTypeId: item.mediaTypeId, mediaUrl: res?.fileLocation };
              } catch (error) {
                setIsLoading(false)
                return null;
              }
            });
            const results = await Promise.all(uploadPromises);
            const successfulUploads = results.filter(result => result !== null);
            addPost(successfulUploads);
        }else{
          //update flow
            const uploadPromises = filesToUpload.map(async (item) => {
              const formData = new FormData();
              formData.append('file', item?.mediaFiles);
              try {
                const res = await PostServices.uploadFile(formData);
                //setIsLoading(false)
                return { mediaTypeId: item.mediaTypeId, mediaUrl: res?.fileLocation };
              } catch (error) {
                setIsLoading(false)
                return null;
              }
            });
            const results = await Promise.all(uploadPromises);
            const successfulUploads = results.filter(result => result !== null);
            updatePostContent(successfulUploads);
        }
    }


    const addPost=(value)=>{
      let data={
          postVisibility:Number(accessTypeValue),
          groupId:Number(groupValue),
          postMedia: value ? value : [],
          postText: editorContent
        }
        setIsLoading(true)
        PostServices.addPost(data).then(res=>{
          setPostUpdated(true)
          setIsLoading(false)
          toast.success(res?.message);
          //updatePostList();
          cancelData();
        }).catch(error=>{
          setIsLoading(false)
          toast.success(error?.message);
        })
    }

    const updatePost = (mediaFiles) => {
      let data={
          optType: 2,
          postId: getPostDetails.postId,
          postMedia: mediaFiles,
          postText: editorContent
      }
      setIsLoading(true);
      PostServices.updatePost(data).then(res=>{
        setIsLoading(false);
        setPostUpdated(true)
        toast.success(res?.message);
        //updatePostList();
        cancelData();
      }).catch(error=>{
        setIsLoading(false)
        toast.success(error?.message)
      })
    }

    const updatePostContent = (media) => {
      let data={
          optType: 1,
          postId: getPostDetails.postId,
          postMedia:[],
          postText: editorContent
      }
      setIsLoading(true);
      PostServices.updatePost(data).then(res=>{
        //setIsLoading(false);
        //toast.success(res?.message);
        //updatePostList();
        //cancelData();
        if(media && media.length > 0)
            updatePost(media)
        else{
          setPostUpdated(true)
          setIsLoading(false);
          toast.success(res?.message);
          //updatePostList();
          cancelData();
        }
      }).catch(error=>{
        setIsLoading(false)
        toast.success(error?.message)
      })
    }

    const getAsccessTYpeValue=(value)=>{
        setAccessTypeValue(value)
        if (value === "3") {  // "3" corresponds to "Private"
          setGroupType(false)
          setUsersListModalOpen(true);  // Open the modal
      }
      if(value === "4"){
        setGroupType(true)
        setUsersListModalOpen(true);
      }
    }

    const getGroupValue=(value)=>{
      setGroupValue(value)

     }
  const handleClickOutside = (event) => {
    if(dropDownRef){
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false) 
        }
    }
  }
  document.addEventListener('mousedown', handleClickOutside);



  const showUsersListModalhandler = () => {
    setUsersListModalOpen(true);
  };
  const hideUsersListModalhandler = () => {
    setUsersListModalOpen(false);
  };

  return (
    <>
      {isModalOpen && (
        <Modal show={isModalOpen}  size={isImageModalOpen?'lg':'xl'}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
          <Modal.Body>
         <div>
              <img
                src={crossWhite}
                alt="Close"
                className={classes["close_icon"]}
                onClick={closePopUp}
              />
              <img
                src={profilePic ? profilePic : userImage}
                alt="User"
                className={classes["user_image"]}
              />
              <div className={classes["user_info"]}>
                <span className={classes["user_name"]}>{username}</span>
                {!editPost && (
                <img
                src={dropdownIcon}
                alt="Dropdown"
                className={isDropdownOpen ? classes['dropdown-arrow'] : classes["dropdown_icon"]}
                onClick={toggleDropdown}
              />
                )}

                {isDropdownOpen && (
                  <div ref={dropDownRef} className={classes["dropdown_menu"]}>
                    <div className={classes['padding-div']}>
                      {accessType.map(item=>(
                      <div className={classes['input-position']}>
                      <input name="access_type"  type="radio" onChange={()=>{getAsccessTYpeValue(item?.id)}} value={accessTypeValue}
                      checked={accessTypeValue===item?.id}></input>
                      <label for={item?.name}>{item?.name}</label>
                     </div>
                    )) }
                    <p>Groups</p>
                    {groupData.map(item=>(
                      <div className={classes['input-position']}>
                      <input name='groups_data' type="radio" onChange={()=>{getGroupValue(item?.id)}} value={groupValue}
                      checked={groupValue===item?.id}></input>
                      <label for={item?.name}>{item?.name}</label>
                     </div>
                    )) }
                    </div>
                  </div>
                )}
              </div>
              <div className={classes['content-box']}>
            <div className={classes["textarea_container"]}>
              <ReactQuill
                value={editorContent}
                onChange={setEditorContent}
                ref={reactQuillRef}
                modules={{
                  toolbar: [
                    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                    [{size: []}],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{'list': 'ordered'}, {'list': 'bullet'}, 
                    //  {'indent': '-1'}, {'indent': '+1'}
                    ],
                    // ['link', 'image', 'video'],
                    ['clean']
                  ],
                }}
                formats={[
                  'header', 'font', 'size',
                  'bold', 'italic', 'underline', 'strike', 'blockquote',
                  'list', 'bullet', 'indent',
                  'link', 'image', 'video'
                ]}
                placeholder="Write your post here..."
              />
              {imageList?.length > 0 && (
                <div className={classes["image_preview"]}>
                  {imageList.map((image, index) => (
                     <div key={index} className={classes["image_container"]}>
                      {image['mediaTypeId']==1 &&  (<img src={image['file']} alt={`Preview ${index}`}/>)}
                      {image['mediaTypeId']==2 &&  (<video  controls width="300px" src={image['file']}> </video>)}
                      <img
                        src={crossWhite}
                        alt="Delete"
                        className={classes["delete_icon"]}
                        onClick={() => removeImage(index,image)}
                      />
                    {/* {image['mediaTypeId']==2 && (<div className={classes["video_container"]}>
                        <video  controls width="300px" src={image['file']}> </video>
                        <img
                            src={crossWhite}
                            alt="Delete"
                            className={classes["delete_icon"]}
                            onClick={() => removeImage(index)}
                          />
                        </div>)} */}
                    </div>
                  ))}
                </div>
              )}
 
             {/* {videoList && (
              <div className={classes["image_preview"]}>
                <div  className={classes["video_container"]}>
                  <video
                  controls
                  width="300px"
                  src={videoList}>
                  </video>
                  <img
                      src={crossWhite}
                      alt="Delete"
                      className={classes["delete_icon"]}
                      onClick={removeVideoList}
                    />
                  </div>
                  </div>
             )} */}
             {isEmojiPickerOpen && (
               <div className={classes["emoji_picker_wrapper"]}>
                 <img
                    src={crossWhite}
                   alt="Close"
                   className={classes["close_icon_emojis"]}
                   onClick={toggleEmojiPicker}
                  />
                 <Picker onEmojiClick={onEmojiClick} />
               </div>
             )}
            </div>
            </div>
            <div className={classes["icons_container"]}>
              <div className={classes["text_with_icon"]}>
                {/* <img title="Image Type"
                  src={textIcon}
                  alt="Text Icon"
                  className={classes["text_icon"]}
                /> */}
                {/* <span>Rewrite with AI</span> */}
              </div>
              <img
                src={dummyImageIcon}
                alt="Dummy_Image"
                className={classes["icon"]}
                onClick={() => { openImageModal('image',1) }}
              />
              <img onClick={() => { openImageModal('video',2) }} title="Video Type" src={icon1} alt="Icon 1" className={classes["icon"]} />
              {/* <img src={icon3} alt="Icon 3" className={classes["icon"]} />
              <img src={icon4} alt="Icon 4" className={classes["icon"]} /> */}
              <img src={emojiIcon} alt="Emoji Icon" className={classes["icon"]} onClick={toggleEmojiPicker} />
            </div>
            <div className={classes["cp_button"]}>
            <button onClick={closePopUp} className={classes['cancel-btn']}>Cancel</button>
            {!editPost && (
              <button disabled={!editorContent} onClick={uploadFile} className={classes["post_button"]}>Post</button>
            )}
            {editPost && (
              <button disabled={!editorContent} onClick={uploadFile} className={classes["post_button"]}>Update</button>
            )}
            </div>
          </div>
        </Modal.Body>
        </Modal>
     
      )}

      {isImageModalOpen && (
              <Modal show={isModalOpen}  size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered>
                <Modal.Body>
           <div>
            <img
              src={crossWhite}
              alt="Close"
              className={classes["close_icon"]}
              onClick={closeImageModal}
            />
            <div className={classes['select-content-box']}>
            <div className={classes["modal_header"]}>
              <h2>Select {selectedMediaType === 1 ? 'Images' : 'Videos'}</h2>
            </div>
            <input ref={fileInputRef} type="file" accept={selectedMediaType === 1 ? "image/*" : "video/*"} multiple onChange={handleImageChange} />
            {selectedImages.length > 0 && (
              <div className={classes["image_preview"]}>
                {selectedImages.map((image, index) => (
                  <div key={index} className={classes["image_container"]}>
                    { image['mediaTypeId']==1 && (<img src={image['file']} alt={`Preview ${index}`}/>)}
                    {image['mediaTypeId']==2  && (<video controlswidth="300px" src={image['file']}></video>)}
                    <img
                      src={crossWhite}
                      alt="Delete"
                      className={classes["delete_icon"]}
                      onClick={() => deleteImageFromPreview(index)}
                    />
                  </div>
                ))}
              </div>
            )}

            {/* {selectedType==='video' && selectedVideos && (
              <div className={classes["image_preview"]}>
                <div  className={classes["video_container"]}>
                  <video
                  controls
                  width="300px"
                  src={selectedVideos}>
                  </video>
                  <img
                      src={crossWhite}
                      alt="Delete"
                      className={classes["delete_icon"]}
                      onClick={removeVideo}
                    />
                  </div>
              </div>
            )} */}
             </div>
             <div className="text-end">
             <button onClick={insertMedia} className={classes["insert_button"]}>
              Insert {selectedType === 'image' ? 'Images' : 'Videos'}
            </button>
             </div>
         
          </div>
        </Modal.Body>
        </Modal>
      )}
         {confirmationBox && (
          <ConfirmationModal onShow={confirmationBox} getConfirmation={cancelData} 
          onClose={confrimBox} btnText='Yes'  confirmationText='Are you sure you want to leave the post ? Your changes will not be saved.'></ConfirmationModal>
        )}
        {deleteConfirmationBox && (
          <ConfirmationModal onShow={deleteConfirmationBox} onClose={closeDeleteConfirmation} getConfirmation={deleteMediaFile} 
            btnText='Yes'  confirmationText='Are you sure you want to delete this media?'></ConfirmationModal>
        )}
         {usersListModalOpen && (
        <UserListModal
          onClose={hideUsersListModalhandler}
          onShow={showUsersListModalhandler}
          groupType={groupType}
          // postIdData={post?.postId}
        ></UserListModal>
      )}
        {isLoading && (
          <Loader></Loader>
        )}
        
    </>
  );
}

export default CommonPost;