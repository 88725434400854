import React from 'react'
import { Form } from 'react-bootstrap'
import classes from './CreditCardSection.module.css';
const CreditCardSection = ({updateValue}) => {
  return (    <>
    <div className={classes['mainContainer']}>
      <div className={classes['container']}>
         <div className="row">
             <div className="col-md-3">
                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                     <Form.Label>Card Type</Form.Label>
                     <input placeholder='NA' className={`form-control`} type='text' aria-label="Card Type"/>
                 </Form.Group>
             </div>
             <div className="col-md-3">
                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                     <Form.Label>Card Number</Form.Label>
                     <input placeholder='NA' className={`form-control`} type='text' aria-label="Card Number"/>
                 </Form.Group>
             </div>
             <div className="col-md-3">
                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                     <Form.Label>CVV</Form.Label>
                     <input placeholder='NA' className={`form-control`} type='text' aria-label="CVV"/>
                 </Form.Group>
             </div>
             <div className="col-md-3">
                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                     <Form.Label>Expiry Date</Form.Label>
                     <input placeholder='NA' className={`form-control`} type='text' aria-label="Expiry Date"/>
                 </Form.Group>
             </div>
             <div className="col-md-12">
                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                     <Form.Label>Name of Card Holder</Form.Label>
                     <input placeholder='NA' className={`form-control`} type='text' aria-label="Overall"/>
                 </Form.Group>
             </div>
             <div className="col-md-12">
                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                     <Form.Label>Address</Form.Label>
                     <input placeholder='NA' className={`form-control`} type='text' aria-label="Overall"/>
                 </Form.Group>
             </div>
         </div>
    </div>
    </div>
</>)
}

export default CreditCardSection