import classes from './FeePaymentModal.module.css';
import cross from '../../../../../Assets/Logo/crose-white.svg';
import { Form } from 'react-bootstrap';

const FeePaymentModal = ({ onClose }) => {
    return (
        <>
            <div className={classes["fee-modal-block"]}>
                <div className={classes["fee-modal-header"]}>
                    <div className={classes["heading"]}>Fee Details</div>
                    <div><img className={classes["cross-icon"]} src={cross} alt="cross" onClick={onClose} /></div>
                </div>
                <div className={classes["fee-modal-body"]}>
                    <Form>
                        <fieldset disabled>
                        <Form.Group className="mb-3">
                                <Form.Label htmlFor="disabledSelect">Fee Type</Form.Label>
                                <Form.Select id="">
                                    <option>Application Fee</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="disabledSelect">Mode of Payment</Form.Label>
                                <Form.Select id="">
                                    <option>Credit Card</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="disabledTextInput">Amount</Form.Label>
                                <Form.Control id="" type="number" placeholder="80" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="disabledTextInput">Date of Deposit</Form.Label>
                                <Form.Control id="" type="date" placeholder="08/09/2024" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="disabledSelect">Payment Schedule</Form.Label>
                                <Form.Select id="">
                                    <option>one-time</option>
                                </Form.Select>
                            </Form.Group>
                            <div className={classes["make-payment-button-block"]}><button className={`${classes["payment-btn"]} primary-button`}>Make Payment</button></div>
                        </fieldset>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default FeePaymentModal;