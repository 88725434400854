import React, { useState, useRef, useEffect } from "react";
import classes from "./Posts.module.css";
import PostPic from "../../../Assets/Images/profile_avatar.png";
import PostImage from "../../../Assets/Images/post_image.png";
import TripleDot from "../../../Assets/Logo/triple_dot.svg";
import PostCrossIcon from "../../../Assets/Logo/post_cross_icon.svg";
import CommentPic from "../../../Assets/Images/comment_user_pic.png";
import Like from "../../../Assets/Logo/like.svg";
import Liked from "../../../Assets/Logo/liked.svg";
import Comment from "../../../Assets/Logo/comment.svg";
import Share from "../../../Assets/Logo/share.svg";
import greyHeart from "../../../Assets/Logo/grey-heart.svg";
import greyEmoji from "../../../Assets/Logo/grey-emogi.svg";
import greyLike from "../../../Assets/Logo/grey-like.svg";
 import Mute from "../../../Assets/Logo/mute.svg";
 import report1 from "../../../Assets/Logo/report1.svg";
 import block from "../../../Assets/Logo/block.svg";
 import repost1 from "../../../Assets/Logo/repost.svg";
import useLocalStorage from "../../GlobleShared/CustomHooks/useLocalStorage";
import PostServices from "../../../Services/PostServices";
import { toast } from "react-toastify";
import Loader from "../../GlobleShared/Loader/Loader";
import PositionLoader from "../../GlobleShared/PositionLoader/PositionLoader";
import PostImagesModal from "../../GlobleShared/Modal/PostImagesModal/PostImagesModal";
import SharePostModal from "../../GlobleShared/Modal/SharePostModal/SharePostModal";
import CommonPost from "../../Pages/Home/Groups/CommonPost/CommonPost";
import { useBehaviorSubject } from "../../GlobleShared/BehaviorSubject/BehaviorSubject";
 import ViewMediaModal from "../../GlobleShared/Modal/ViewMediaModal/ViewMediaModal";
import ConfirmationModal from "../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import ModelForMore from "./ModelForMore";
function InnerPost({ post , refreshPostList, repost, isDashboard, isConnectedUserId}) {

  const [moreBtnPopupOpen, setMoreBtnPopupOpen] = useState(false);
  const [viewComments, setViewComments] = useState(3);
  const [CommentBox, setCommentBox] = useState(false);
  const [comment, setComment] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [mediaUrl, setMediaUrl] = useState('');
const [mediaItems, setMediaItems] = useState([]); 
const [currentMediaIndex, setCurrentMediaIndex] = useState(0); 
const [userDetail] = useLocalStorage('userDetail');
const [mediaTypeId, setMediaTypeId] = useState(null);
  const userId = userDetail.user_id;
  const { postUpdated,setPostUpdated } = useBehaviorSubject();
  // const [liked, setLiked] = useState(post.likedBy.includes(userId));
  const moreBtnRef = useRef(null);
  const [commentList, setCommentList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(3);
  const [postId, setpostId] = useState();
  const [commentCount, setCommentCount] = useState();
  const [isCommentLoading, setIsCommentLoading] = useState(false);
 
  const [postImagesModalOpen, setPostImagesModalOpen] = useState(false);
  const [postShareModalOpen, setPostShareModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditPost, setIsEditPost] = useState(false);
  const [getEditPost, setGetEditPost] = useState({});
  const [imageMediaList, setImageMediaList] = useState([]);
  const [isLikeLoading, setIsLikeLoading] = useState(false);

  const [OpenConformBox, setOpenConformBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modelOpenForMore, setModelOpenForMore] = useState('');
  const [confirmationRepostModal, setConfirmationRepostModal]=useState(false)
  const [postData, setpostData]=useState("")
  const comfirmModal=useRef(null)

  const handleShowModal = (actionName, data) => {
    setMoreBtnPopupOpen(false);
    setModelOpenForMore(actionName); 
  };

  const actionConfirm=()=>{
    setModelOpenForMore(''); 
  }
  const handleCloseModal = () => {
    setModelOpenForMore(''); 
  };
  const showImagesModalhandler = () => {
    setPostImagesModalOpen(true);
  };
  const hideImagesModalhandler = () => {
    setPostImagesModalOpen(false);
  };

  const showConfirmModalhandler = (postDatavalue) => {
    setpostData(postDatavalue)
    setConfirmationRepostModal(true);
  };
  const hideConfirmModalhandler = () => {
    setConfirmationRepostModal(false);
  };

  const showPostShareModalhandler = () => {
    setPostShareModalOpen(true);
  };
  const hidePostShareModalhandler = () => {
    setPostShareModalOpen(false);
  };

  const handleMediaClick = (url, typeId) => {
    setMediaUrl(url);
    setMediaTypeId(typeId);
    setShowModal(true);
  };
  const handleCommentSubmit = (postId, commentId, optTypeValue) => {
    if (comment.trim()) {
      let data ={
        comment: comment,
        optType: optTypeValue,
        postCommentId: commentId>0 ? commentId : 0,
        postId: postId
      };
      setIsCommentLoading(true);
      PostServices.addComments(data)
        .then((res) => {
          setComment("");
          getAddedCommentList(postId);
          setIsCommentLoading(false);
        })
        .catch((error) => {
          toast.error(error?.message);
          setIsCommentLoading(false);
        });
    }
  };
 
  //  const showCommentBox=()=>{
  //   setCommentBox(!CommentBox)
  //  }
 
  const handleViewMoreComments = () => {
    setViewComments((prev) => prev + 4);
  };
 
  const handleShowLessComments = () => {
    setViewComments(3);
  };
 
  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };
 
  const toggleMoreBtnPopup = () => {
    setMoreBtnPopupOpen(true);
  };
 
  const handleLike = () => {
    // if (liked) {
    //   post.likedBy = post.likedBy.filter(id => id !== userId);
    // } else {
    //   post.likedBy.push(userId);
    // }
    // setLiked(!liked);
  };
 
  useEffect(() => {
    function handleClickOutside(event) {
      if (moreBtnRef.current && !moreBtnRef.current.contains(event.target)) {
        setMoreBtnPopupOpen(false);
      }
      if(comfirmModal.current && !comfirmModal.current.contains(event.target)) {
        setModelOpenForMore('');
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [moreBtnRef, modelOpenForMore]);
  const getAddedCommentList = (postId) => {
    let data = {
      limit: limit * page,
      page: 1,
      postId: postId
    }
    setpostId(postId)
    setCommentBox(true)
    setIsCommentLoading(true)
    PostServices.getComments(data).then(res=>{
      setCommentList(res?.list)
      setCommentCount(res?.totalCount)
      setIsCommentLoading(false)
    }).catch(error=>{
      setIsCommentLoading(false)
      toast.error(error?.message)
    })
  }
  const getCommentList = (postId, pageValue) => {
    let data = {
      limit: limit,
      page: pageValue,
      postId: postId
    }
    setpostId(postId)
    setCommentBox(true)
    setIsCommentLoading(true)
    PostServices.getComments(data).then(res=>{
      setCommentList([...commentList, ...res?.list])
      setCommentCount(res?.totalCount)
      setIsCommentLoading(false)
    }).catch(error=>{
      setIsCommentLoading(false)
      toast.error(error?.message)
    })
  }
  // const handleMediaClick = (url, typeId) => {
  //   setMediaUrl(url);
  //   setMediaTypeId(typeId);
  //   setShowModal(true);
  // };
 
  const viewMore=(postId)=>{
    getCommentList(postId, page+1)
    setPage(page+1)
  }
 
  const updateLike=(postId, islike)=>{
    let data={
      isLike: islike,
      postId: postId
    }
    setIsLikeLoading(true)
    PostServices.likePost(data).then(res=>{
      // refreshPostList()
      if(islike){
        post.likes =post.likes + 1
      }
      else{
        post.likes =post.likes - 1
      }
      post.isLike=islike
      setIsLikeLoading(false)
    }).catch(error=>{
      setIsLikeLoading(false)
      toast.error(error?.message)
    })
  }
  
  const editPost = (data) => {
    setGetEditPost(data);
    setIsEditPost(true);
    setIsModalOpen(true);
    getPostMedia();
  }
 
  const closeModal = () => {
    setIsEditPost(false);
    setIsModalOpen(false);
  };
 
  const getPostMedia = () => {
    let images = [];
    if((post.postMediaResponse).length > 0){
      post.postMediaResponse.forEach((media) => {
        if(media.mediaTypeId && media.mediaTypeId===1){
          images.push(media.mediaUrl);
        }
      })
      setImageMediaList(images);
    }
  }
  const deletePostById = (id) =>{
    setIsLoading(true);
    PostServices.deletePostById(id).then(res=>{
      toast.success(res?.message);
      setIsLoading(false)
      setPostUpdated(true)
      setOpenConformBox(false);
    }).catch(error=>{
      setIsLoading(false)
      toast.success(error?.message);
    })
    }
  const cancelData=()=>{
    setOpenConformBox(true);
  }
  const confrimBox=()=>{
      deletePostById(post.postId)
  }
  const closeDeleteModel=()=>{
    setOpenConformBox(false);
  }

  const repostData =()=>{
    repost(postData);
    hideConfirmModalhandler();
    console.log()
  }
 
  return (
    <>
    <CommonPost isModalOpen={isModalOpen} closeModal={closeModal} getPostDetails={getEditPost}
     editPost={isEditPost} imageMediaURLs={getEditPost?.postMediaResponse} updatePostList={refreshPostList} />
    <div ref={comfirmModal} className={classes["post-container"]}>
    {modelOpenForMore && (
      <ModelForMore 
      title={modelOpenForMore}
      onClose={handleCloseModal}
      confirm={actionConfirm}
      ></ModelForMore>
    )}
    <div className={classes["post-block"]}>
      <div className={classes["header"]}>
        <div className={classes["header-left"]}>
          <img
            src={PostPic}
            alt="Profile-Picture" 
            className={classes["profile_picture"]}
          />
          <div className={classes["user_info"]}>
            <div className={classes["user_name"]}>{userDetail?.first_name} {userDetail?.last_name}</div>
          </div>
        </div>
        <div className={classes["header-right"]}>
            <span className={classes["triple-dot"]}
            onClick={() => toggleMoreBtnPopup()}>
              <img src={TripleDot} alt="" />
            </span>
            {(!isDashboard && !isConnectedUserId) && ( <span className={classes["cross-icon"]} onClick={()=>setOpenConformBox(true)}>
              <img src={PostCrossIcon} alt="" />
            </span>
            )}
            {OpenConformBox && (
                <ConfirmationModal  onShow={OpenConformBox} getConfirmation={confrimBox} 
                  onClose={closeDeleteModel} btnText='Yes'  confirmationText='Are you sure you want to delete this post ?'/>
            )}
            {isLoading  && <Loader></Loader>}
            {moreBtnPopupOpen && (
              <div className={classes["MoreBtn"]} ref={moreBtnRef}>
                { (!isDashboard && !isConnectedUserId) && (
                                  <>
                                  <div className={classes["MoreBtnItem"]} onClick={() => editPost(post)}>Edit</div>
                                  <div className={classes["brokenLine"]}></div>
                                  <div className={classes["MoreBtnItem"]} onClick={()=>{cancelData()}}>Delete</div>
                                  <div className={classes["brokenLine"]}></div>
                                  <div className={classes["MoreBtnItem"]} onClick={()=>{showConfirmModalhandler(post)}}>Repost</div>
                                  <div className={classes["brokenLine"]}></div>
                                  <div className={classes["MoreBtnItem"]}>Comment</div>
                                  </>
                )}
                { (isDashboard || isConnectedUserId) && (
                                <>
                                <div className={classes["MoreBtnItem"]} onClick={()=>handleShowModal('Repost', post)}>
                                  <span className={classes["icon"]}><img src={repost1} alt="Repost Icon" /></span>
                                  Repost
                                </div>
                                <div className={classes["brokenLine"]}></div>
                                <div className={classes["MoreBtnItem"]} onClick={()=>handleShowModal('Mute', post)}>
                                  <span className={classes["icon"]}><img src={Mute} alt="Mute Icon" /></span>
                                  Mute 
                                </div>
                                <div className={classes["brokenLine"]}></div>
                                <div className={classes["MoreBtnItem"]} onClick={()=>handleShowModal('Report', post)}>
                                  <span className={classes["icon"]}><img src={report1} alt="Report Icon" /></span>
                                  Report
                                </div>
                                <div className={classes["brokenLine"]}></div>
                                <div className={classes["MoreBtnItem"]} onClick={()=>handleShowModal('Block', post)}>
                                  <span className={classes["icon"]}><img src={block} alt="Block Icon" /></span>
                                  Block
                                </div>
                              </>
                )}
             
              </div>
            )}
            </div>
          </div>
 
          <div className={classes["content"]}>
            <div
              className={classes["post_text"]}
              dangerouslySetInnerHTML={{ __html: post.postCaption }}
            />
          </div>
 
          {/* <div className={classes["post-image"]}>
          {post.postMediaResponse?.length > 0 && post.postMediaResponse.map(media => (
 
            <img key={media.postMediaId} src={media.mediaUrl} alt="" />
            
          ))}
      </div> */}
 
 {/* <div className={classes["post-image"]}>
  <div className={classes["image-card"]}>
    {post.postMediaResponse?.map((media, index) => (
      media.mediaTypeId === 2 ? (
        <video
          key={media?.postMediaId}
          src={media?.mediaUrl}
          controls
          className={classes["image"]}
          onClick={() => handleMediaClick(media.mediaUrl, media.mediaTypeId)}
        />
      ) : (
        <img
          key={media?.postMediaId}
          src={media?.mediaUrl}
          alt=""
          className={classes["image"]}
          onClick={() => handleMediaClick(media.mediaUrl, media.mediaTypeId)}
        />
      )
    ))}
  </div>
</div> */}
 
           {/* for images  */}

           { post.postMediaResponse?.length > 0 && (

           <div className={classes["post-image"]}>
            <div className={ post.postMediaResponse?.length === 1 ? classes["post-image-section-1"]  : post.postMediaResponse?.length === 2 
                   ? classes["post-image-section-2"]  : classes["post-image-section-3"] }>
              {post.postMediaResponse.slice(0,3).map((item)=>(
                <div key={item?.postMediaId}>
                  { item.mediaTypeId===1 && (
                        <img onClick={() => handleMediaClick(item.mediaUrl, item.mediaTypeId)}
                        key={item.postMediaId}
                        src={item.mediaUrl}
                        alt=""
                        className={classes["show-image"]}
                      />
                  )}
                    { item.mediaTypeId===2 && (
                          <video
                          controls
                          width="100%"
                          hieght="auto"
                          className={classes["show-vedio"]}
                          src={item.mediaUrl}>
                          </video>
                  )}
              
                  </div>
                  
            
              ))}
                {post.postMediaResponse?.length > 3 && (
                    <div
                      className={classes["more-image"]}
                      onClick={showImagesModalhandler}
                    >
                      +{post.postMediaResponse?.length - 3} more
                    </div>
                 )}

             

            </div>
          </div>
 
           )}




          <div className={classes["actions-data"]}>
        <p className={classes['count-text']}> 
          <img src={greyLike} alt="" />
          <img className={classes['image-icon']} src={greyEmoji} alt="" />
          <img className={classes['image-icon']} src={greyHeart} alt="" />
        <span> {post?.likes} </span></p>
 
        <p className={classes['count-text']}>
          {commentList?.length} Comments
        </p>
        <p className={classes['count-text']}>
          {post?.shareComment} Share
        </p>
      </div>
 
      <div className={classes["actions"]}>
     
        <button className={classes["action_button"]} onClick={()=>{updateLike(post?.postId, post?.isLike ? false : true)}}>
        { !isLikeLoading && (
          <span>
        <img src={post?.isLike? Liked : Like} alt="" /> <span className={post?.isLike? classes["activeLikeKeyword"] :''}>Like</span>
        </span>
       )}

       { isLikeLoading && (
        <PositionLoader></PositionLoader>
       )} 
        </button>


        <button disabled={postId===post?.postId} onClick={()=>{getCommentList(post?.postId, 1)}} className={classes["action_button"]}>
          <img src={Comment} alt="" />
           Comments
        </button>
        <button className={classes["action_button"]} onClick={showPostShareModalhandler}>
          <img src={Share} alt="" /> Share
        </button>
      </div>
 
          </div>
          {CommentBox && (
            <>
              <div className={classes["add-comment-container"]}>
                <img src={PostPic} alt="User" className={classes["user-pic"]} />
                <input
                  type="text"
                  placeholder="Add a comment..."
                  value={comment}
                  onChange={handleCommentChange}
                  className={classes["input-field"]}
                />
                <button
                  disabled={isCommentLoading}
                  onClick={() => {
                    handleCommentSubmit(post?.postId, 0, 1);
                  }}
                  className={classes["comment-btn"]}
                >
                  Comment
                </button>
              </div>
            </>
          )}
 
          <div className={classes["comments_section"]}>
            <div className={classes["comments_title"]}>
              {post?.postId === postId && commentList?.length > 0 && (
                <div className={classes["text"]}>Comments:</div>
              )}
 
              {/* {viewComments < post.comments.length ? (
              <div onClick={handleViewMoreComments} className={classes["view_more"]}>
                view more...
              </div>
            ) : (
              viewComments > 3 && (
                <div onClick={handleShowLessComments} className={classes["view_more"]}>
                  show less...
                </div>
              )
            )} */}
        </div>
 
        <div className={classes["comments"]}>
          {commentList.map((comment, index) => (
            <div className={classes["comment"]} key={index}>
              <div className={classes["comment-image"]}>
                <img src={comment?.profileImage?comment?.profileImage:PostPic} alt="" /> 
              </div>
 
              <div className={classes["comment_content"]}>
                <div className={classes["comment_author"]}>
                  {comment?.displayName}:
                </div>
                <div>
                {comment?.comment}
                  </div>
                </div>
            </div>
              ))}
          </div>
            {isCommentLoading && post?.postId === postId && (
              <PositionLoader></PositionLoader>
            )}
            {CommentBox &&
              commentList.length !== commentCount &&
              commentList.length > 0 && (
                <div className="text-center">
                  <button
                    onClick={() => {
                      viewMore(post?.postId);
                    }}
                    className={classes["view-more"]}
                  >
                    View More
                  </button>
                </div>
              )}
 
            {!isCommentLoading &&
              CommentBox &&
              post?.postId === postId &&
              commentList.length === 0 && (
                <div className="text-center">
                  <p>No comments found</p>
                </div>
              )}
          </div>
        </div>
        <ViewMediaModal
  show={showModal}
  handleClose={() => setShowModal(false)}
  mediaUrl={mediaUrl}
  mediaTypeId={mediaTypeId}
/>
<ViewMediaModal
  show={showModal}
  handleClose={() => setShowModal(false)}
  mediaUrl={mediaUrl}
  mediaTypeId={mediaTypeId}
/>
      {postImagesModalOpen && (
        
        <PostImagesModal
          onClose={hideImagesModalhandler}
          onShow={showImagesModalhandler}
          data={post.postMediaResponse}
        ></PostImagesModal>
      )}

      {postShareModalOpen && (
        <SharePostModal
          onClose={hidePostShareModalhandler}
          onShow={showPostShareModalhandler}
          postIdData={post?.postId}
        ></SharePostModal>
      )}

        {confirmationRepostModal && (
          <ConfirmationModal onShow={showConfirmModalhandler} getConfirmation={repostData} 
          onClose={hideConfirmModalhandler} btnText='Yes'  confirmationText='Are you sure you want to repost ?'></ConfirmationModal>
        )}
    </>
  );
}
 
function Posts(props) {
  // post api
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [postList, setPostList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const { postUpdated,setPostUpdated } = useBehaviorSubject();
  const initial = useRef(null);
 
  const getPost = (pageValue) => {
    let data = {}
    if(props.connectedUserId){
      data = {
        limit: limit,
        page: pageValue,
        paginated: true,
        searchTerm: "",
        studentId: props.connectedUserId,
      };
    }
    else{
      data = {
        limit: limit,
        page: pageValue,
        paginated: true,
        searchTerm: "",
      };
    }
    setIsLoading(true);
    PostServices.getPostList(data)
      .then((res) => {
        setPostList([...postList, ...res?.postData]);
        setIsLoading(false);
        setTotalCount(res?.totalCount);
        console.log(postList);
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  };
 

  // repost
  const rePost = (post_data) => {
    let data = {
     groupId: post_data?.groupId,
     postMedia: post_data?.postMediaResponse,
     postText: post_data?.postCaption,
     postVisibility:post_data?.postVisibility,
     rePostId: post_data?.postId,
    };
    setIsLoading(true);
    PostServices.addPost(data)
      .then((res) => {
        // setPostList([...postList, ...res?.postData]);
        toast.success(res?.message);
        setPostUpdated(true);
        setIsLoading(false);

        // setTotalCount(res?.totalCount);
        // console.log(postList);
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  };


 
  const refreshConnection = (pageValue) => {
    let data = {
      limit: limit,
      page: pageValue,
      paginated: true,
      searchTerm: "",
    };
    setIsLoading(true);
    PostServices.getPostList(data)
      .then((res) => {
        setPostList([...res?.postData]);
        setIsLoading(false);
        setTotalCount(res?.totalCount);
        console.log(postList);
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  };
 
  const viewMore = () => {
    getPost(page + 1);
    setPage(page + 1);
  };
 
  useEffect(() => {
    if (!initial.current) {
      initial.current = true;
      getPost(page, limit);
    }
  },[])
 
 
  useEffect(() => {
    console.log('postUpdated changed:', postUpdated);
    if (postUpdated) {
      refreshConnection(1)
       setPostUpdated(false); // Reset after fetching
    }
}, [postUpdated]);
 
 
  const getRefrshPage=()=>{
    getPost(page, limit)
  }
  return (
    <>
    <div className={classes["post-main-container"]}>
      {postList.length > 0 && ( <>
      {postList.map((post, index) => (
        <InnerPost refreshPostList={getRefrshPage} key={index} post={post} repost={rePost} isDashboard={props.isDashboard} isConnectedUserId={props.connectedUserId}/> 
     ))}</>
        ) }
 
      <div>
      {(totalCount!==postList?.length && postList.length>0) && (
         <div className="text-center mb-2">
         <button onClick={viewMore} className={classes['view-more']}>View more</button>
          </div>
          )}
        </div>
      </div>
 
      {postList.length===0 && (
        <div className={classes['no-post-data']}>
          <p> No Posts Found</p>
          </div>
      ) }
      {isLoading && <Loader></Loader>}
    </>
  );
}
 
export default Posts;