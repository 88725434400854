import axiosInstance from "./Interceptor";
 
const StudentServices = {
  userService: async (file) => {
    const formData = new FormData();
    console.log(file, 'file');
    formData.append('file', file);
 
    try {
      const response = await axiosInstance.post('/common/upload-file/v1', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
 
  addOrUpdateProfilePic: async (data) => {
    try {
      const response = await axiosInstance.post('/user/add-profile-pic', data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  getStudentDetail: async (studentId) => {
    try {
      const response = await axiosInstance.get('Student/Get?StudentId=' + studentId);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  pendingDocumentUpload: async (data) => {
    try {
      const response = await axiosInstance.post("Student/StudentDocument/AddBulkV2", data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  studentDocumentList: async (parentId, parentTypeId) => {
    try {
      const response = await axiosInstance.get('Student/StudentDocument/List?ParentId=' + parentId + '&ParentType=' + parentTypeId);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

};
 
export default StudentServices;