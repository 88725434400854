import React, { useState, useRef, useEffect }  from 'react';
import classes from "./StudentDocumentsSection.module.css";
import { Button, Form, Table } from 'react-bootstrap';
import deleteIcon from "../../../../../../Assets/Logo/documentDeleteICon.svg";
import logEditICon from "../../../../../../Assets/Logo/logEditApplication.svg";
import actionIcon from "../../../../../../Assets/Logo/table-action.svg";
import documentView from "../../../../../../Assets/Logo/documetnView.svg";
import documentDownloadArrow from "../../../../../../Assets/Logo/uploadBlueIcon.svg";

const StudentDocumentsSection = () => {
  const [popUpView, setPopUpView] = useState(null);
  const dropdownRef = useRef(null);

  const tableData = {
    columns: ["Attachment", "Remark", "Date Uploaded", "UploadedBy"],
    documents: [
      { id: 1, "Attachment": 'Third Party Letter of Authorization', "Remark": '', 'Date Uploaded': '29/08/2024', "UploadedBy": 'Jane Smith' },
      { id: 2, "Attachment": 'English Proficiency score card', "Remark": '', "Date Uploaded": '29/08/2024', "UploadedBy": 'Jane Smith' },
      { id: 3, "Attachment": 'Higher Secondary School Transcript', "Remark": '', "Date Uploaded": '29/08/2024', "UploadedBy": 'Jane Smith' },
      { id: 4, "Attachment": 'Passport Copy (Front & Last Page)', "Remark": '', "Date Uploaded": '29/08/2024', "UploadedBy": 'Jane Smith' },
      { id: 5, "Attachment": 'Secondary School Transcript', "Remark": '', "Date Uploaded": '29/08/2024', "UploadedBy": 'Jane Smith' },
    ],
  };

  const handleViewRequest = (id) => {
    console.log('View request for document ID:', id);
  };

  function onClickOutsideHandler(event) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setPopUpView(null);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutsideHandler);
    return () => {
      document.removeEventListener('mousedown', onClickOutsideHandler);
    };
  }, []);

  return (
    <div className={classes.container}>
      <Table responsive className={classes.customTable}>
        <thead>
          <tr>
            {tableData.columns.map((column, index) => (
              <th key={index}>
                <div className={classes.thAndIconContainer}>
                  <span className={classes.columnText}>{column}</span>
                </div>
              </th>
            ))}
            <th>View File</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.documents.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {tableData.columns.map((column, colIndex) => (
                <td key={colIndex}>{row[column]}</td>
              ))}
              <td>
                <span
                  className={classes.actionBtn}
                  onClick={() => handleViewRequest(row.id)}>
                  <img src={documentView} className={classes.actionICon} alt="View" />
                </span>
              </td>
              <td>
                <img
                  src={actionIcon}
                  alt="Action"
                  className={classes.icon}
                  onClick={() => setPopUpView(row.id)}
                />
                {popUpView === row.id && (
                  <div className={classes.positionRelative} ref={dropdownRef}>
                  <div className={classes.popContainer} >
                    <div className={classes.IConAndText} onClick={() => handleViewRequest(row.id)}>
                      <div>
                        <img className={classes.popUpICon} src={documentDownloadArrow} alt="Edit" />
                      </div>
                      <div>
                        <span className={classes.btnText}>Upload</span>
                      </div>
                    </div>
                    <div className={classes.IConAndText} onClick={() => handleViewRequest(row.id)}>
                      <div>
                        <img className={classes.popUpICon} src={logEditICon} alt="Edit" />
                      </div>
                      <div>
                        <span className={classes.btnText}>Log</span>
                      </div>
                    </div>
                    <div className={classes.IConAndText}>
                      <div>
                        <img className={classes.popUpICon} src={deleteIcon} alt="Delete" />
                      </div>
                      <div>
                        <span className={classes.btnText}>Delete</span>
                      </div>
                    </div>
                  </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default StudentDocumentsSection;
