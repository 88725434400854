import React from 'react'
import axiosInstance from './Interceptor';

const ProfileService  = {
    getAboutProfileInfo: async (studentId) => {
        try {
          const response = await axiosInstance.get('/Student/AboutProfileInfo/?StudentId='+studentId);
          return response.data;
        } catch (error) {
          throw error.response.data
        }
      },
      updateAboutProfileInfo: async (payload) => {
        try {
          const response = await axiosInstance.put('/Student/AboutProfileInfo', payload);
          return response.data;
        } catch (error) {
          throw error.response.data
        }
      },
      saveStudentInterests: async (payload) => {
        try {
          const response = await axiosInstance.post('/Student/StudentInterest/Add',payload);
          return response.data;
        } catch (error) {
          throw error.response.data
        }
      },
      getInterestsInfo: async (studentId) => {
        try {
          const response = await axiosInstance.get('/Student/StudentInterest/Get?StudentId='+studentId);
          return response.data;
        } catch (error) {
          throw error.response.data
        }
      },
      deleteStudentInterests: async (studentId) => {
        try {
          const response = await axiosInstance.delete('/Student/StudentInterest/Delete?StudentInterestId='+studentId);
          return response.data;
        } catch (error) {
          throw error.response.data
        }
      },
      getStudentSkills: async (studentId) => {
        try {
          const response = await axiosInstance.get(`/Student/StudentSkill/Get?StudentId=${studentId}`);
          return response.data;
        } catch (error) {
          throw error.response.data;
        }
      },
      addStudentSkill: async (request) => {
        try {
          const response = await axiosInstance.post('/Student/StudentSkill/Add', request);
          return response.data;
        } catch (error) {
          throw error.response.data;
        }
      },
      deleteStudentSkill: async (skillId) => {
        try {
          const response = await axiosInstance.delete(`/Student/StudentSkill/Delete?StudentInterestId=${skillId}`);
          return response.data;
        } catch (error) {
          throw error.response.data;
        }
      },

      getCompetencyInfo: async (id) => {
        try {
          const response = await axiosInstance.get('/Student/Competency/?StudentId='+id);
          return response.data;
        } catch (error) {
          throw error.response.data
        }
      },
      addCompetencyInfo: async (data) => {
        try {
          const response = await axiosInstance.post('/Student/Competency',data);
          return response.data;
        } catch (error) {
          throw error.response.data
        }
      },
      deleteCompetencyInfo: async (id) => {
        try {
          const response = await axiosInstance.post(`/Student/deleteCompetency?StudentCompetencyID=${id}`);
          return response.data;
        } catch (error) {
          throw error.response.data
        }
      },
      addWorkExperience: async (payload) => {
        try {
          const response = await axiosInstance.post('/Student/WorkExperience/Add', payload);
          return response.data;
        } catch (error) {
          throw error.response.data;
        }
      },
    
      deleteWorkExperience: async (workExpId) => {
        try {
          const response = await axiosInstance.delete(`/Student/WorkExperience/Delete?StudentWorkExpID=${workExpId}`);
          return response.data;
        } catch (error) {
          throw error.response.data;
        }
      },
    
      updateWorkExperience: async (payload) => {
        try {
          const response = await axiosInstance.put('/Student/WorkExperience/Update', payload);
          return response.data;
        } catch (error) {
          throw error.response.data;
        }
      },
    
      getWorkExperienceList: async (studentId) => {
        try {
          const response = await axiosInstance.get(`/Student/WorkExperience/List?StudentID=${studentId}`);
          return response.data;
        } catch (error) {
          throw error.response.data;
        }
      },
      getConnectionCount: async () => {
        try {
          const response = await axiosInstance.get(`/user/connection-count`);
          return response.data;
        } catch (error) {
          throw error.response.data;
        }
      },
      getPassportDetail: async (id) => {
        try {
          const response = await axiosInstance.get(`/Student/PassportDetail?StudentId=`+id);
          return response.data;
        } catch (error) {
          throw error.response.data;
        }
      },
      updatePassportDetail: async (data) => {
        try {
          const response = await axiosInstance.put(`/Student/UpdatePassportDetail`, data);
          return response.data;
        } catch (error) {
          throw error.response.data;
        }
      },
      countryList:async (data) => {
        try {
          const response = await axiosInstance.get('/user/countryList');
          return response.data;
        } catch (error) {
          throw error.response.data
        }
      },
      VisaType:async (data) => {
        try {
          const response = await axiosInstance.get(`/Misc/VisaType?CountryId=${data}`);
          return response.data;
        } catch (error) {
          throw error.response.data
        }
      },
      StatusType:async (data) => {
        try {
          const response = await axiosInstance.get(`Misc/Status?ActivityType=7&RoleId=3`);
          return response.data;
        } catch (error) {
          throw error.response.data
        }
      },
      addStudentDocument: async (payload) => {
        try {
          const response = await axiosInstance.post('/Student/StudentDocument/Add', payload);
          return response.data;
        } catch (error) {
          throw error.response.data;
        }
      },
      addStudentVisa:async (data) => {
        try {
          const response = await axiosInstance.post('/StudentVisa/Add',data);
          return response.data;
        } catch (error) {
          throw error.response.data
        }
      },
      updateStudentVisa:async (data) => {
        try {
          const response = await axiosInstance.put('/StudentVisa/Update',data);
          return response.data;
        } catch (error) {
          throw error.response.data
        }
      },
      deleteStudentVisa:async (visaId, userId) => {
        try {
          const response = await axiosInstance.delete(`/StudentVisa/Delete?StudentVisaId=${visaId}&UserId=${userId}`);
          return response.data;
        } catch (error) {
          throw error.response.data
        }
      },
      getStudentVisa:async (id) => {
        try {
          const response = await axiosInstance.get(`/StudentVisa/List?StudentId=${id}`);
          return response.data;
        } catch (error) {
          throw error.response.data
        }
      },
      getSkills:async (id) => {
        try {
          const response = await axiosInstance.get(`/Misc/Skills`);
          return response.data;
        } catch (error) {
          throw error.response.data
        }
      },
      getInterests:async (id) => {
        try {
          const response = await axiosInstance.get(`/Misc/Interests`);
          return response.data;
        } catch (error) {
          throw error.response.data
        }
      },
      getCompetency:async (id) => {
        try {
          const response = await axiosInstance.get(`/Misc/Competency`);
          return response.data;
        } catch (error) {
          throw error.response.data
        }
      },
}

export default ProfileService