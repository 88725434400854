import classes from './ApplicationSubmit.module.css';
import emailIcon from '../../../../../Assets/Logo/email-icon.svg';
import phoneIcon from '../../../../../Assets/Logo/contact-icon.svg';
import { Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../../GlobleShared/Loader/Loader';
import ApplicationService from '../../../../../Services/ApplicationServices';
import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';
import useLocalStorage from '../../../../GlobleShared/CustomHooks/useLocalStorage';
import ProfileService from '../../../../../Services/ProfileService';
import SearchModal from '../../Search/SearchModal/SearchModal';
import FeePaymentModal from '../FeePaymentModal/FeePaymentModal';
import UploadPendingDocumentsModall from '../UploadPendingDocumentsModall/UploadPendingDocumentsModall';

const ApplicationSubmit = () => {
    const initial = useRef(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [applicationData, setApplicationData] = useState({});
    const [feeData, setFeeData] = useState([]);
    const [studentData, setStudentData] = useState({});
    const [feeAndDocumentStatusData, setFeeAndDocumentStatusData] = useState({});
    const [userDetail] = useLocalStorage('userDetail');
    const { applicationId } = useParams();
    const [feeModalIsVisible, setFeeModalIsVisible] = useState(false);
    const [uploadDocumentsModalIsVisible, setUploadDocumentsModalIsVisible] = useState(false);
    const [modalSize, setModalSize] = useState('');

    useEffect(() => {
        if (!initial.current) {
            initial.current = true;
            if (applicationId) {
                getApplicationData(applicationId);
                getFeeAndDocumentStatus(applicationId);
            }
            if (userDetail.refId) {
                getStudentData(userDetail.refId);
            }
        }
    }, [applicationId, userDetail.refId]);

    const getApplicationData = (id) => {
        setIsLoading(true)
        ApplicationService.viewV1Application(id)
            .then((res) => {
                if (res) {
                    setApplicationData(res);
                    getApplicationFee(res);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };

    const getFeeAndDocumentStatus = (id) => {
        setIsLoading(true)
        ApplicationService.feeAndDocumentStatus(id)
            .then((res) => {
                if (res) {
                    setFeeAndDocumentStatusData(res);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };

    const getStudentData = (id) => {
        setIsLoading(true);
        ProfileService.getAboutProfileInfo(id).then((res) => {
            setStudentData(res);
            setIsLoading(false);
        }).catch((error) => {
            console.log('error: ', error);
            setIsLoading(false);
        });
    }

    const getApplicationFee = (res) => {
        const data = {
            ApplicationId: applicationId,
            IntakeId: res?.programInfo?.IntekId,
            Nationality: res?.genInfo?.Citizenship
        }
        setIsLoading(true)
        ApplicationService.forApplication(data)
            .then((res) => {
                setFeeData(res)
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };

    const backToApplicationList = () => {
        navigate('/member/application/list');
    }

    const feePaymentHandle = () => {
        setModalSize('md');
        setFeeModalIsVisible(true);
    }

    const hidefeeModalhandler = () => {
        setFeeModalIsVisible(false);
        setModalSize('');
    }

    const uploadDocumentsHandle = () => {
        setModalSize('xl');
        setUploadDocumentsModalIsVisible(true);
    }

    const hideUploadDocumentModalhandler = () => {
        setUploadDocumentsModalIsVisible(false);
        setModalSize('');
    }

    const submitDocuments = (isDocumentPending) => {
        setUploadDocumentsModalIsVisible(false);
        setModalSize('');
        if(isDocumentPending){
            getFeeAndDocumentStatus(applicationId);
        }
        else{
            return;
        }
    }

    return (
        <>

            {feeModalIsVisible && (
                <SearchModal onShow={feeModalIsVisible} size={modalSize}>
                    <FeePaymentModal onClose={hidefeeModalhandler} />
                </SearchModal>
            )}
            {uploadDocumentsModalIsVisible && (
                <SearchModal onShow={uploadDocumentsModalIsVisible} size={modalSize}>
                    <UploadPendingDocumentsModall onClose={hideUploadDocumentModalhandler} applicationId={applicationId} programId={applicationData.programInfo.ProgramId}
                     onSubmitPendingDocuments={submitDocuments} countryId={applicationData?.genInfo?.Citizenship} />
                </SearchModal>
            )}
            <div className={classes["application-submit-container"]}>
                <div className={classes["application-submit-section"]}>
                    <div className={classes["section-1"]}>
                        <div className={classes["application-submit-heading"]}>
                            Application for
                            {studentData.FirstName && studentData.LastName
                                ? ` ${studentData.FirstName} ${studentData.LastName} `
                                : 'NA'}
                            ({applicationData?.genInfo?.ProgramType || 'NA'})
                        </div>
                        <div className={classes["application-list-button"]}>
                            <button className={`${classes["back-to-appList"]} secondary-button`} onClick={backToApplicationList}>Go to Application List</button>
                        </div>
                    </div>
                    <div className={classes["section-2"]}>
                        <div className={classes["student-detail-section"]}>
                            <div className={classes["student-name"]}>{studentData.FirstName && studentData.LastName ? `${studentData.FirstName} ${studentData.LastName}` : 'NA'}</div>
                            <div className={classes["student-personal-details"]}>
                                <div className={classes["student-data"]}>
                                    MSM ID: {userDetail.refId ? `${userDetail.refId}` : 'NA'}
                                </div>
                                <div className={classes["detail-block"]}>
                                    <div>
                                        <img src={emailIcon} alt="email" />
                                    </div>
                                    <div className={classes["student-data"]}>{studentData.Email ? `${studentData.Email}` : 'NA'}</div>
                                </div>
                                <div className={classes["detail-block"]}>
                                    <div>
                                        <img src={phoneIcon} alt="phone" />
                                    </div>
                                    <div className={classes["student-data"]}>+{studentData?.MobileNoCountryCode} {studentData?.MobileNo}</div>
                                </div>
                            </div>
                        </div>
                        <div className={classes["application-submit-button"]}>
                            <button className={`${classes["submit-btn"]} primary-button`}>Submit Now</button>
                        </div>
                    </div>
                    <div className={classes["section-3"]}>
                        <div className={classes["application-detail-heading"]}>Application Details</div>
                        <div className={classes["application-detail-table"]}>
                            <Table responsive className={classes.customTable}>
                                <thead>
                                    <tr>
                                        <th>Program</th>
                                        <th>Intake</th>
                                        <th>Institution</th>
                                        <th>Application Fee</th>
                                        <th>Documents</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{applicationData?.programInfo?.ProgramName ? `${applicationData?.programInfo?.ProgramName}` : 'NA'}</td>
                                        <td>{applicationData?.programInfo?.IntakeName ? `${applicationData?.programInfo?.IntakeName}` : 'NA'}</td>
                                        <td>{applicationData?.programInfo?.InstName ? `${applicationData?.programInfo?.InstName}` : 'NA'}</td>
                                        <td>
                                            <div className={classes["action-block"]}>
                                                <div>Fee to pay: {applicationData?.programInfo?.InstCurrency ? `${applicationData?.programInfo?.InstCurrency}` : 'NA'} 400</div>
                                                <div><button className={`${classes["payment-modal-btn"]} primary-button`} onClick={feePaymentHandle}>Make Payment</button></div>
                                                <div>View Fee Breakup</div>
                                            </div>
                                        </td>
                                        <td><div className={classes["action-block"]}>
                                            <div className={feeAndDocumentStatusData.DocumentsStatus === 'Success' ? classes["pending-success-alert"] : classes["pending-alert"]}>{feeAndDocumentStatusData.DocumentsStatus ? `${feeAndDocumentStatusData.DocumentsStatus}` : ''}</div>
                                            {feeAndDocumentStatusData.DocumentsStatus && (feeAndDocumentStatusData.DocumentsStatus === "Pending") && (
                                                <div><button className={`${classes["upload-modal-btn"]} primary-button`} onClick={uploadDocumentsHandle}>Upload</button></div>
                                            )}
                                        </div></td>
                                    </tr>
                                    {/* <tr>
                                <td colSpan={columns.length + 1} className={classes.noData}>
                                    No Data Available
                                </td>
                            </tr> */}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && (
                <Loader></Loader>
            )}
        </>
    )
}

export default ApplicationSubmit;