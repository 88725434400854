import React, { useState, useRef, useEffect } from "react";
import { Form } from "react-bootstrap";
import classes from "./PersonalInformation.module.css";
import documentDownloadArrow from "../../../../../../Assets/Logo/documentDownloadArrow.svg";

function PersonalInformation({ personalInfoData, formData, countryList, languageList }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [personalData, setPersonalData] = useState(formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    personalInfoData({ ...personalData, [name]: value })
    setPersonalData({ ...personalData, [name]: value })
    
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleUploadBoxClick = () => {
    fileInputRef.current.click();
  };

  useEffect(()=>{
    if(formData){
      setPersonalData(formData)
      personalInfoData(formData)
    }
  }, [])

  return (
    <>
      <div className={classes["mainContainer"]}>
        <div className={classes.headingText}>
          <span>Personal Information</span>
        </div>
        <div className={classes["container"]}>
          <div className="row">
            <div className="col-md-3">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Title <span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <Form.Select
                  aria-label="Title"
                  name="Salution"
                  value={personalData?.Salution}
                  onChange={handleChange}
                >
                  <option value="Mr."> Mr.</option>
                  <option value="Ms."> Ms.</option>
                  <option value="Miss"> Miss</option>
                  <option value="Mrs."> Mrs.</option>
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="FirstName">
                <Form.Label>
                  First Name <span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="text"
                  name="FirstName"
                  className="form-control"
                  value={personalData?.FirstName}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="MiddleName">
                <Form.Label>Middle Name</Form.Label>
                <input
                  type="text"
                  name="MiddleName"
                  className="form-control"
                  value={personalData?.MiddleName}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="LastName">
                <Form.Label>
                  Last Name<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="text"
                  name="LastName"
                  className="form-control"
                  value={personalData?.LastName}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="Gender">
                <Form.Label>
                  Gender<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <Form.Select
                  name="Gender"
                  value={personalData?.Gender}
                  onChange={handleChange}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="MaritialStatus">
                <Form.Label>
                  Marital Status<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <Form.Select
                  name="MaritialStatus"
                  value={personalData?.MaritialStatus}
                  onChange={handleChange}
                >
                  <option value="">Select Status</option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                  <option value="divorced">Divorced</option>
                  <option value="widowed">Widowed</option>
                  <option value="separated">Separated</option>
                  <option value="common-law">Common-law</option>
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="DateOfBirth">
                <Form.Label>
                  Date of Birth<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="date"
                  name="DateOfBirth"
                  className="form-control"
                  value={personalData?.DateOfBirth}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="Language">
                <Form.Label>First Language</Form.Label>
                <Form.Select
                  name="Language"
                  value={personalData?.Language}
                  onChange={handleChange}
                >
                  <option value="">Select Language</option>
                  {languageList.map((language, index) => (
                    <option key={index} value={language?.LanguageName}>
                      {language.LanguageName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="Email">
                <Form.Label>
                  Email<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="Email"
                  name="Email"
                  className="form-control"
                  value={personalData?.Email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="MobileNoCountryCode">
                <Form.Label>
                  Country Code<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <Form.Select
                  name="MobileNoCountryCode"
                  value={personalData?.MobileNoCountryCode}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Country Code</option>
                  {countryList.map((country, index) => (
                    <option key={index} value={country?.CountryCode}>
                      <span
                        className={`${country?.flag}`}
                        style={{ marginRight: "5px" }}
                      ></span>
                      {country?.CountryCode} ({country?.CountryName})
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="MobileNo">
                <Form.Label>
                  Phone Number<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="tel"
                  name="MobileNo"
                  className="form-control"
                  value={personalData?.MobileNo}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </div>
          </div>
        </div>
    { Number(personalData?.Citizenship)===101 && (
        <div
        className={`${classes.headingText} d-flex align-items-center mb-4 mt-4`}
      >
        <span>Passport Details</span>
        <span className={` ${classes.centerIcon} ${classes.uploadBtn} ms-2`}>
          <input
            type="file"
            id="upload-passport"
            className="d-none"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept="image/png, image/jpeg, application/pdf"
            onChange={handleFileChange}
          />
          <span onClick={handleUploadBoxClick}>
            <img
              src={documentDownloadArrow}
              className={classes.actionICon}
              alt=""
            />
            <span className={`${classes.uploadPassport} ps-2`}>
              Upload Passport
            </span>
          </span>
        </span>
        <div className="d-flex justify-content-center m-2">
          {selectedFile ? selectedFile.name : "No file chosen"}
        </div>
      </div>
     )}

        <div className={classes["container"]}>
          <div className="row">
            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="Citizenship">
                <Form.Label>
                  Country of Citizenship
                  <span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <Form.Select
                  name="Citizenship"
                  value={personalData?.Citizenship}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Citizenship</option>
                  {countryList.map((country, index) => (
                    <option key={index} value={country?.CountryId}>
                      <span
                        className={`fi fi-${country?.CShortName.toLowerCase()}`}
                        style={{ marginRight: "5px" }}
                      ></span>
                      {country?.CountryName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="PassportNo">
                <Form.Label>
                  Passport Number<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="text"
                  name="PassportNo"
                  className="form-control"
                  value={personalData?.PassportNo}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="PassportExpiryDate">
                <Form.Label>
                  Expiry Date<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="date"
                  name="PassportExpiryDate"
                  className="form-control"
                  value={personalData?.PassportExpiryDate?personalData?.PassportExpiryDate.split('T')[0] : ''}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PersonalInformation;
