import React, { useState, useRef, useEffect } from 'react';
import classes from "./PendingDocumentsSection.module.css";
import { Button, Form, Table } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import deleteIcon from "../../../../../../Assets/Logo/documentDeleteICon.svg";
import corssIcon from "../../../../../../Assets/Logo/cross-icon.svg";
import documentView from "../../../../../../Assets/Logo/documetnView.svg";
import documentDownloadArrow from "../../../../../../Assets/Logo/documentDownloadArrow.svg";
import uploadArrow from "../../../../../../Assets/Logo/uploadArrow.svg";
import SearchModal from '../../../Search/SearchModal/SearchModal';
import infoICon from "../../../../../../Assets/Logo/info-iconBlack.svg";
import CommonServices from '../../../../../../Services/CommonServices';
import { toast } from 'react-toastify';
import Loader from '../../../../../GlobleShared/Loader/Loader';
import ConfirmationModal from '../../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal';
import useLocalStorage from '../../../../../GlobleShared/CustomHooks/useLocalStorage';

const PendingDocumentsSection = ({pendingDocumentList, documentList, getPendingDoc}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDocumentName, setSelectedDocumentName] = useState(''); // State for selected document name
  const fileInputRef = useRef(null); 
  const [filePath, setFilePath] = useState();
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState("");
  const [isLoading, setIsLoading]=useState(false)
  const blobUrl=process.env.REACT_APP_URL_PATH
  const [uploadDocumentList, setUploadDocumentList]=useState([])
  const [docData, setDocData]=useState('')
  const currentDate=new Date()
  const [confrimModalShow, seConfrimModalShow]=useState(false)
  const [confirmationText, setConfirmationText]=useState('')
  const [tempData, setTempData]=useState([]);
  const [userDetail] = useLocalStorage('userDetail');

  const handleClose = () => {
    setShowModal(false);
  };

  const tableData = {
    columns: ["Attachment", "Remark", "Date Uploaded", "UploadedBy"],
  };

  const handleViewRequest = (data) => {
    setSelectedDocumentName(data?.DocumentName);
    setFileName(data?.DocPath)
    setShowModal(true);
    setDocData(data)
  };

  const handleFileChange = (event) => {
    const fileData=event.target.files[0]
     setFile(event.target.files[0]) ;
    if(fileData.type ==='image/png' || fileData.type ==='image/jpg' ||  fileData.type ==='image/jpeg' || fileData.type ==='application/pdf'){
      setFileName(fileData?.name);
      fileInputRef.current.value = null;
    }else{
      toast.error('Supported file format is :JPG, JPEG, PNG and PDF');
      fileInputRef.current.value = null;
      return;
    }

  };

 const uploadFile=()=>{
  const formData = new FormData();
  formData.append("file", file);

  setIsLoading(true)
    CommonServices.uploadFile(formData).then((res) => {
      setFilePath(res?.fileLocation);
      setIsLoading(false)
      setShowModal(false);
      pendingDocumentList.map(item=>{
        if(item.DocumentTypeId===docData.DocumentTypeId){
          item['DocPath']=res?.fileLocation
          item['DocumentTypeId']=item.DocumentTypeId
          item['UploadBy']=userDetail?.first_name
        }
      })
      setTempData(pendingDocumentList);
      getPendingDoc(pendingDocumentList)

    }).catch(error=>{
      setIsLoading(false)
      toast.error(error?.message)
    });
  }


  const clearSelectedFile = () => {
    setFileName('')
    setFile(null);
  };

  const handleUploadBoxClick = () => {
    fileInputRef.current.click();
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric'};
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options) ;
  };

  const deleteDoc=(data)=>{
    seConfrimModalShow(true)
     setDocData(data)
     setConfirmationText('Are you sure, you want to delete the selected document?')
  }
  const getConfirmation=()=>{
    setFileName('')
    setFile(null);
      pendingDocumentList.map(item=>{
        if(item.DocumentTypeId===docData.DocumentTypeId){
          item['DocPath']=''
           item['UploadBy']=''
           item['AddStamp']=''
           item['AdmissionRemark']=''
        }
      })
      setTempData(pendingDocumentList);
      seConfrimModalShow(false)
  }

  useEffect(()=>{
    if(pendingDocumentList && pendingDocumentList.length > 0){
     pendingDocumentList.map(item=>{
        if(documentList.length>0){
          documentList?.filter(data=>{
            if(data.DocumentTypeId ===item.DocumentTypeId){
              item['UploadBy']=data['UploadBy']
              item['DocPath']=data['DocPath']
              item['AddStamp']=data['AddStamp']
              item['AdmissionRemark']=data['AdmissionRemark']
            }
          })
        }
      })
      setTempData(pendingDocumentList);
     }
  }, [])

  const onClose=()=>{
    seConfrimModalShow(false)
  }
  return (
    <div className={classes.container}>
      <Table responsive className={classes.customTable}>    
        <thead>
          <tr>
            {tableData.columns.map((column, index) => (
              <th key={index}>
                <div className={classes.thAndIconContainer}>
                  <span className={classes.columnText}>{column}</span>
                </div>
              </th>
            ))}
            <th>View File</th>
            <th>Action</th>
          </tr>
        </thead>
        {tempData.length>0}
        <tbody>
        
          {tempData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>{row?.DocumentName}</td> 
              <td>{row?.Remark}</td>
              <td>{ row?.DocPath && (
                 <span>{row?.AddStamp ? formatDate(row?.AddStamp):formatDate(currentDate)}</span>
              )}</td> 
              <td>{row?.UploadBy}</td> 
              <td>
                { row?.DocPath && (
                <a href={blobUrl + row?.DocPath} target='_blank'>
                <span className={classes.actionBtn}>
                <img src={documentView} className={classes.actionICon} alt="" />
                </span>
                </a>
                )}

              </td>
              <td>
                <div className={`d-flex justify-content-center align-items-center`}>
                <div
                  className={`${classes.actionBtn} d-flex justify-content-center`}
                  onClick={() => handleViewRequest(row)}>
                    <span className={` ${classes.centerIcon} ${classes.uploadBtn}`}>
                        <img src={documentDownloadArrow} className={classes.actionICon} alt="" />
                        <span className='ps-2'>Upload</span>
                    </span>
                </div>
                {row?.DocPath && (
                <div 
                className={`${classes.actionBtn} ${classes.centerIcon}`}
                onClick={()=>{deleteDoc(row)}}>
              <img src={deleteIcon} className={classes.actionICon} alt="" />
              </div>
                )}
          
                  </div>
              </td>
            </tr>
          ))}
          </tbody>
          
          {pendingDocumentList.length===0 && (
            <tbody>
            <p>No pending document</p>
            </tbody>
          )}
        
      </Table> 
      <ConfirmationModal onShow={confrimModalShow} onClose={onClose}  btnText={"Yes"} 
      confirmationText={confirmationText} getConfirmation={getConfirmation}></ConfirmationModal>
      <SearchModal
        onShow={showModal}
        size="lg"
      >  
        <div className={classes.ModelContainer}>
          <div className={`${classes.headerContainer}`}>
            <img
              title="Remove"
              className={classes.corssIcon}
              src={corssIcon}
              alt=""
              onClick={handleClose}
            />
          </div>
          <div className="container">
            <div className={classes.title}>Upload Document</div>
            <Form.Select aria-label="Default select example" disabled>
              <option>{selectedDocumentName || 'Open this select menu'}</option>
            </Form.Select>
            <div>
              <div className='d-flex justify-content-center'>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  accept=".jpg,.jpeg,.png,.pdf"
                />
                <div className={classes.uploadBoxContainer}
                  onClick={handleUploadBoxClick}>
                  <img src={uploadArrow} alt="" />
                  <span className='ps-2'>
                   Choose a file or drag it here.
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-center m-2">
                {fileName && (
                  <div className='d-flex align-items-center'>
                    {fileName}
                    <img
                      src={corssIcon}
                      alt="Remove"
                      className='ms-2'
                      style={{ cursor: 'pointer' }}
                      onClick={clearSelectedFile}
                    />
                  </div>
                )}
              </div>
              <div>
                <Alert key={`warning`} variant={`warning`}>
                  <div><img src={infoICon} className="me-1" /><span>Please use document name as a file name. </span></div>
                  <ul className="ms-2">
                    <li>Acceptable file formats: JPG, JPEG, PNG, and PDF.</li>
                    <li>File size must be less than 5 MB.</li>
                  </ul>
                </Alert>
              </div>
            </div>
          </div>
          <div className={classes.btnContainer}>
            <Button disabled={!file}
              className={`${classes.btnUpload}`}
              onClick={uploadFile}>
              Upload
            </Button>
          </div>
        </div> 
      </SearchModal>
      {isLoading && (
        <Loader></Loader>
      )}
      
    </div>
  )
}

export default PendingDocumentsSection;