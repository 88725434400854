import React, { useState } from "react";
import styles from './ApplicationDetails.module.css';
import institutesImage from "../../../../../Assets/Images/institute-image.svg"
import rightGreen from "../../../../../Assets/Logo/rightGreen.svg"
import ProgramSection from "../NewApplication/ProgramSection/ProgramSection";
import EducationSection from "../NewApplication/EducationSection/EducationSection";
import TestScores from "../NewApplication/TestScores/TestScores";
import AssignedCounsellor from "../NewApplication/AssignedCounsellor/AssignedCounsellor";
import CreditCardSection from "../NewApplication/CreditCardSection/CreditCardSection";
import StudentDocumentsSection from "../NewApplication/StudentDocumentsSection/StudentDocumentsSection";
import EditPendingDocumentSection from "../NewApplication/PendingDocumentsSection/EditPendingDocumentSection";
import InstitutionDocumentsSection from "../NewApplication/InstitutionDocumentsSection/InstitutionDocumentsSection";
import FeeSection from "../NewApplication/FeeSection/FeeSection";
import NoteSection from "../NewApplication/NoteSection/NoteSection";
const tabs=[{name:'Program', id:'Program'}, {name:'Education', id:'Education'},
    {name:'Test Score', id:'TestScore'},{name:'Credit Card', id:'CreditCard'}, {name:'Student Documents', id:'StudentDocuments'},
    {name:'Pending Documents', id:'PendingDocuments'}, {name:'Institution Documents', id:'InstitutionDocuments'},
    {name:'Assigned Counsellor', id:'AssignedCounsellor'}
]

const ApplicationDetails=()=>{
    const [programDetails, setProgramDetails]=useState('');

    const getScroll =(value)=>{
        document.getElementById(value).scrollIntoView({behavior:'smooth', block:'nearest'});
    }

  const  getProgramDetails=(value)=>{
    setProgramDetails(value)

    }

   return(
       <>
        <div>
            <div>
                <div>
                    <p className={styles['heading']}>Edit MSM Application</p>
                </div>

                <div className={styles['tabs-section']}>
                <div className={styles['tab-alignment']}>
                    {tabs.map(item=>(
                        <div onClick={()=>getScroll(item?.id)} className={styles['tab-text']}><p>{item?.name}</p></div>
                    ))}
                </div>

                </div>
                {!programDetails && (
                    <div className={styles['program-details']}>
                    <div className="row">
                        <div className={`${styles['col-white-background']} col-md-3 col-sm-12`}>
                            <img className={styles['institue-image']} src={institutesImage} alt=''></img>
                            
                        </div>
                        <div className={`${styles['col-background']} col-md-3 col-sm-12`}>
                            <p className={styles['sub-heading']}>Duration</p>
                            <p className={styles['sub-heading-result']}>4 years</p>
                            <p className={styles['sub-heading']}>Application Fee</p>
                            <p className={styles['sub-heading-result']}>CAD 80 one-time</p>

                        </div>
                        <div className={`${styles['col-background']} col-md-3 col-sm-12`}>
                            <p className={styles['sub-heading']}>Average Processing Time</p>
                            <p className={styles['sub-heading-result']}>4 weeks</p>
                            <p className={styles['sub-heading']}>LOA Deposit</p>
                            <p className={styles['sub-heading-result']}>CAD 2500 one-time</p>

                        </div>
                        <div className={`${styles['col-background']} col-md-3 col-sm-12`}>
                            <p className={styles['sub-heading']}>LOA Deposit</p>
                            <p className={styles['sub-heading-result']}>CAD 2500 one-time</p>
                            <p className={styles['sub-heading']}>Tuition Fee</p>
                            <p className={styles['sub-heading-result']}>CAD 19,579 per year</p>

                        </div>

                    </div>
                </div>
                )}
           
                <div id="Program" className="mb-3">
                    <div className={styles['header']}>
                        <p>Program</p>
                    </div>
                    <ProgramSection/>
                </div>
                <div id="Education" className="mb-3">
                <div className={styles['header']}>
                        <p>Education</p>
                </div>
                <div>
                    <EducationSection/>
                </div>
                </div>
                <div id="TestScores" className="mb-3">
                <div className={styles['header']}>
                        <p>Test Scores</p>
                    </div>
                    <TestScores updateValue={true}/>
                </div>
                <div id="CreditCard" className="mb-3">
                <div className={styles['header']}>
                        <p>Credit Card</p>
                    </div>
                    <CreditCardSection/>
                </div>
                <div id="StudentDocuments" className="mb-3">
                <div className={styles['header']}>
                        <p>Student Documents</p>
                    </div>
                   <StudentDocumentsSection/>
                </div>
                <div id="PendingDocuments" className="mb-3">
                <div className={styles['header']}>
                        <p>Pending Documents</p>
                    </div>
                    <EditPendingDocumentSection/>
                </div>
                <div id="Institution Documents" className="mb-3">
                <div className={styles['header']}>
                        <p>Institution Documents</p>
                    </div>
                    <InstitutionDocumentsSection/>
                </div>
                <div id="AssignedCounsellor" className="mb-3">
                <div className={styles['header']}>
                        <p>Assigned Counsellor</p>
                </div>
                <AssignedCounsellor />
                </div>
                <div id="Fee" className="mb-3">
                <div className={styles['header']}>
                        <p>Fee</p>
                    </div>
                    <FeeSection/>
                </div>
                <div id="Contact" className="mb-3">
                <div className={styles['header']}>
                        <p>Note</p>
                    </div>
                    <div>
                        <NoteSection/>
                    </div>
                </div>
                    <div className={styles['endContainer']}>
                        <div><img src={rightGreen} alt="" /><span className={styles['endLabel']}>Submitted to MSM Unify</span></div>
                        <div><input type="radio" name="" id="" /><span className={styles['endLabel']}>Review Done</span></div>
                        <div><input type="radio" name="" id="" /><span className={styles['endLabel']}>Submitted to Institution</span></div>
                    </div>
                <div className="text-end">
                    <button className={styles['save-btn']}>Save</button>
                    <button className={styles['submit-btn']}>Submit</button>
                </div>
            </div>
       </div>
       </>
   )
}
export default ApplicationDetails;