import React, { useEffect, useRef, useState } from "react";
import classes from "./ApplicationList.module.css"
import { Form, Table } from "react-bootstrap";
import srName from "../../../../../Assets/Logo/srnum.svg"
import sortName from "../../../../../Assets/Logo/sort.svg";
import actionIcon from "../../../../../Assets/Logo/table-action.svg";
import { useNavigate } from "react-router-dom";
import ApplicationCategory from "../../../../GlobleShared/ApplicationCategoryModal/ApplicationCategory";
import deleteIcon from "../../../../../Assets/Logo/documentDeleteICon.svg";
import editICon from "../../../../../Assets/Logo/editBlueICon.svg";
import ApplicationService from "../../../../../Services/ApplicationServices";
import Loader from "../../../../GlobleShared/Loader/Loader";
const ApplicationList = () => {
    const navigate= useNavigate();
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [popUpView, setPopUpView] = useState(false);
    const dropdownRef = useRef();
    const [selectedFilter, setSelectedFilter] = useState('All');
    const [tableData, setTableData] = useState([]);
       const columns = [
        { title: "Application ID", dataKey: "ApplicationId" },
        { title: "Status", dataKey: "StatusName" },
        { title: "Application Created", dataKey: "ApplicationDate" },
        { title: "Submission Date", dataKey: "SubmitDate" },
        { title: "Institution Name", dataKey: "InstName" },
        { title: "Email ID", dataKey: "Email" },
        { title: "Contact Number", dataKey: "MobileNo" },
        { title: "Document Status", dataKey: "DocumentsStatus" },
    ];
    const [isLoading, setIsLoading] = useState(false);

    const initial =useRef(null)
    const addApplication =(value)=>{
        navigate('/member/application/add/'+value)

    }
    const getAllApplicationRowData = (value) =>{
        let payload={
          "keyword": "All",
          "status": "",
          "intake": ""
      }
      if (value === 'Online/Blended') {
          setIsLoading(true);
          ApplicationService.getAllApplicationOnline(payload).then((res)=>{
              if (res) {
                  setTableData(res.data);
                  setIsLoading(false);
              }
              setIsLoading(false);
          }).catch((error)=>{
              console.log('error: ', error);
              setIsLoading(false);
          });
      } else {
          setIsLoading(true);
          ApplicationService.getAllApplicationData(payload).then((res)=>{
              if (res) {
                  setTableData(res.data);
                  setIsLoading(false);
              }
              setIsLoading(false);
          }).catch((error)=>{
              console.log('error: ', error);
              setIsLoading(false);
          });
      }
      
    }
    const navigateToEditApplication = (id) => {
        navigate('/member/application/details/' + id);
    };

    const showModalhandler=()=>{
        setModalIsVisible(true);
    }
    const hideModalHandler = (value) => {
        setModalIsVisible(value);
    }

    function onClickOutsideHandler(event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setPopUpView(false);
        }
    }
    const handleFilterChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedFilter(selectedValue);
    };
    useEffect(() => {
        document.addEventListener('mousedown', onClickOutsideHandler);
        return () => {
            document.removeEventListener('mousedown', onClickOutsideHandler);
        };
    }, []);
    useEffect(() => {
     if(!initial.current){
        initial.current=true
        getAllApplicationRowData(selectedFilter);

     }
       
    }, [selectedFilter]);
    return (
        <>
            {isLoading && <Loader></Loader>}
            <div className={classes["application-list-container"]}>
                <div className={classes["section-1"]}>
                    <div className={classes["heading"]}>MSM Application List</div>
                    <div>
                        <button onClick={showModalhandler} className={`${classes["new-application-btn"]} secondary-button`}>+ New Application</button>
                    </div>
                </div>
                <div className={classes["section-2"]}>
                    <Form className={classes["radio-button-block"]}>
                        <Form.Check
                            inline
                            label="All"
                            value="All"
                            checked={selectedFilter === "All"}
                            onChange={handleFilterChange}
                            type="radio"
                            id="radio-1"
                            className={classes["radio-btn"]}
                        />
                        <Form.Check
                            inline
                            label="Online/Blended"
                            value="Online/Blended"
                            checked={selectedFilter === "Online/Blended"}
                            onChange={handleFilterChange}
                            type="radio"
                            id="radio-2"
                            className={classes["radio-btn"]}
                        />
                    </Form>
                </div>
                <div className={classes["section-3"]}>
                    <Table responsive className={classes.customTable}>
                        <thead>
                            <tr>
                                {columns.map((column, index) => (
                                    <th key={index}>
                                        {index === 0 ? (
                                            <div className={classes.thAndIconContainer}>
                                                <span className={classes.columnText}>{column.title}</span>
                                                <img src={srName} alt="Sr Name" className={classes.icon} />
                                            </div>
                                        ) : (
                                            <div className={classes.thAndIconContainer}>
                                                <span className={classes.columnText}>{column.title}</span>
                                                <img src={sortName} alt="Sort" className={classes.icon} />
                                            </div>
                                        )}
                                    </th>
                                ))}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>{tableData.length > 0 ?
                            tableData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {columns.map((column, colIndex) => (
                                        <td key={colIndex}>
                                            {row[column.dataKey] || "N/A"}
                                        </td>
                                    ))}
                                    <td>
                                        <img src={actionIcon} alt="Action" className={classes.icon} onClick={() => setPopUpView(row.ApplicationId)} />
                                        <div className={classes.positionRelative}>
                                            {popUpView === row.ApplicationId && (
                                                <div className={classes.popContainer} ref={dropdownRef}>
                                                    <div className={classes.IConAndText}>
                                                        <div><img className={classes.popUpICon} src={deleteIcon} /></div>
                                                        <div><span className={classes.btnText}>Delete</span></div>
                                                    </div>
                                                    <div className={classes.IConAndText} onClick={() => navigateToEditApplication(row.ApplicationId)}>
                                                        <div><img className={classes.popUpICon} src={editICon} /></div>
                                                        <div><span className={classes.btnText}>Create Deferral Request</span></div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td colSpan={columns.length + 1} className={classes.noData}>
                                    No Data Available
                                </td>
                            </tr>

                            }
                        </tbody>
                    </Table>
                </div>

                {modalIsVisible && (
                    <ApplicationCategory onClose={hideModalHandler} onShow={modalIsVisible} navigate={addApplication} />
                )}
            </div>
        </>
    )
}

export default ApplicationList;