import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import styles from "./ContactInfo.module.css";
import ApplicationService from "../../../../../../Services/ApplicationServices";
import { toast } from "react-toastify";

const ContactInfo = ({countryList, getProvinceListData, getCityListData, contactInfo, getGenContectInfo, relationList }) => {
 
  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [provinceListForMailing, setProvinceListForMailing] = useState([]);
          
  const [cityListForMailing, setCityListForMailing] = useState([]);

  const [ mailingAddressSame, setMailingAddressSame] =useState(false);

  const [genInfo, setGenInfo] = useState({
    Addres: contactInfo?.Addres,
    Country:contactInfo?.Country,
    Province: contactInfo?.Province,
    City:contactInfo?.City,
    Pincode: contactInfo?.Pincode,
    MailingAddress: contactInfo?.MailingAddress,
    MailingCountry: contactInfo?.MailingCountry,
    MailingProvince:contactInfo?.MailingProvince,
    MailingCity: contactInfo?.City,
    MailingPincode: contactInfo?.MailingPincode,
    EmergencyName: contactInfo?.EmergencyName,
    EmergencyRelation: contactInfo?.EmergencyRelation,
    EmergencyEmail: contactInfo?.EmergencyEmail,
    EmergencyCellPhoneCountryCode: contactInfo?.EmergencyCellPhoneCountryCode,
    EmergencyCellPhone: contactInfo?.EmergencyCellPhone,
    EmergencyAddress: contactInfo?.EmergencyAddress,
    MailingAddressSame:contactInfo?.MailingAddressSame
  });





  const handleChange = (e) => {
    const { name, value } = e.target;
    setGenInfo({ ...genInfo, [name]: value });
    getGenContectInfo({ ...genInfo, [name]: value })
  };



  const toggleMailingAddressSame = (e) => {
   
    const { name, checked } = e.target;
    if(e.target.checked){
      getProvinceListForMailing(genInfo?.Country)
      getCityListForMailing(Number(genInfo?.Province))
      setGenInfo((prev) => ({
        ...prev,
        MailingAddress: prev.Addres,
        MailingCountry: prev.Country,
        MailingProvince: prev.Province,
        MailingCity:  prev.City,
        MailingPincode: prev.Pincode,
      }));
    }else{
      setGenInfo((prev) => ({
        ...prev,
        MailingAddress: "",
        MailingCountry: 0,
        MailingProvince: 0,
        MailingCity:  "",
        MailingPincode: "",
      }));
    }


    setMailingAddressSame(e.target.checked);
    getGenContectInfo({ ...genInfo, [name]: checked })
  };

  const getProvinceList = (value) => {
    genInfo.Province="";
    genInfo.City="";
    ApplicationService.getProvince(value)
      .then((res) => {
        setProvinceList(res);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
      if(mailingAddressSame){
        getProvinceListForMailing(value)
      }
  };

  const getCityList = (value) => {
    genInfo.City="";
    ApplicationService.getCity(value)
      .then((res) => {
        setCityList(res);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
      if(mailingAddressSame){
        if(value){
          getCityListForMailing(Number(value))
          setGenInfo((prev) => ({
            ...prev,
            MailingProvince: value,
          }));
        }
   
      }
  };

  const getProvinceListForMailing = (value) => {
    genInfo.MailingProvince="";
    genInfo.MailingCity="";
    ApplicationService.getProvince(value)
      .then((res) => {
        setProvinceListForMailing(res);
        if(mailingAddressSame){
          if(genInfo.Province){
            getCityListForMailing(genInfo.Province)
          }
          
        }
       
      })
      .catch((error) => {
        toast.error(error?.message);
      });

  };

  const getCityListForMailing = (value) => {
    genInfo.MailingCity="";
    ApplicationService.getCity(value)
      .then((res) => {
        setCityListForMailing(res);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  useEffect(()=>{
    getGenContectInfo(genInfo)
    if(genInfo.Country && genInfo.Country > 0){
      getProvinceList(genInfo.Country)
    }
    if(genInfo.Province && Number(genInfo.Province) > 0){
     getCityList(genInfo.Province)
    }
  }, [])


  return (
    <div className={styles["data-section"]}>
      <Form>
        <div className={styles["heading"]}>
          <p>Residential Address</p>
        </div>
        <div className={styles["address"]}>
          <div className="row">
            <div className="col-md-12">
              <Form.Group className="mb-3" controlId="Addres">
                <Form.Label> Address</Form.Label>
                <Form.Control
                  type="text"
                  name="Addres"
                  placeholder="Addres"
                  value={genInfo.Addres}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="country">
                <Form.Label> Country</Form.Label>
                <Form.Select
                  aria-label="Country"
                  name="Country"
                  value={genInfo.Country}
                  onChange={(e)=>{getProvinceList(e.target.value); handleChange(e)}}
                  required
                >
                  <option className={styles["option-default"]} value="">Select a country</option>
                  {countryList.map((country) => (
                    <option key={country.CountryId} value={country.CountryId}>
                      {country.CountryName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="provinceState">
                <Form.Label> Province/State</Form.Label>
                <Form.Select
                  name="Province"
                  aria-label="Province/State"
                  value={genInfo.Province}
                  onChange={(e)=>{getCityList(e.target.value); handleChange(e)}}
                  required
                  // disabled={!genInfo.Country}
                >
                  <option className={styles["option-default"]} value="">Select a province/state</option>
                  {provinceList.map((province) => (
                    <option
                      key={province.ProvinceId}
                      value={province.ProvinceId}
                    >
                      {province.ProvinceName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="cityTown">
                <Form.Label> City/Town</Form.Label>
                <Form.Select
                  name="City"
                  aria-label="City/Town"
                  value={genInfo.City}
                  onChange={handleChange}
                  required
                >
                  <option  className={styles["option-default"]} value="">Select a city/town</option>
                  {cityList.map((city) => (
                    <option key={city.CityName} value={city.CityName}>
                      {city.CityName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="postalZipCode">
                <Form.Label> Postal/Zip Code</Form.Label>
                <Form.Control
                  type="text"
                  name="Pincode"
                  placeholder="Postal/Zip Code"
                  value={genInfo.Pincode}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
          </div>
        </div>

        <div className={styles["heading"]}>
          <p>Mailing Address</p>
          <div>
            <div className={styles["checkbox-container"]}>
              <input
                className={styles["checkbox-input"]}
                type="checkbox"
                name="MailingAddressSame"
                value={genInfo.MailingAddressSame}
                onChange={(e)=>{toggleMailingAddressSame(e)}}
              /> 
              <span>Same as above</span>
            </div>
          </div>
        </div>
        <div className={styles["address"]}>
          <div className="row">
            <div className="col-md-12">
              <Form.Group className="mb-3" controlId="MailingAddress">
                <Form.Label> Address</Form.Label>
                <Form.Control
                  type="text"
                  name="MailingAddress"
                  placeholder="MailingAddress"
                  value={
                    mailingAddressSame
                      ? genInfo.Addres
                      : genInfo.MailingAddress
                  }
                  onChange={handleChange}
                  disabled={mailingAddressSame}
                />
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="MailingCountry">
                <Form.Label> Country</Form.Label>
                <Form.Select
                  name="MailingCountry"
                  value={
                    mailingAddressSame
                      ? genInfo.Country
                      : genInfo.MailingCountry
                  }
                  onChange={(e)=>{getProvinceListForMailing(e.target.value); handleChange(e)}}
                  disabled={mailingAddressSame}
                  required
                >
                  <option className={styles["option-default"]} value="">Select a country</option>
                  {countryList.map((country) => (
                    <option key={country.CountryId} value={country.CountryId}>
                      {country.CountryName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="MailingProvince">
                <Form.Label> Province/State</Form.Label>
                <Form.Select
                  name="MailingProvince"
                  value={
                    mailingAddressSame
                      ? genInfo.Province
                      : genInfo.MailingProvince
                  }
                  onChange={(e)=>{getCityListForMailing(e.target.value); handleChange(e)}}
                  required
                  disabled={mailingAddressSame}
                >
                  <option className={styles["option-default"]} value="">Select a province/state</option>
                  {provinceListForMailing.map((province) => (
                    <option
                      key={province.ProvinceId}
                      value={province.ProvinceId}
                    >
                      {province.ProvinceName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="MailingCity">
                <Form.Label> City/Town</Form.Label>
                <Form.Select
                  name="MailingCity"
                  value={
                    mailingAddressSame ? genInfo.City : genInfo.MailingCity
                  }
                  onChange={handleChange}
                  disabled={mailingAddressSame}
                  required
                >
                  <option className={styles["option-default"]} value="">Select a city/town</option>
                  {cityListForMailing.map((city) => (
                    <option key={city.CityName} value={city.CityName}>
                      {city.CityName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="MailingPincode">
                <Form.Label> Postal/Zip Code</Form.Label>
                <Form.Control
                  type="text"
                  name="MailingPincode"
                  placeholder="Postal/Zip Code"
                  value={
                    mailingAddressSame
                      ? genInfo.Pincode
                      : genInfo.MailingPincode
                  }
                  onChange={handleChange}
                  disabled={mailingAddressSame}
                />
              </Form.Group>
            </div>
          </div>
        </div>

        <div className={styles["heading"]}>
          <p>Emergency Contact</p>
        </div>
        <div className={styles["address"]}>
          <div className="row">
            <div className="col-md-4">
              <Form.Group className="mb-3" controlId="EmergencyName">
                <Form.Label> Name</Form.Label>
                <Form.Control
                  type="text"
                  name="EmergencyName"
                  placeholder="Name"
                  value={genInfo.EmergencyName}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>

            <div className="col-md-4">
              <Form.Group className="mb-3" controlId="EmergencyRelation">
                <Form.Label> Relation</Form.Label>
                <Form.Select
                  name="EmergencyRelation"
                  value={genInfo.EmergencyRelation}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select relation</option>
                  {relationList.map((relation) => (
                    <option key={relation.RelationId} value={relation.Relation}>
                      {relation.Relation}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-md-4">
              <Form.Group className="mb-3" controlId="EmergencyEmail">
                <Form.Label> Email</Form.Label>
                <Form.Control
                  type="text"
                  name="EmergencyEmail"
                  placeholder="Email"
                  value={genInfo.EmergencyEmail}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>

            <div className="col-md-6">
              <Form.Group
                className="mb-3"
                controlId="EmergencyCellPhoneCountryCode"
              >
                <Form.Label> Country Code</Form.Label>
                {/* <Form.Select aria-label="EmergencyCellPhoneCountryCode">
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select> */}

                <Form.Select
                  name="EmergencyCellPhoneCountryCode"
                  value={genInfo.EmergencyCellPhoneCountryCode}
                  onChange={handleChange}
                  required
                >
                  <option  className={styles["option-default"]} value="">Select Country Code</option>
                  {countryList.map((country, index) => (
                    <option key={index} value={country.CountryCode}>
                      <span
                        className={`${country.flag}`}
                        style={{ marginRight: "5px" }}
                      ></span>
                      {country.CountryCode} ({country.CountryName})
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-md-6">
              <Form.Group className="mb-3" controlId="EmergencyCellPhone">
                <Form.Label> Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  name="EmergencyCellPhone"
                  placeholder="Phone Number"
                  value={genInfo.EmergencyCellPhone}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
            <div className="col-md-12">
              <Form.Group className="mb-3" controlId="EmergencyAddress">
                <Form.Label> Address</Form.Label>
                <Form.Control
                  type="text"
                  name="EmergencyAddress"
                  placeholder="Address"
                  value={genInfo.EmergencyAddress}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ContactInfo;
