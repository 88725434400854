import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import classes from './TestScores.module.css';
import UploadDocument from '../../../../../GlobleShared/UploadDocument/UploadDocument';

// Mock API function


const englishExamRange = [
    {
      Type: -1, AMin: 0, AMax: 0, BMin: 0, BMax: 0, CMin: 0, CMax: 0, DMin: 0, DMax: 0, EMin: 0, EMax: 0, Increment: 0,
      pattern: null,
      showUsername: false,
      ALabel: '', BLabel: '', CLabel: '', DLabel: '', ELabel: '',
      ShowA: false, ShowB: false, ShowC: false, ShowD: false, ShowE: false,
      Name: 'Not Applicable'
    },
    
    {
      Type: 1, AMin: 0, AMax: 9, BMin: 0, BMax: 9, CMin: 0, CMax: 9, DMin: 0, DMax: 9, EMin: 0, EMax: 9, Increment: 0.5,
      pattern: /^[0-9]+(\.[5])?$/,
      showUsername: true,
      ALabel: 'Listening', BLabel: 'Reading', CLabel: 'Writing', DLabel: 'Speaking', ELabel: 'Overall',
      ShowA: true, ShowB: true, ShowC: true, ShowD: true, ShowE: true,
      Name: 'IELTS'
    },
    {
      Type: 2, AMin: 10, AMax: 90, BMin: 10, BMax: 90, CMin: 10, CMax: 90, DMin: 10, DMax: 90, EMin: 10, EMax: 90, Increment: 1,
      pattern: /^[0-9]+(\.[0-9][0-9]?)?$/,
      showUsername: true,
      ALabel: 'Listening', BLabel: 'Reading', CLabel: 'Writing', DLabel: 'Speaking', ELabel: 'Overall',
      ShowA: true, ShowB: true, ShowC: true, ShowD: true, ShowE: true,
      Name: 'PTE'
    },
    {
      Type: 3, AMin: 1, AMax: 30, BMin: 1, BMax: 30, CMin: 1, CMax: 30, DMin: 1, DMax: 30, EMin: 0, EMax: 120, Increment: 1,
      pattern: /^[0-9]+(\.[0-9][0-9]?)?$/,
      showUsername: true,
      ALabel: 'Listening', BLabel: 'Reading', CLabel: 'Writing', DLabel: 'Speaking', ELabel: 'Overall',
      ShowA: true, ShowB: true, ShowC: true, ShowD: true, ShowE: true,
      Name: 'TOEFL iBT'
    },
    {
      Type: 4, AMin: 310, AMax: 677, BMin: 310, BMax: 677, CMin: 310, CMax: 677, DMin: 310, DMax: 677, EMin: 310, EMax: 677, Increment: 1,
      pattern: /^[0-9]+(\.[0-9][0-9]?)?$/,
      showUsername: true,
      ALabel: 'Listening', BLabel: 'Reading', CLabel: 'Writing', DLabel: 'Speaking', ELabel: 'Overall',
      ShowA: true, ShowB: true, ShowC: true, ShowD: true, ShowE: true,
      Name: 'TOEFL PBT'
    },
    {
      Type: 5, AMin: 0, AMax: 100, BMin: 0, BMax: 100, CMin: 0, CMax: 97, DMin: 1, DMax: 4, EMin: 0, EMax: 99, Increment: .5,
      pattern: /^[0-9]+(\.[0-9][0-9]?)?$/,
      showUsername: false,
      ALabel: 'Listening', BLabel: 'GCVR', CLabel: 'Writing', DLabel: 'Speaking', ELabel: 'Final MELAB',
      ShowA: true, ShowB: true, ShowC: true, ShowD: true, ShowE: true,
      Name: 'MELAB'
    },
    {
      Type: 6, AMin: 10, AMax: 90, BMin: 10, BMax: 90, CMin: 10, CMax: 90, DMin: 10, DMax: 90, EMin: 10, EMax: 90, Increment: .5,
      pattern: /^[0-9]+(\.[0-9][0-9]?)?$/,
      showUsername: false,
      ALabel: 'Listening', BLabel: 'Reading', CLabel: 'Writing', DLabel: 'Speaking', ELabel: 'Overall',
      ShowA: true, ShowB: true, ShowC: true, ShowD: true, ShowE: true,
      Name: 'CAEL'
    },
    {
      Type: 7, AMin: 1, AMax: 300, BMin: 1, BMax: 300, CMin: 1, CMax: 300, DMin: 1, DMax: 300, EMin: 1, EMax: 300, Increment: 1,
      pattern: /^[0-9]+(\.[0-9][0-9]?)?$/,
      showUsername: true,
      ALabel: 'Listening', BLabel: 'Reading', CLabel: 'Writing', DLabel: 'Speaking', ELabel: 'Overall',
      ShowA: true, ShowB: true, ShowC: true, ShowD: true, ShowE: true,
      Name: 'TOEFL CBT'
    },
    {
      Type: 8, AMin: 0, AMax: null, BMin: 0, BMax: null, CMin: 0, CMax: null, DMin: 0, DMax: null, EMin: 10, EMax: 160, Increment: .5,
      pattern: null,
      showUsername: true,
      ALabel: '', BLabel: '', CLabel: '', DLabel: '', ELabel: 'Overall',
      ShowA: false, ShowB: false, ShowC: false, ShowD: false, ShowE: true,
      Name: 'Duolingo'
    }
  ];
  

const OtherExams = [
  {
    "OtherExamId": 0,
    "OtherExamName": "None",
  },
  {
    "OtherExamId": 1,
    "OtherExamName": "ACT",
  },
  {
    "OtherExamId": 2,
    "OtherExamName": "GMAT",
  },
  {
    "OtherExamId": 3,
    "OtherExamName": "GRE",
  },
  {
    "OtherExamId": 4,
    "OtherExamName": "SAT",
  },
];


const TestScores = ({ updateValue = false, testScoreData, getTestScore}) => {
  const [testScores, setTestScores] = useState({});
  const [selectedExamType, setSelectedExamType] = useState({});
  const [selectedOtherExam, setSelectedOtherExam] = useState({});
  const currentDate = new Date()
  const [selectedDate, setSelectedDate]=useState()
  const [selectedOtherExamDateDate, setselectedOtherExamDateDate]=useState()
  console.log("erteter",currentDate)

  const getTestScores = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(testScoreData);
      }, 1000); 
    });
  };
  
  useEffect(() => {
    fetchTestScores(testScoreData?.EnglishExamType, testScoreData?.GMATExam);
    getTestScore(testScoreData)
  //  setSelectedExamType(4) 

  }, []);
  
  const fetchTestScores = (englishExamType, otherExamTypeName) => {
    const examType = englishExamRange.filter(exam => exam.Type === englishExamType);
    const otherExamType = OtherExams.filter(exam => exam.OtherExamId === otherExamTypeName);
    setSelectedOtherExam(otherExamType[0]);
    setSelectedDate((new Date(testScoreData?.EnglishExamDate)))
    setselectedOtherExamDateDate((new Date(testScoreData?.GMATExamDate)))
    setSelectedExamType(examType[0]);
    setTestScores(testScoreData);
  };

  const handleExamTypeChange = (event) => {
    const examType = englishExamRange.find(exam => exam.Type === parseInt(event.target.value));
    testScores.EnglishExamDate=''
    testScores.EnglishScoreOverall='';
    testScores.EnglishScoreL=''
    testScores.EnglishScoreR=''
    testScores.EnglishScoreW=''
    testScores.EnglishScoreS=''
    testScores.EnglishExamRemark=''
    testScores.EnglishExamUser=''
    testScores.EnglishExamPswd=''
    testScores.EnglishExamDocPath=''

    testScores.EnglishExamType=event.target.value
    setSelectedDate()
    setSelectedExamType(examType);
    const { name, value } = event.target;
    getTestScore({...testScores, [name]: value})
    
  };

  const handleOtherExamTypeChange = (event) => {
    testScores.GMATExamDate=''
    testScores.ACTScoreEnglish=''
    testScores.ACTScoreMath=''
    testScores.ACTScoreScience=''
    testScores.ACTScoreReading=''
    testScores.ACTScoreWriting=''
    testScores.ACTScoreTotal=''
    testScores.GREScoreV=''
    testScores.GREScoreQ=''
    testScores.GREScoreW=''
    testScores.SATScoreTotal=''
    testScores.GREScoreV =''
    testScores.GREScoreQ=''
    testScores.GREScoreW=''
    testScores.SATScoreMath=''
    testScores.SATScoreEBRW=''
    testScores.SATScoreTotal=''
    testScores.OtherExamDocPath=''
    testScores.GMATExam=event.target.value
    const otherExamType = OtherExams.find(exam => exam.OtherExamId === parseInt(event.target.value));
    setSelectedOtherExam(otherExamType);
    const { name, value } = event.target;
    getTestScore({...testScores, [name]: value})
    setselectedOtherExamDateDate()
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTestScores(prevState => ({
      ...prevState,
      [name]: value
    }));
    getTestScore({...testScores, [name]: value})
    if(event.target.name==="EnglishExamDate"){
      setSelectedDate((new Date(value)))
    }
    if(event.target.name==="GMATExamDate"){
      setselectedOtherExamDateDate((new Date(value)))
    }

  };

  const englishExamFile=(file)=>{
    console.log("file",file)
    testScores.EnglishExamDocPath=file
    getTestScore({...testScores, ...testScores.EnglishExamDocPath})

  }

  const otherExamFile=(file)=>{
    testScores.OtherExamDocPath=file
    getTestScore({...testScores, ...testScores.OtherExamDocPath})

  }

  return (
    <>
      <div className={classes['mainContainer']}>
        <div className={classes.headingText}><span>English {!updateValue && `Proficiency Test`}</span></div>
        <div className={classes['container']}>
          <div className="row">
            <div className="col-md-6">
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Select Exam Type</Form.Label>
                <Form.Select aria-label="Select Exam Type" name="EnglishExamType" value={testScores?.EnglishExamType} onChange={handleExamTypeChange}>
                <option  value=''>Select an exam</option>
                  {englishExamRange.map((exam, index) => (
                    <option key={index} value={exam.Type}>{exam.Name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            {selectedExamType?.Type === -1 && (
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInputRemark">
                  <Form.Label>Remark</Form.Label>
                  <input
                    className={`form-control`}
                    type='text'
                    placeholder='Remark'
                    aria-label="Remark"
                    name="EnglishExamRemark"
                    value={testScores.EnglishExamRemark}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </div>
            )}
            {selectedExamType?.Type !==-1 && (
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                  <Form.Label>Exam Date</Form.Label>
                  <input
                    className={`form-control`}
                    type='date'
                    aria-label="Exam Date"
                    name="EnglishExamDate"
                    value={testScores?.EnglishExamDate? testScores?.EnglishExamDate.split('T')[0] : ''}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </div>
            )}
            {selectedExamType?.Type >0 && selectedDate > currentDate && (
              <div className="col-md-12 mb-4">
                <p>Upload confirmation document</p>
                 <UploadDocument filePathUrl={testScoreData.EnglishExamDocPath} btnText="Upload File" uploadFile={englishExamFile}></UploadDocument>
              </div>
            )}
            {(selectedExamType?.ShowA &&  (selectedDate===undefined || selectedDate <= currentDate)) && (
              <div className="col-md-3">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                  <Form.Label>{selectedExamType.ALabel}</Form.Label>
                  <input
                    className={`form-control`}
                    type='number'
                    aria-label={selectedExamType.ALabel}
                    name="EnglishScoreL"
                    value={testScores.EnglishScoreL}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </div>
            )}
            {(selectedExamType?.ShowB &&  (selectedDate===undefined || selectedDate <= currentDate)) &&(
              <div className="col-md-3">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                  <Form.Label>{selectedExamType.BLabel}</Form.Label>
                  <input
                    className={`form-control`}
                    type='number'
                    aria-label={selectedExamType.BLabel}
                    name="EnglishScoreR"
                    value={testScores.EnglishScoreR}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </div>
            )}
            {(selectedExamType?.ShowC &&  (selectedDate===undefined || selectedDate <= currentDate)) &&(
              <div className="col-md-3">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                  <Form.Label>{selectedExamType.CLabel}</Form.Label>
                  <input
                    className={`form-control`}
                    type='number'
                    aria-label={selectedExamType.CLabel}
                    name="EnglishScoreW"
                    value={testScores.EnglishScoreW }
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </div>
            )}
            {(selectedExamType?.ShowD  &&  (selectedDate===undefined || selectedDate <= currentDate)) &&(
              <div className="col-md-3">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                  <Form.Label>{selectedExamType.DLabel}</Form.Label>
                  <input
                    className={`form-control`}
                    type='number'
                    aria-label={selectedExamType.DLabel}
                    name="EnglishScoreS"
                    value={testScores.EnglishScoreS}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </div>
            )}
            {(selectedExamType?.ShowE  &&  (selectedDate===undefined || selectedDate <= currentDate)) &&(
              <div className="col-md-3">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                  <Form.Label>{selectedExamType.ELabel}</Form.Label>
                  <input
                    className={`form-control`}
                    type='number'
                    aria-label={selectedExamType.ELabel}
                    name="EnglishScoreOverall"
                    value={testScores.EnglishScoreOverall}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </div>
            )}
          </div>
          {!updateValue && selectedExamType?.showUsername && (
            <div className={`row`}>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                  <Form.Label>Username</Form.Label>
                  <input
                    className={`form-control`}
                    type='text'
                    aria-label="Username"
                    name="EnglishExamUser"
                    value={testScores.EnglishExamUser}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                  <Form.Label>Password</Form.Label>
                  <input
                    className={`form-control`}
                    type='password'
                    aria-label="Password"
                    name="EnglishExamPswd"
                    value={testScores.EnglishExamPswd}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </div>
            </div>
          )}
        </div>
        <div className={`${classes.headingText}`}>
          <span>{updateValue ? 'GMAT' : 'Other Exams '}</span>
        </div>
        <div className={classes['container']}>
          <div className="row">
            <div className="col-md-6">
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Select an Exam</Form.Label>
                <Form.Select aria-label="Select an Exam" name="GMATExam" value={testScores?.GMATExam} onChange={handleOtherExamTypeChange}>
                  {OtherExams.map((exam, index) => (
                    <option key={index} value={exam.OtherExamId}>{exam.OtherExamName}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            {selectedOtherExam.OtherExamId >0 && (
              <>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Exam Date</Form.Label>
                    <input
                      className={`form-control`}
                      type='date'
                      aria-label="Exam Date"
                      name="GMATExamDate"
                      value={testScores?.GMATExamDate ? testScores?.GMATExamDate.split('T')[0] : ''}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </div>
                {(selectedOtherExam.OtherExamId > 0 && selectedOtherExamDateDate >= currentDate) && (
                    <div className="col-md-12 mb-4">
                       <p>Upload confirmation document</p>
                      <UploadDocument filePathUrl={testScoreData.OtherExamDocPath} btnText="Upload File" uploadFile={otherExamFile}></UploadDocument>
                 </div>

                )}
 
                {(selectedOtherExam?.OtherExamId === 1 &&  (selectedOtherExamDateDate===undefined || selectedOtherExamDateDate <= currentDate)) && (
                  <>
                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>English</Form.Label>
                        <input
                          className={`form-control`}
                          type='number'
                          aria-label="English"
                          name="ACTScoreEnglish"
                          value={testScores.ACTScoreEnglish}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>Math</Form.Label>
                        <input
                          className={`form-control`}
                          type='number'
                          aria-label="Math"
                          name="ACTScoreMath"
                          value={testScores.ACTScoreMath}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>Science</Form.Label>
                        <input
                          className={`form-control`}
                          type='number'
                          aria-label="Science"
                          name="ACTScoreScience"
                          value={testScores.ACTScoreScience}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>Reading</Form.Label>
                        <input
                          className={`form-control`}
                          type='number'
                          aria-label="Reading"
                          name="ACTScoreReading"
                          value={testScores.ACTScoreReading}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>Writing</Form.Label>
                        <input
                          className={`form-control`}
                          type='number'
                          aria-label="Writing"
                          name="ACTScoreWriting"
                          value={testScores.ACTScoreWriting}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>Total Score</Form.Label>
                        <input
                          className={`form-control`}
                          type='number'
                          aria-label="Total Score"
                          name="ACTScoreTotal"
                          value={testScores.ACTScoreTotal}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                  </>
                )}
                {(selectedOtherExam.OtherExamId === 2 && (selectedOtherExamDateDate===undefined || selectedOtherExamDateDate <= currentDate)) && (
                  <>
                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>Verbal</Form.Label>
                        <input
                          className={`form-control`}
                          type='number'
                          aria-label="Verbal"
                          name="GREScoreV"
                          value={testScores.GREScoreV}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>Quantitative</Form.Label>
                        <input
                          className={`form-control`}
                          type='number'
                          aria-label="Quantitative"
                          name="GREScoreQ"
                          value={testScores.GREScoreQ}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>Analytic</Form.Label>
                        <input
                          className={`form-control`}
                          type='number'
                          aria-label="Analytic"
                          name="GREScoreW"
                          value={testScores.GREScoreW}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>Total Score</Form.Label>
                        <input
                          className={`form-control`}
                          type='number'
                          aria-label="Total Score"
                          name="SATScoreTotal"
                          value={testScores.SATScoreTotal}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>Username</Form.Label>
                        <input
                          className={`form-control`}
                          type='text'
                          aria-label="Username"
                          name="OtherExamUser"
                          value={testScores.OtherExamUser}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>Password</Form.Label>
                        <input
                          className={`form-control`}
                          type='password'
                          aria-label="Password"
                          name="OtherExamPswd"
                          value={testScores.OtherExamPswd}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                  </>
                )}
                {(selectedOtherExam.OtherExamId === 3 &&  (selectedOtherExamDateDate===undefined || selectedOtherExamDateDate <= currentDate)) &&(
                  <>
                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>Verbal</Form.Label>
                        <input
                          className={`form-control`}
                          type='number'
                          aria-label="Verbal"
                          name="GREScoreV"
                          value={testScores.GREScoreV }
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>Quantitative</Form.Label>
                        <input
                          className={`form-control`}
                          type='number'
                          aria-label="Quantitative"
                          name="GREScoreQ"
                          value={testScores.GREScoreQ}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>Analytic</Form.Label>
                        <input
                          className={`form-control`}
                          type='number'
                          aria-label="Analytic"
                          name="GREScoreW"
                          value={testScores.GREScoreW}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>Username</Form.Label>
                        <input
                          className={`form-control`}
                          type='text'
                          aria-label="Username"
                          name="OtherExamUser"
                          value={testScores.OtherExamUser}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>Password</Form.Label>
                        <input
                          className={`form-control`}
                          type='password'
                          aria-label="Password"
                          name="OtherExamPswd"
                          value={testScores.OtherExamPswd}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                  </>
                )}
                {(selectedOtherExam.OtherExamId === 4 &&  (selectedOtherExamDateDate===undefined || selectedOtherExamDateDate <= currentDate)) &&(
                  <>
                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>Math</Form.Label>
                        <input
                          className={`form-control`}
                          type='number'
                          aria-label="Math"
                          name="SATScoreMath"
                          value={testScores.SATScoreMath}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>Evidence Based Reading and Writing</Form.Label>
                        <input
                          className={`form-control`}
                          type='number'
                          aria-label="Evidence Based Reading and Writing"
                          name="SATScoreEBRW"
                          value={testScores.SATScoreEBRW}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>Total Score</Form.Label>
                        <input
                          className={`form-control`}
                          type='number'
                          aria-label="Total Score"
                          name="SATScoreTotal"
                          value={testScores.SATScoreTotal}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
  };

  export default TestScores;