import React, { useState } from "react";
import classes from "./Common.module.css";
import userPic from "../../.../../../../../../Assets/Images/profile_image_connections.png";
import userProfilePic from "../../.../../../../../../Assets/Images/comment_user_pic.png";
import plusIcon from "../../../../../../Assets/Logo/add-icon.svg";

const ProfileCard = ({
  ConnectButtonShow,
  buttonShow,
  handleAction,
  withdrawHandler,
  connectionsProfileDetails
}) => {

  const viewProfileSection = () => {
    window.open('/member/profileView', '_blank');
}

  return (
    <div className={classes.card}>
      <div className={classes.coverImage}>
        <span className={classes.backImage}>
          <img src={connectionsProfileDetails.profileImage ? connectionsProfileDetails.profileImage : userPic} alt="" />
        </span>
        <span className={classes.profileImage}>
          <img src={connectionsProfileDetails.profileBGImage ? connectionsProfileDetails.profileBGImage : userProfilePic} alt={`${connectionsProfileDetails.displayName}'s profile`} />
        </span>
      </div>
      <div className={classes.content}>
        <div className={classes.content_info}>
          <div className={classes.name} onClick={viewProfileSection}>{connectionsProfileDetails.displayName ? connectionsProfileDetails.displayName : 'Not available'}</div>
          <div className={classes.description}>{connectionsProfileDetails.aboutUs ? connectionsProfileDetails.aboutUs : 'Not available'}</div>
        </div>
        <div className={classes.content_info}>
          <div className={classes.connectionCount}>
            {connectionsProfileDetails.mutualConnections.length} connections
          </div>
          <div className={classes.connections}>
            { connectionsProfileDetails.mutualConnections && connectionsProfileDetails.mutualConnections.map((mutualConnection, index) => (
              <img
                key={index}
                src={mutualConnection.imgSrc}
                alt={mutualConnection.name}
                className={classes.connectionImage}
              />
            ))}
          </div>
        </div>
      </div>
      <div>
        {buttonShow ? (
          <div className={classes.buttons}>
            {ConnectButtonShow ? <img className={classes.plus} src={plusIcon} alt="Connect" onClick={() => handleAction(connectionsProfileDetails.senderUserId, "Connect")} />:<button className="primary-button"  onClick={() => handleAction(connectionsProfileDetails.userConnectionId, "Accept")}>Accept</button>}
            {!(ConnectButtonShow) && <span className={classes.dismissButton} onClick={() => handleAction(connectionsProfileDetails.userConnectionId, "Dismiss")}>Dismiss</span>}
          </div>
        ) : (
          <div className={classes.buttons}>
          <span className={classes.withdrawButton} onClick={() => withdrawHandler(connectionsProfileDetails.userConnectionId, 7)}>Withdraw</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileCard;
