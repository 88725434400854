import React, { useState } from "react";
import classes from "./Connections.module.css";
import SubConnections from "./SubComponents/SubConnections";
import Suggestions from "./SubComponents/Suggestions";
import Invitations from "./SubComponents/Invitations";
import SentComponents from "./SubComponents/SentComponents";
function Connections({connectedUserId}) {
  const [activeSection, setActiveSection] = useState("connections");

  return (
    <>
    {connectedUserId && (
      <div className={`${classes["connection-container"]}`}>
      <div className={`${classes["connection-section"]}`}>
          <SubConnections studentId={connectedUserId} />
        </div>
      </div>
    )}
    {!connectedUserId && (
      <div className={`${classes["connection-container"]}`}>
      <div className={`${classes["connection-section"]}`}>
          <div className={`${classes["connection-left-section"]}`}>
            <div className={classes["connection-left-content"]}>
              <div
                className={`${classes["alphaComp"]} ${activeSection === "connections" ? classes["activeMenuBar"] : ""}`}
                onClick={() => setActiveSection("connections")}>
                Connections
              </div>
              <div
                className={`${classes["alphaComp"]}  ${activeSection === "Suggestions" ? classes["activeMenuBar"] : ""}`}
                onClick={() => setActiveSection("Suggestions")}>
                Suggestions
              </div>
              <div
                className={`${classes["alphaComp"]}  ${activeSection === "invitations" ? classes["activeMenuBar"] : ""}`}
                onClick={() => setActiveSection("invitations")}>
                Invitations
              </div>
              <div
                className={`${classes["alphaComp"]}  ${activeSection === "sent" ? classes["activeMenuBar"] : ""}`}
                onClick={() => setActiveSection("sent")}>
                Sent
              </div>
            </div>
          </div>
          <div className={`${classes["connection-right-section"]} `}>
            {activeSection === "connections" && <SubConnections />}
            {activeSection === "Suggestions" && <Suggestions />}
            {activeSection === "invitations" && <Invitations />}
            {activeSection === "sent" && <SentComponents />}
          </div>
        </div>
      </div>
    )}
    </>
  );
}
export default Connections;