import React  from 'react'
import classes from "./InstitutionDocumentsSection.module.css";
import { Table } from 'react-bootstrap';
import documentView from "../../../../../../Assets/Logo/documetnView.svg";
const InstitutionDocumentsSection = () => {
    const tableData = {
      columns: ["Attachment", "Date uploaded", "Description", "Expiry date", "Upload by", "Ins. sub. stamp","Intake"],
        documents: [
          // {
          //   "id": 1,
          //   "Attachment": "Third Party Letter of Authorization",
          //   "Date uploaded": "2024-09-10",
          //   "Description": "Authorization letter for third-party representation",
          //   "Expiry date": "2025-09-10",
          //   "Upload by": "John Doe",
          //   "Ins. sub. stamp": "Stamped",
          //   "Intake": "Fall 2024"
          // },
          // {
          //   "id": 2,
          //   "Attachment": "English Proficiency score card",
          //   "Date uploaded": "2024-08-05",
          //   "Description": "IELTS score card",
          //   "Expiry date": "2026-08-05",
          //   "Upload by": "Jane Smith",
          //   "Ins. sub. stamp": "Not Stamped",
          //   "Intake": "Fall 2024"
          // },
          // {
          //   "id": 3,
          //   "Attachment": "Higher Secondary School Transcript",
          //   "Date uploaded": "2024-07-15",
          //   "Description": "High school grades",
          //   "Expiry date": "2025-07-15",
          //   "Upload by": "Alice Johnson",
          //   "Ins. sub. stamp": "Stamped",
          //   "Intake": "Spring 2024"
          // },
          // {
          //   "id": 4,
          //   "Attachment": "Passport Copy (Front & Last Page)",
          //   "Date uploaded": "2024-06-20",
          //   "Description": "Copy of passport",
          //   "Expiry date": "2034-06-20",
          //   "Upload by": "Bob Lee",
          //   "Ins. sub. stamp": "Stamped",
          //   "Intake": "Fall 2024"
          // },
          // {
          //   "id": 5,
          //   "Attachment": "Secondary School Transcript",
          //   "Date uploaded": "2024-05-10",
          //   "Description": "Secondary school grades",
          //   "Expiry date": "2025-05-10",
          //   "Upload by": "Catherine Green",
          //   "Ins. sub. stamp": "Not Stamped",
          //   "Intake": "Spring 2025"
          // }
        ]
      };
      const handleViewRequest = (id) => {
        console.log('id: ', id);
  };

  return (
    <div className={classes.container}>
      <Table responsive className={classes.customTable}>    
        <thead>
          <tr>
            {tableData.columns.map((column, index) => (
              <th key={index}>
                <div className={classes.thAndIconContainer}>
                  <span className={classes.columnText}>{column}</span>
                </div>
              </th>
            ))}
            <th>View File</th>
          </tr>
        </thead>
        <tbody>
        {tableData.documents.length > 0 ? (
            tableData.documents.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {tableData.columns.map((column, colIndex) => (
                  <td key={colIndex}>{row[column]}</td>
                ))}
                <td>
                  <span 
                    className={classes.actionBtn}
                    onClick={() => handleViewRequest(row["id"])}>
                    <img src={documentView} className={classes.actionICon} alt="" />
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className={classes.noDataText} colSpan={tableData.columns.length + 1}>No data</td>
            </tr>
          )}
        </tbody>
      </Table> 
   </div>
  )
}

export default InstitutionDocumentsSection