import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import styles from "./EducationSection.module.css";
import eduEdit from "../../../../../../Assets/Logo/eduEdit.svg"
import eduDelete from "../../../../../../Assets/Logo/eduDelete.svg"
import ConfirmationModal from "../../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import AddEducationModal from "./AddEducationModal/AddEducationModal";

const EducationSection = ({
  educationLevelData,
  countryList,
  schoolList,
  grdSchemeData,
  onEducationCountryChange,
  deleteSchoolDetail,
  studentId, 
  educationInfo,
  getEducationInfo,
  getSchoolDetailList
}) => {


  
  const [gradeSchemeDDL, setGradeSchemeDDL] = useState([]);
  const [addMoreEducationPopup, setAddMoreEducationPopup] = useState(false);
  const [dropDownGradingSchemeSelected, setDropDownGradingSchemeSelected] = useState(false);
  const GradeDropdownValuesArray = [1,2,14,19,21,22,25];
  const [confirmationRepostModal, setConfirmationRepostModal]=useState(false);
  const [schoolId, setSchoolId]=useState(0);

  //Flag for update
  const [updateEducationData, setUpdateEducationData]=useState(false);


   //************************* */ for eduInfo section logic and education sections only
   const [eduInfo, setEduInfo] = useState({
    CountryOfEducation: "",
    HighestLevelOfEducation: "",
    GradingScheme: "",
    GradeAverage: "",
  });

  useEffect(()=>{
  setEduInfo(educationInfo);
  getEducationInfo(educationInfo)
  onEducationCountryChange(educationInfo?.CountryOfEducation);
  }, [])

  const handleInfoChange = (e) => {
    const { name, value } = e.target;
    if (name === 'GradingScheme') {
      onGradeChange(value);
    }
    setEduInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
    getEducationInfo({...eduInfo, [name]:value})
  };


  const onGradeChange = (gradingSchemeID) => {
    eduInfo.GradeAverage = "";
    // Reset GradeAverage correctly
    if (GradeDropdownValuesArray.includes(Number(gradingSchemeID))) {
      setDropDownGradingSchemeSelected(true);
    }
    else{
      setDropDownGradingSchemeSelected(false);
      return;
    }
    const selectedGrade = grdSchemeData.find((d) => d.GrdSchemeId === Number(gradingSchemeID)) || null;

    if (selectedGrade) {
      setGradingScheme(selectedGrade);
    }
  };

  const setGradingScheme = (selectedGrade) => {
    if (selectedGrade) {
      setDropDownGradingSchemeSelected(true);
        const hints = selectedGrade['InputHint'].replaceAll(" ","").split(",");
      setGradeSchemeDDL(hints);
    } else {
      setDropDownGradingSchemeSelected(false);
    }
  };


  const handleEducationCountryChange = (e) => {
    eduInfo.GradingScheme = "";
    eduInfo.GradeAverage = "";
    onEducationCountryChange(e.target.value);
  };




  //********************** */
  const addMoreEducation = () => {
    setAddMoreEducationPopup(true);
  };


 const showConfirmModalhandler = (id) => {
  setSchoolId(id);
  setConfirmationRepostModal(true);
};
const hideConfirmModalhandler = () => {
  setConfirmationRepostModal(false);
};


//  ******************** delete school data
const deleteSchoolData = ()=>{
  deleteSchoolDetail(schoolId)
  hideConfirmModalhandler();
}


// send school data
const [selectedSchoolData, setSelectedSchoolData] = useState({});
const handleEditClick = (school) => {
  setSelectedSchoolData(school); 
  setAddMoreEducationPopup(true);
  setUpdateEducationData(true); 
};

const handleClose = () => {
  setAddMoreEducationPopup(false);
  // setRows([]);
  // setEducationDetails("")
  setSelectedSchoolData({});
  setUpdateEducationData(false); 
  // setCountryInstitution("");
  // setEducationLevel("");
};


const refreshSchoolList=()=>{
  getSchoolDetailList();
}

  return (
    <div className={styles["data-section"]}>
      {/*main education section only have only four fields */}
      <Form>
        <div className={styles["address"]}>
          <div className="row">
            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="countryOfEducation">
                <Form.Label>Country of Education</Form.Label>
                <Form.Select
                  name="CountryOfEducation"
                  aria-label="Country of Education"
                  onChange={(e) => {
                    handleEducationCountryChange(e);
                    handleInfoChange(e);
                  }}
                  value={eduInfo?.CountryOfEducation}
                  required
                >
                  <option className={styles["option-default"]}>
                    Select a country
                  </option>
                  {countryList.map((country) => (
                    <option key={country.CountryId} value={country.CountryId}>
                      {country.CountryName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="highestLevelOfEducation">
                <Form.Label>Highest Level of Education</Form.Label>
                <Form.Select
                  name="HighestLevelOfEducation"
                  aria-label="Highest Level of Education"
                  onChange={handleInfoChange}
                  value={eduInfo?.HighestLevelOfEducation}
                  required
                >
                  <option className={styles["option-default"]} value="">
                    Select level of education
                  </option>
                  {educationLevelData.map((level) => (
                    <option key={level.EduLevelId} value={level.EduLevelId}>
                      {level.EduLevelName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-md-3">
              <Form.Group className="mb-3" controlId="gradingScheme">
                <Form.Label>Grading Scheme</Form.Label>
                <Form.Select
                  name="GradingScheme"
                  aria-label="Grading Scheme"
                  onChange={handleInfoChange}
                  value={eduInfo?.GradingScheme}
                  required
                >
                  <option className={styles["option-default"]} value="">
                    Select grading scheme
                  </option>
                  {grdSchemeData.map((scheme) => (
                    <option key={scheme.GrdSchemeId} value={scheme.GrdSchemeId}>
                      {scheme.GrdSchemeName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-md-3">
              {dropDownGradingSchemeSelected ? (
                <Form.Group controlId="gradeAverage">
                  <Form.Label>Grade Average</Form.Label>
                  <Form.Select
                    name="GradeAverage"
                    onChange={handleInfoChange}
                    value={eduInfo?.GradeAverage}
                    required
                  >
                    <option className={styles["option-default"]} value="">
                      Select grade average
                    </option>
                    {gradeSchemeDDL.map((grade, index) => (
                      <option key={index} value={grade}>
                        {grade}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              ) : (
                <Form.Group controlId="gradeAverageInput">
                  <Form.Label>Grade Average</Form.Label>
                  <Form.Control
                    type="text"
                    name="GradeAverage"
                    value={eduInfo?.GradeAverage}
                    placeholder="Grade Average"
                    onChange={(e) => handleInfoChange(e)}
                    required
                  />
                </Form.Group>
              )}
            </div>
          </div>
        </div>
      </Form>

      {/* ***************************************************************** */}

      {/* school list section to show school list */}
      <div className={`${styles.formBackgroundEducationInfoMore} mt-4`}>
        <div className={`${styles.rowHeight} row mb-4`}>
          {schoolList?.length > 0 &&
            schoolList.map((school) => (
              <div className="col-lg-4 col-md-6 mb-4" key={school.SchoolId}>
                <div className={styles.educationCard}>
                  <div className={styles.educationCardHeader}>
                    <div
                      className={`${styles.textOverflow} ${styles.educationCardTitle}`}
                      title={school.NameOfInstitution}
                    >
                      {school.NameOfInstitution}
                    </div>
                    <div className={styles.actions}>
                      <button
                        className={styles.iconHeightUpdate}
                        title="Edit"
                        onClick={() => handleEditClick(school)}
                      >
                        <img src={eduEdit} alt="Edit" />
                      </button>
                      <button
                        className={styles.iconHeightDelete}
                        title="Delete"
                        onClick={() => showConfirmModalhandler(school.SchoolId)}
                      >
                        <img src={eduDelete} alt="Delete" />
                      </button>
                    </div>
                  </div>
                  <div className={styles.mainWrapper}>
                    <div className={styles.contentArea}>
                      <div className={styles.valueWrapper}>
                        <div className={styles.headingLabel}>Level : </div>
                        <div className={styles.headingValue}>
                          <span title={school.LevelOfEducationName}>
                            {school.LevelOfEducationName}
                          </span>
                        </div>
                      </div>
                      <div className={styles.valueWrapper}>
                        <div className={styles.headingLabel}>
                          Language of Instruction :{" "}
                        </div>
                        <div className={styles.headingValue}>
                          {school.Language}
                        </div>
                      </div>
                      <div className={styles.valueWrapper}>
                        <div className={styles.headingLabel}>
                          Board of Education :{" "}
                        </div>
                        <div
                          className={`${styles.headingValue} ${styles.boardOfEducation}`}
                          title={school.Address}
                        >
                          {school.Address}
                        </div>
                      </div>
                      {/* {school.StreamName && ( */}
                      <div className={styles.valueWrapper}>
                        <div className={styles.headingLabel}>Stream : </div>
                        <div className={styles.headingValue}>
                          <span title={school.StreamName}>
                            {school.StreamName}
                          </span>
                        </div>
                      </div>
                      {/* )} */}
                    </div>
                    <div className={styles.tableArea}>
                      <div className={`${styles.tableWrapper} edu_table`}>
                        <table
                          style={{ width: "100%" }}
                          className={styles.educationTable}
                        >
                          <thead>
                            <tr>
                              <th className={styles.tableHeading}>
                                Major Subject
                              </th>
                              <th className={styles.tableHeading}>Score</th>
                            </tr>
                          </thead>
                          <tbody>
                            {school.Marks.map((item, index) => (
                              <tr key={index}>
                                <td className={styles.tableData}>
                                  {item.SubjectName}
                                </td>
                                <td className={styles.tableData}>
                                  {item.ObtainMarks}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* ************************************************* */}

      <div className="text-end mt-2">
        <button className={styles["add-more-btn"]} onClick={addMoreEducation}>
          + Add More Education
        </button>
      </div>

      {/* popup for education modal */}



   {addMoreEducationPopup && (
      <AddEducationModal addMoreEducationPopup={addMoreEducationPopup} updateFlag={updateEducationData} handleClose={handleClose} selectedSchoolData={selectedSchoolData} refreshSchoolList={refreshSchoolList}/>
      
       )}


      {confirmationRepostModal && (
          <ConfirmationModal onShow={showConfirmModalhandler} getConfirmation={deleteSchoolData} 
          onClose={hideConfirmModalhandler} btnText='Yes'  confirmationText='Are you sure you want to delete 
          school ?'></ConfirmationModal>
        )}
    </div>
    
  );
};

export default EducationSection;
