import React, { useEffect, useState } from "react";
import classes from "./Documents.module.css";
import editIcon from "../../../../../Assets/Logo/edit-icon.svg";
import deleteICon from "../../../../../Assets/Logo/delete-icon.svg";
import addIcon from "../../../../../Assets/Logo/add-icon.svg";
import coinIcon from "../../../../../Assets/Logo/coin-icon.svg";
import locationIcon from "../../../../../Assets/Logo/location-icon.svg";
import calenderIcon from "../../../../../Assets/Logo/calender-icon.svg";
import passportIcon from "../../../../../Assets/Logo/passport-icon.svg";
import countryIcon from "../../../../../Assets/Logo/country-icon.svg";
import graduationCap from '../../../../../Assets/Logo/graduation-cap-icon.svg';
import programIcon from '../../../../../Assets/Logo/program-icon.svg';
import starIcon from '../../../../../Assets/Logo/star-icon.svg';
import governmentIdIcon from "../../../../../Assets/Logo/goverment-id-icon.svg";
import lineIcon from "../../../../../Assets/Logo/line-hr.svg";
import visaIcon from "../../../../../Assets/Logo/Visa-icon.svg";
import eyeIcon from "../../../../../Assets/Logo/eye-icon.svg";
import documentIcon from "../../../../../Assets/Logo/document-icon.svg";
import upperArrowICon from "../../../../../Assets/Logo/uper-arrow-icon.svg";
import dropArrowICon from "../../../../../Assets/Logo/drop-down_icon.svg";
import UploadDocument from "../../../../../Components/GlobleShared/UploadDocument/UploadDocument";
import ProfileService from "../../../../../Services/ProfileService";
import useLocalStorage from "../../../../GlobleShared/CustomHooks/useLocalStorage";
import Loader from "../../../../GlobleShared/Loader/Loader";
import SearchModal from "../../Search/SearchModal/SearchModal";
import crossIcon from '../../../../../Assets/Logo/cross-icon.svg';
import { toast } from "react-toastify";
import ConfirmationModal from "../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import ApplicationService from "../../../../../Services/ApplicationServices";

function Documents() {
  const blobUrl = process.env.REACT_APP_URL_PATH
  const [openDropdown, setOpenDropdown] = useState(null);
  const [userDetail] = useLocalStorage("userDetail");
  const studentID = userDetail.refId;
  const userId = userDetail.user_id;
  const [isLoading, setIsLoading] = useState();
  const [passportModel, setPassportModel] = useState();
  const [passportData, setPassportData] = useState({});
  const [errors, setErrors] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [visaTypeList, setVisaTypeList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [visaData, setVisaData] = useState([]);
  const [visaIdValue, setVisaIdValue] = useState([]);
  const [confirmationModalIsVisible, setConfirmationModalIsVisible] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');
  const [btnTextName, setBtnTextName] = useState('');
  const [docPathName, setDocPathName] = useState('');
  const [educationData, setEducationData] = useState([]);
  const [schooleditModel, setSchoolEditModel] = useState(false);
  const [educationDetails, setEducationDetails] = useState(null);
  const [form, setForm] = useState({
    PassportNo: "",
    PassportExpiryDate: "",
    Citizenship: "",
    Citizenship: passportData.Citizenship || "",
    DocPath: passportData.DocPath || "",
  });
  const [isEditMode, setIsEditMode] = useState(false); // New state to track mode
  const [visaModel, setVisaModel] = useState(false);
  const [formVisa, setFormVisa] = useState({
    VisaNo: '',
    CountryId: '',
    VisaTypeId: '',
    VisaStatus: '',
    IssueDate: '',
    ValidUpto: '',
    Comment: '',
    DocumentPath: "",

  });
  const getSchoolDetailList = () => {
    let data = {
      ParentType: 6,
      ParentId: studentID,
    };
    ApplicationService.getSchoolDetailList(data)
      .then((res) => {
        setEducationData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  }
  const validateForm = () => {
    let formErrors = {};

    if (!form.PassportNo) {
      formErrors.PassportNo = "Passport number is required";
    }
    if (!form.PassportExpiryDate) {
      formErrors.PassportExpiryDate = "Expiry date is required";
    }
    if (!form.Citizenship) {
      formErrors.Citizenship = "Citizenship is required";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  const handleSubmit = () => {
    debugger;
    if (form.DocPath !== docPathName) {
      getStudentDocument()
    }
    setIsLoading(true);
    const payload = {
      ...form,
      StudentId: studentID
    }
    ProfileService.updatePassportDetail(payload).then((res) => {
      toast.success(res.message);
      getPassportDetail(studentID);
      setPassportModel(false)
      setIsLoading(false);
    }).catch((err) => {
      console.log('err: ', err);
      setIsLoading(false);
    });
  };
  const getPassportDetail = (id) => {
    setIsLoading(true);
    ProfileService.getPassportDetail(id).then((res) => {
      setPassportData(res);
      setDocPathName(res?.DocPath)
      setIsLoading(false);
    }).catch((error) => {
      console.log('error: ', error);
      setIsLoading(false);
    });
  }
  const countryData = () => {
    setIsLoading(true);
    ProfileService.countryList().then((res) => {
      setCountryList(res);
      setIsLoading(false);
    }).catch((error) => {
      console.log('error: ', error);
      setIsLoading(false);
    });
  }

  const handleVisaChange = (e) => {
    const { name, value } = e.target;
    setFormVisa({
      ...formVisa,
      [name]: value,
    });


    if (name === 'CountryId') {
      const selectedCountryId = value;  
      fetchVisaTypes(selectedCountryId); 
    }
    if (name === 'VisaTypeId') {
      fetchStatusTypes()
    }
  };
  const fetchVisaTypes = (countryId) => {
    setIsLoading(true);
    ProfileService.VisaType(countryId).then((res) => {
      setVisaTypeList(res);
      setIsLoading(false);
    }).catch((error) => {
      console.log('error: ', error);
      setIsLoading(false);
    });
  }
  const fetchStatusTypes = () => {
    setIsLoading(true);
    ProfileService.StatusType().then((res) => {
      setStatusList(res);
      setIsLoading(false);
    }).catch((error) => {
      console.log('error: ', error);
      setIsLoading(false);
    });
  }
  const getStudentVisa = (id) => {
    setIsLoading(true);
    ProfileService.getStudentVisa(id).then((res) => {
      setVisaData(res);
      setIsLoading(false);
    }).catch((err) => {
      console.log('err: ', err);
      setIsLoading(false);
    });
  }

  const getStudentDocument = () => {
    setIsLoading(true);
    const payload = {
      "DocPath": docPathName,
      "DocumentId": passportData.DocumentId ? passportData.DocumentId : 0,
      "DocumentName": "",
      "DocumentTypeId": 1,
      "DueDate": "",
      "ExpiryDate": "",
      "ParentId": studentID,
      "ParentType": 6
    }
    ProfileService.addStudentDocument(payload).then((res) => {
      setIsLoading(false);
    }).catch((err) => {
      console.log('err: ', err);
      setIsLoading(false);
    });
  }
  useEffect(() => {
    countryData();
    getStudentVisa(studentID);
    getPassportDetail(studentID);
    getSchoolDetailList();
  }, [studentID]);


  useEffect(() => {
    if (passportData) {
      setForm({
        PassportNo: passportData.PassportNo || "",
        PassportExpiryDate: passportData.PassportExpiryDate || "",
        Citizenship: passportData.Citizenship || "",
        DocPath:passportData.DocPath ?  passportData.DocPath : ""
      });
    }
  }, [passportData]);
  const toggleDropdown = (id) => {
    setOpenDropdown((prevOpenDropdown) => (prevOpenDropdown === id ? null : id));
  };

  const deleteVisa = (VisaId, userId) => {
    setIsLoading(true);
    ProfileService.deleteStudentVisa(VisaId, userId).then((res) => {
      toast.success("Visa Deleted Successfully ");
      setConfirmationModalIsVisible(false);
      setIsLoading(false);
      getStudentVisa(studentID);
    }).catch((err) => {
      console.log('err: ', err);
      setIsLoading(false);
    });
  }
  const getConfirmation = () => {
    deleteVisa(visaIdValue, userId);
  };
  const hideConfirmationModalHandler = () => {
    setConfirmationModalIsVisible(false);
  };
  const handleRemoveVisa = (visaId) => {
    console.log('visaId: ', visaId);
    setConfirmationModalIsVisible(true);
    setConfirmationText('Are you sure, you want to remove the selected visa?');
    setBtnTextName('Remove');
    setVisaIdValue(visaId);
  };

  const uploadDocFile = (file) => {
    setDocPathName(file)
  }

  const openVisaModal = (visa = null) => {
    if (visa) {
      // Update mode
      setIsEditMode(true);
      const matchedStatus = statusList.find(status => status.StatusName === visa.VisaStatus);
      setFormVisa({
        StudentVisaId: visa.StudentVisaId || '',
        CountryId: visa.CountryId || '',
        VisaTypeId: visa.VisaTypeId || '',
        VisaStatus: matchedStatus ? matchedStatus.StatusId : '',
        IssueDate: visa.IssueDate || '',
        ValidUpto: visa.ValidUpto || '',
        Comment: visa.Comment || '',
        DocumentPath: visa.DocumentPath || ''
      });
    } else {
      // Add mode
      setIsEditMode(false);
      setFormVisa({
        StudentVisaId: 0,
        CountryId: '',
        VisaTypeId: '',
        VisaStatus: '',
        IssueDate: '',
        ValidUpto: '',
        Comment: '',
        DocumentPath: ''
      });
    }
    setVisaModel(true);
  };

  const handleSchoolEditClick = (school) => {
    setEducationDetails(school);
    setSchoolEditModel(true);
  };


  const handleSubmitVisa = () => {
    setIsLoading(true);
    const payload = {
      ...formVisa,
      StudentId: studentID,
      UserId: userId,
      VisaTypeId: parseInt(formVisa.VisaTypeId, 10),
      // VisaStatus: parseInt(formVisa.VisaStatus, 10) 
    };

    if (isEditMode) {
      // Update the existing visa
      ProfileService.updateStudentVisa(payload)
        .then((res) => {
          toast.success(res.message);
          getStudentVisa(studentID);
          setIsLoading(false);
          setVisaModel(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log('Error updating visa:', err);
        });
    } else {
      // Add new visa
      ProfileService.addStudentVisa(payload)
        .then((res) => {
          toast.success(res.message);
          getStudentVisa(studentID);
          setVisaModel(false);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log('Error adding visa:', err);
        });
    }
  };
  const handleFileUpload = (file) => {
    setFormVisa((prevForm) => ({
      ...prevForm,
      DocumentPath: file
    }));
  };


  const handleFileUploadEducation = (file) => {
    setEducationDetails((prevForm) => ({
      ...prevForm,
      ExamDocUrl: file
    }));
  };

  const refreshSchoolList = () => {
    getSchoolDetailList();
  }

  const handleUpdateEducation = () => {
    const data = {
      SchoolId: educationDetails.SchoolId || 0,
      ParentId: educationDetails.ParentId || studentID,
      ParentType: educationDetails.ParentType || 6,
      LevelOfEducation: educationDetails.LevelOfEducation || 0,
      LevelOfEducationName: educationDetails.LevelOfEducationName || '',
      CountryOfInstitution: educationDetails.CountryOfInstitution || 0,
      CountryOfInstitutionName: educationDetails.CountryOfInstitutionName || '',
      NameOfInstitution: educationDetails.NameOfInstitution || '',
      Language: educationDetails.Language || '',
      AttendendFrom: educationDetails.AttendendFrom || 0,
      AttendendTo: educationDetails.AttendendTo || 0,
      Degree: educationDetails.Degree || '',
      DegreeAwardedOn: educationDetails.DegreeAwardedOn || 0,
      Addres: educationDetails.Addres || '',
      City: educationDetails.City || '',
      Province: educationDetails.Province || '',
      Pincode: educationDetails.Pincode || '',
      Marks: educationDetails.Marks.length > 0 ? educationDetails.Marks : [],
      StreamId: educationDetails.StreamId || 0,
      institutionId: educationDetails.institutionId || '',
      ExamDocUrl: educationDetails.ExamDocUrl || '',
    };

    setIsLoading(true)
    ApplicationService.updateSchoolDetail(data)
      .then((res) => {
        setIsLoading(false)
        toast.success("Education Document Uploaded successfully!");
        refreshSchoolList();
        setEducationDetails("");
        setSchoolEditModel(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });
  }

  return (
    <div>
      {confirmationModalIsVisible && (
        <ConfirmationModal onClose={hideConfirmationModalHandler} confirmationText={confirmationText} btnText={btnTextName} onShow={confirmationModalIsVisible} getConfirmation={getConfirmation}>
        </ConfirmationModal>
      )}
      {isLoading && <Loader />}
      {passportModel &&
        <SearchModal onShow={passportModel} size={'lg'}>
          {isLoading && <Loader />}
          <div className={classes['passport-modal']}>
            <div className={classes['passport-header']}>
              <div className={`${classes['passport-title']} ps-2`}>Passport Detail</div>
              <div className={classes['passport-crose-icon']} onClick={() => setPassportModel(false)}>
                <img src={crossIcon} className={classes['crose-icon-size']} />
              </div>
            </div>
            <div className={classes['passport-body']}>
              <div className='row'>
                <div className="col-md-4 m-0 p-1">
                  <label className={classes["PassportNoLabel"]}>
                    Passport No<span className={classes["required-asterisk"]}>*</span>
                  </label>
                  <input className="form-control" type="text" name="PassportNo" id="" value={form.PassportNo} required onChange={handleChange} />
                  {errors.PassportNo && <span className="text-danger">{errors.PassportNo}</span>}
                </div>
                <div className="col-md-4 m-0 p-1">
                  <label className={classes["PassportExpiryDateLabel"]}>Expiry Date<span className={classes["required-asterisk"]}>*</span></label>
                  <input className="form-control" type="date" name="PassportExpiryDate" value={form.PassportExpiryDate} required onChange={handleChange} />
                  {errors.PassportExpiryDate && <span className="text-danger">{errors.PassportExpiryDate}</span>}
                </div>
                <div className="col-md-4 m-0 p-1">
                  <label className={classes["CitizenshipLabel"]}>Country<span className={classes["required-asterisk"]}>*</span></label>
                  <select className="form-select" type="text" name="Citizenship" value={form.Citizenship} required onChange={handleChange}>
                    <option>---select---</option>
                    {countryList && countryList.map((country, index) => (
                      <option key={index} value={country.CountryId}>{country.CountryName}</option>
                    ))}
                  </select>
                  {errors.Citizenship && <span className="text-danger">{errors.Citizenship}</span>}
                </div>
              </div>
              <div className='row'>
                <div className="col-md-6 m-0 p-1">
                  <label className={classes["CitizenshipLabel"]}>Upload Document</label>
                  <UploadDocument btnText="Upload File" uploadFile={uploadDocFile} filePathUrl={docPathName} />
                </div>
              </div>
            </div>
            <div className="mt-2 text-center">
              <button className="primary-button" onClick={handleSubmit}>Update</button>
            </div>
          </div>
        </SearchModal>
      }

      {visaModel && (
        <SearchModal onShow={visaModel} size="lg">
          {isLoading && <Loader />}
          <div className={classes['passport-modal']}>
            <div className={classes['passport-header']}>
              <div className={`${classes['passport-title']} ps-2`}>
                {isEditMode ? 'Update Visa Detail' : 'Add Visa Detail'}
              </div>
              <div className={classes['passport-crose-icon']} onClick={() => setVisaModel(false)}>
                <img src={crossIcon} alt="Close" />
              </div>
            </div>
            <div className={classes['passport-body']}>
              <div className="row">
                <div className="col-md-6 p-1">
                  <label className={classes["PassportNoLabel"]}>Country<span className={classes["required-asterisk"]}>*</span></label>
                  <select
                    className="form-select"
                    name="CountryId"
                    value={formVisa.CountryId}
                    onChange={handleVisaChange}
                    required
                  >
                    <option value="">--- Select Country ---</option>
                    {countryList.map((country) => (
                      <option key={country.CountryId} value={country.CountryId}>
                        {country.CountryName}
                      </option>
                    ))}
                  </select>
                  {errors.CountryId && <span className="text-danger">{errors.CountryId}</span>}
                </div>

                <div className="col-md-6 p-1">
                  <label className={classes["PassportNoLabel"]}>Visa Type<span className={classes["required-asterisk"]}>*</span></label>
                  <select
                    className="form-select"
                    name="VisaTypeId"
                    value={formVisa.VisaTypeId}
                    onChange={handleVisaChange}
                    required
                  >
                    <option value="">--- Select Visa Type ---</option>
                    {visaTypeList.map((visaType) => (
                      <option key={visaType.VisaTypeId} value={visaType.VisaTypeId}>
                        {visaType.VisaTypeName}
                      </option>
                    ))}
                  </select>
                  {errors.VisaTypeId && <span className="text-danger">{errors.VisaTypeId}</span>}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4 p-1">
                  <label className={classes["PassportNoLabel"]}>Status<span className={classes["required-asterisk"]}>*</span></label>
                  <select
                    className="form-select"
                    name="VisaStatus"
                    value={formVisa.VisaStatus}
                    onChange={handleVisaChange}
                    required
                  >
                    <option value="">--- Select Status ---</option>
                    {statusList.map((status) => (
                      <option key={status.StatusId} value={status.StatusId}>
                        {status.StatusName}
                      </option>
                    ))}
                  </select>
                  {errors.VisaStatus && <span className="text-danger">{errors.VisaStatus}</span>}
                </div>

                <div className="col-md-4 p-1">
                  <label className={classes["PassportNoLabel"]}>Issue Date</label>
                  <input
                    className="form-control"
                    type="date"
                    name="IssueDate"
                    value={formVisa.IssueDate}
                    onChange={handleVisaChange}
                  />
                  {errors.IssueDate && <span className="text-danger">{errors.IssueDate}</span>}
                </div>

                <div className="col-md-4 p-1">
                  <label className={classes["PassportNoLabel"]}>Valid Upto</label>
                  <input
                    className="form-control"
                    type="date"
                    name="ValidUpto"
                    value={formVisa.ValidUpto}
                    onChange={handleVisaChange}
                    min={formVisa.IssueDate || new Date().toISOString().split('T')[0]}

                  />
                  {errors.ValidUpto && <span className="text-danger">{errors.ValidUpto}</span>}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 p-1">
                  <label className={classes["PassportNoLabel"]}>Comment</label>
                  <textarea
                    className="form-control"
                    name="Comment"
                    value={formVisa.Comment}
                    onChange={handleVisaChange}
                  />
                  {errors.Comment && <span className="text-danger">{errors.Comment}</span>}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 p-1">
                  <label className={classes["PassportNoLabel"]}>Upload Document</label>
                  <UploadDocument btnText="Upload File" uploadFile={(file) => handleFileUpload(file)} filePathUrl={formVisa.DocumentPath} />
                </div>
              </div>
            </div>

            <div className="mt-3 text-center">
              <button className="primary-button" onClick={handleSubmitVisa}>
                {isEditMode ? 'Update' : 'Add'}
              </button>
            </div>
          </div>
        </SearchModal>
      )}


      {schooleditModel && (
        <SearchModal onShow={schooleditModel} size="md" style={{ minHeight: '80px' }}>
          <div className={classes['passport-modal']}>
            <div className={classes['passport-header']}>
              <div className={`${classes['passport-title']} ps-2`}>
                Upload Education Document
              </div>
              <div className={classes['passport-crose-icon']} onClick={() => setSchoolEditModel(false)}>
                <img src={crossIcon} alt="Close" style={{ cursor: 'pointer' }} />
              </div>
            </div>
            <div className={classes['passport-body']}>
              <div className="d-flex align-items-center" >
                <UploadDocument
                  btnText="Upload Document"
                  uploadFile={(file) => handleFileUploadEducation(file)}
                  filePathUrl={educationDetails.ExamDocUrl}
                />
              </div>
            </div>
            <div className="mt-3 text-center">
              <button className="primary-button" onClick={handleUpdateEducation}>
                Upload
              </button>
            </div>
          </div>
        </SearchModal>
      )}



      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
          <div className={classes["icon-alignment"]}>
            <div className="d-flex">
              <div>
                <span>
                  <img
                    className={classes["educationIcon-image-size"]}
                    src={passportIcon}
                    alt=""></img>
                </span>
              </div>
              <div className={` ${classes["grey-text"]}`}>Passport: {passportData.PassportNo ? passportData.PassportNo : 'N/A'}</div>
            </div>
            <div>
              <span className={classes["coin-count"]}>5</span>
              <span>
                <img
                  className={classes["coin-image-size"]}
                  src={coinIcon}
                  alt=""></img>
              </span>
              <img
                className={classes["edit-icon"]}
                src={editIcon}
                alt="" onClick={() => setPassportModel(true)}></img>
            </div>
          </div>
          <div className={classes["icon-alignment"]}>
            <div className="d-flex">
              <div>
                <span>
                  <img
                    className={classes["educationIcon-image-size"]}
                    src={countryIcon}
                    alt=""></img>
                </span>
              </div>
              <div className={` ${classes["grey-text"]}`}>{passportData.Citizenship ?
                countryList.find((country) => country.CountryId === passportData.Citizenship)?.CountryName
                : 'N/A'}</div>
            </div>
          </div>
          <div className={classes["icon-alignment"]}>
            <div className="d-flex">
              <div>
                <span>
                  <img
                    className={classes["educationIcon-image-size"]}
                    src={calenderIcon}
                    alt=""></img>
                </span>
              </div>
              <div className={`${classes["grey-text"]}`}>
                {passportData.PassportExpiryDate ? new Date(passportData.PassportExpiryDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : "NA"}
              </div>
            </div>
          </div>
          <div className={classes["icon-alignment"]}>
            <div className="d-flex">
              <div>
                <span>
                  <img
                    className={classes["educationIcon-image-size"]}
                    src={documentIcon}
                    alt=""></img>
                </span>
              </div>
              <div className={` ${classes["grey-text"]}`}>Document</div>
              {passportData.DocPath ?
                <div className="">
                  <span>
                    <img
                      className={classes["educationIcon-image-size"]}
                      src={eyeIcon}
                      alt="" onClick={() => window.open(`${blobUrl}${passportData.DocPath}`, '_blank')}></img>
                  </span>
                </div> : ""}
            </div>
          </div>
        </div>
      </div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
          <div className={classes["heading-align"]}>
            <div>
              <p className={classes["heading-text"]}>Visa</p>
            </div>
            <div>
              <span className={classes["coin-count"]}>5</span>
              <span>
                <img
                  className={classes["coin-image-size"]}
                  src={coinIcon}
                  alt=""></img>
              </span>
              <img
                className={classes["image-size"]}
                src={addIcon} onClick={() => openVisaModal()}
                alt=""></img>
            </div>
          </div>
          <div>
            {visaData.map((visa, index) => (
              <div key={visa.StudentVisaId}>
                <div className={classes["icon-alignment"]}>
                  <div className="d-flex">
                    <div>
                      <span>
                        <img
                          className={classes["educationIcon-image-size"]}
                          src={visaIcon}
                          alt=""></img>
                      </span>
                    </div>
                    <div className={` ${classes["grey-text"]}`}>{visa.VisaTypeName}</div>
                    <div className="ms-4">
                      <span>
                        <img
                          className={classes["edit-icon"]}
                          src={openDropdown == visa.StudentVisaId ? upperArrowICon : dropArrowICon}
                          alt=""
                          onClick={() => toggleDropdown(visa.StudentVisaId)}
                        />
                      </span>
                    </div>

                  </div>
                  <div className="">
                    <span>
                      <img
                        className={classes["edit-icon"]}
                        src={deleteICon}
                        onClick={() => handleRemoveVisa(visa.StudentVisaId)}
                        alt=""></img>
                    </span>
                    <span>
                      {" "}
                      <img
                        className={classes["edit-icon"]}
                        src={editIcon}
                        alt="" onClick={() => openVisaModal(visa)} ></img>
                    </span>
                  </div>
                </div>
                {openDropdown === visa.StudentVisaId && (
                  <div className="dropDownContainer">
                    <div className={classes["icon-alignment"]}>
                      <div className="d-flex">
                        <div className="">
                          <span>
                            <img
                              className={classes["educationIcon-image-size"]}
                              src={locationIcon}
                              alt=""></img>
                          </span>
                        </div>
                        <div className={` ${classes["grey-text"]}`}>{visa.CountryName}</div>
                      </div>
                    </div>
                    <div className={classes["icon-alignment"]}>
                      <div className="d-flex">
                        <div>
                          <span>
                            <img
                              className={classes["educationIcon-image-size"]}
                              src={documentIcon}
                              alt=""></img>
                          </span>
                        </div>
                        <div className={` ${classes["grey-text"]}`}>Document</div>
                        {visa.DocumentPath ?
                          <div className="">
                            <span>
                              <img
                                className={classes["educationIcon-image-size"]}
                                src={eyeIcon}
                                alt="" onClick={() => window.open(`${blobUrl}${visa.DocumentPath}`, '_blank')}></img>
                            </span>
                          </div> : ""}
                      </div>
                    </div>
                  </div>
                )}
                {index < educationData.length - 1 && (
                  <img
                    className={`${classes.imageLineHeight}`}
                    src={lineIcon}></img>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
          <div className={classes["heading-align"]}>
            <div>
              <p className={classes["heading-text"]}>Education</p>
            </div>
            <div className="d-flex align-items-center">
              <span className={classes["coin-count"]}>5</span>
              <span><img className={classes["coin-image-size"]} src={coinIcon} alt="" /></span>
              {/* <img onClick={addEducationModalOpen} className={classes["image-size"]} src={addIcon} alt="" /> */}
            </div>
          </div>
          <div>
            {educationData.map((school, index) => (
              <div key={school.id}>
                <div className={classes["icon-alignment"]}>
                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                  <div className="d-flex">
                    <div>
                      <span>
                        <img className={classes["educationIcon-image-size"]} src={graduationCap} alt="" />  </span>
                    </div>
                    <div className={`${classes["grey-text"]}`}>
                      {school.NameOfInstitution}
                    </div>

                    <div className="ms-4">
                      <span>
                        <img
                          className={classes["edit-icon"]}
                          src={openDropdown === school.SchoolId ? upperArrowICon : dropArrowICon}
                          alt=""
                          onClick={() => toggleDropdown(school.SchoolId)}
                        />
                      </span>
                    </div>

                  </div>
                </div>

                {openDropdown === school.SchoolId && (
                  <div className="dropDownContainer">
                    {/* Level of Education */}
                    <div className={classes["icon-alignment"]}>
                      <div className="d-flex align-items-center">
                        <img className={classes["educationIcon-image-size"]} src={programIcon} alt="" />
                        <div className={`  ${classes["grey-text"]}`}>
                          {school.LevelOfEducationName ? school.LevelOfEducationName : "NA"}
                        </div>
                      </div>
                    </div>

                    {/* Marks/Grade */}
                    <div className={classes["icon-alignment"]}>
                      <div className="d-flex align-items-center">
                        <img className={classes["educationIcon-image-size"]} src={starIcon} alt="" />
                        <div className={`  ${classes["grey-text"]}`}>
                          {school.Marks.length > 0 ? school.Marks[0].GradeName : "NA"}
                        </div>
                      </div>
                    </div>

                    {/* Marks/Obtained */}
                    <div className={classes["icon-alignment"]}>
                      <div className="d-flex align-items-center">
                        <img className={classes["educationIcon-image-size"]} src={starIcon} alt="" />
                        <div className={`${classes["grey-text"]}`}>
                          {school.Marks.length > 0 ? school.Marks[0].ObtainMarks : "NA"}
                        </div>
                      </div>
                    </div>

                    {/* Attended From */}
                    <div className={classes["icon-alignment"]}>
                      <div className="d-flex align-items-center">
                        <img className={classes["educationIcon-image-size"]} src={calenderIcon} alt="" />
                        <div className={`  ${classes["grey-text"]}`}>
                          {school.AttendendFrom ? new Date(school.AttendendFrom).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : "NA"}
                        </div>
                      </div>
                    </div>

                    {/* Attended To */}
                    <div className={classes["icon-alignment"]}>
                      <div className="d-flex align-items-center">
                        <img className={classes["educationIcon-image-size"]} src={calenderIcon} alt="" />
                        <div className={`  ${classes["grey-text"]}`}>
                          {school.AttendendTo ? new Date(school.AttendendTo).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : "NA"}
                        </div>
                      </div>
                    </div>

                    {/* Country of Institution */}
                    <div className={classes["icon-alignment"]}>
                      <div className="d-flex align-items-center">
                        <img className={classes["educationIcon-image-size"]} src={locationIcon} alt="" />
                        <div className={`  ${classes["grey-text"]}`}>
                          {school.CountryOfInstitutionName ? school.CountryOfInstitutionName : "NA"}
                        </div>
                      </div>
                    </div>

                    {/* Document row */}
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <img className={classes["educationIcon-image-size"]} src={documentIcon} alt="" />
                        <div className={`  ${classes["grey-text"]}`}>Document</div>
                        {school.ExamDocUrl &&
                          <div>
                            <span>
                              <img
                                className={classes["educationIcon-image-size"]}
                                src={eyeIcon}
                                alt=""
                                onClick={() => window.open(`${blobUrl}${school.ExamDocUrl}`, "_blank")}
                              />
                            </span>
                          </div>
                        }
                      </div>

                      {/* Edit icon only for documents */}
                      <div>
                        <img
                          className={classes["edit-icon"]}
                          src={editIcon}
                          alt=""
                          onClick={() => handleSchoolEditClick(school)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {index < educationData.length - 1 && <img className={`${classes.imageLineHeight}`} src={lineIcon} />}
              </div>
            ))}
          </div>
        </div>
      </div>


    </div>

  );
}
export default Documents;
