import axiosInstance from "./Interceptor";
const PostServices = {
  uploadFile: async (data) => {
    try {
      const response = await axiosInstance.post('/common/upload-file', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  addPost: async (data) => {
    try {
      const response = await axiosInstance.post('/post/add', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getPostList: async (data) => {
    try {
      const response = await axiosInstance.post('/post/list', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  updatePost: async (data) => {
    try {
      const response = await axiosInstance.post('/post/update-data', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getComments: async (data) => {
    try {
      const response = await axiosInstance.post('/post/comments', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  addComments: async (data) => {
    try {
      const response = await axiosInstance.post('/post/add-comment', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  likePost: async (like) => {
    try {
      const response = await axiosInstance.post('/post/like', like);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  sharePost: async (data) => {
    try {
      const response = await axiosInstance.post('/post/share', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  deletePostMedia: async (id) => {
    try {
      const response = await axiosInstance.post('/post/delete-media/'+ id);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  deletePostById: async (id) => {
    try {
      const response = await axiosInstance.post('/post/delete?postId='+ id);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  
}
export default PostServices;