import React  from 'react'
import classes from "./NoteSection.module.css";
import { Button, Form, Table } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
const NoteSection = () => {
    const tableData = {
        columns: ["Student", "Date"],
        documents: [
            {
                id: 1,
                "Student": 'I will be arriving later than the other students',
                'Date': '29/08/2024',
            },
            {
                id: 2,
                "Student": 'I will be arriving later than the other students',
                "Date": '29/08/2024',
            },
        ]
      };

  return (
    <div className={classes.container}>
             <FloatingLabel controlId="floatingTextarea2" label="Enter your comment here...">
             <Form.Control
             className={classes.forTextAreaContainer}
               as="textarea"
               style={{ height: '100px' }}
             />
             </FloatingLabel>     
        <div className={classes.btnContainer}>
            <Button className={classes.secondaryBtn}>+Add Note</Button>
        </div>
      <Table responsive className={classes.customTable}>    
        <thead>
          <tr>
            {tableData.columns.map((column, index) => (
              <th key={index}>
                <div className={classes.thAndIconContainer}>
                  <span className={classes.columnText}>{column}</span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.documents.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {tableData.columns.map((column, colIndex) => (
                <td key={colIndex}>{row[column]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table> 
   </div>
  )
}

export default NoteSection