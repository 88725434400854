import React, { useEffect, useState, useRef } from "react";
import classes from "./User_Account.module.css";
import Marketing from "./Marketing/Marketing";
import avatarImage from "../../../../Assets/Images/backgroung_profile_image.jpg";
import profile_picture from "../../../../Assets/Images/profile_avatar.png";
import edit_profile from "../../../../Assets/Logo/edit_profile.svg";
import Posts from "../../../Common/Posts/Posts";
import Settings from "./Settings/Settings";
import Connections from "./Connections/Connections";
import About from "./About/About";
import Documents from "./Documents/Documents";
import Modal_Box from "../../../GlobleShared/Modal/Modal_Box";
import ProfileModel from "./Settings/Section/ProfileModel";
import Applications from "./Applications/Applications";
import ProfileService from "../../../../Services/ProfileService";
import Loader from "../../../GlobleShared/Loader/Loader";
import useLocalStorage from "../../../GlobleShared/CustomHooks/useLocalStorage";
import { toast } from "react-toastify";
import { useProfile } from "../../../Context/ProfileContext";

function UserAccount() {
  const [tab, setTab] = useState("About");
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [selectedHeading, setselectedHeading] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('');
  const {profileImage, setProfileImage } = useProfile();
  const [studentDetails, setStudentDetails] = useState();
  const [connectionCount, setConnectionCount] = useState(0); 
  const initial = useRef(null);
  const blobUrl = process.env.REACT_APP_URL_PATH;

  function hideModalhandler() {
    setModalIsVisible(false);
  }

  const [userDetail] = useLocalStorage('userDetail');
  const studentId = userDetail.refId;

  function showModalhandler(header) {
    setselectedHeading(header);
    setModalIsVisible(true);
  }

  const getAboutProfileInfo = () => {
    setIsLoading(true);
    ProfileService.getAboutProfileInfo(studentId).then((res) => {
      setRowData(res);
      if (res) {
        if (res && res.ProfileBGImage) {
          setBackgroundImage(blobUrl + res.ProfileBGImage);
        } else {
          setBackgroundImage(avatarImage);
        }
        if (res && res.ProfileImgPath) {
          setProfileImage(blobUrl + res.ProfileImgPath);
        } else {
          setProfileImage(profile_picture);
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    }).catch((error) => {
      console.log('error: ', error);
      setIsLoading(false);
    });
  }

  const getConnectionCount = () => {
    setIsLoading(true);
    ProfileService.getConnectionCount()
      .then((res) => {
        setConnectionCount(res);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!initial.current) {
      initial.current = true;
      getAboutProfileInfo();
      getStudentDetail(studentId);
      getConnectionCount(); 
    }
  }, []);

  const getStudentDetail = () => {
    // setIsLoading(true);
    // StudentServices.getStudentDetail(studentId)
    //     .then((res) => { 
    //         setStudentDetails(res);
    //         if(res && res.ProfileBGImage){
    //           setBackgroundImage(blobUrl+res.ProfileBGImage);
    //         }else{
    //           setBackgroundImage(avatarImage);
    //         }
    //         //setIsLoading(false);
    //         if(res && res.ProfileImgPath){
    //           setProfileImage(blobUrl+res.ProfileImgPath);
    //         }else{
    //           setProfileImage(profile_picture);
    //         }
    //         setIsLoading(false);
    //     })
    //     .catch((error) => {
    //         toast.error(error?.message);
    //         setIsLoading(false);

    //     });
  }

  const handleImageChange = (image) => {
    // getStudentDetail();
    getAboutProfileInfo();
  };

  const handleProfileImageChange = (image) => {
    console.log("Updated Profile Image:", image);
    setProfileImage(image);
  };

  function hideModalhandler() {
    setModalIsVisible(false);
  }

  function showModalhandler(header) {
    setselectedHeading(header);
    setModalIsVisible(true);
  }

  return (
    <div className={`${classes["user_account"]} row `}>
      {isLoading && <Loader></Loader>}
      <div className={` ${classes["user_account_section"]} col-md-9 col-lg-9`}>
        <div className={classes["user_account_info"]}>
          <div className={classes["background_image"]}>
            <div className={classes["background_image_pic"]}>
              <img src={backgroundImage} alt="background-image " />
            </div>
            <div className={classes["profile_pic"]}>
              <span className={classes["user_profile_pic"]}>
                <img src={profileImage} alt="User Profile_Picture" />
              </span>
              <span className={classes["edit_profile_icon"]} onClick={() => setModalIsVisible(true)}>
                <img src={edit_profile} alt="" />
              </span>
            </div>
          </div>
          {modalIsVisible && (
            <Modal_Box removePadding={true} onClose={hideModalhandler} onShow={showModalhandler} title={selectedHeading}>
              <ProfileModel
                onProfileImageUpdated={handleImageChange}
                onClose={hideModalhandler}
              />
            </Modal_Box>
          )}
          <div className={`row ${classes["user_details"]}`}>
            <div className={`col-md-6 ${classes["user_personalDetails"]}`}>
              <p>
                {(rowData.FirstName && rowData.LastName) ? (rowData.FirstName + ' ' + rowData.LastName) : 'NA'}
                <span>
                  {rowData.Gender === 'Female' ? '(She/Her)' : rowData.Gender === 'Male' ? '(He/His)' : "N/A"}
                </span>
              </p>
              <p>{rowData.Email ? rowData.Email : "N/A"}</p>
            </div>

            <div className={`col-md-6 ${classes["user_highlights"]}`}>
              <p>Studying Computer Science</p>
              <p>Highlights</p>
            </div>
          </div>

          <div className={classes["user_connections"]}>{connectionCount} Connections</div>

          <div className={classes["section-line"]}>
            <hr />
          </div>

          <div className={classes["user_navigation"]}>
            <button
              className={tab === "About" ? classes["active_button"] : ""}
              onClick={() => setTab("About")}
            >
              About
            </button>
            <button
              className={tab === "Documents" ? classes["active_button"] : ""}
              onClick={() => setTab("Documents")}
            >
              Documents
            </button>
            <button
              className={tab === "Connections" ? classes["active_button"] : ""}
              onClick={() => setTab("Connections")}
            >
              Connections
            </button>
            <button
              className={tab === "Application" ? classes["active_button"] : ""}
              onClick={() => setTab("Application")}
            >
              Applications
            </button>
            <button
              className={tab === "Posts" ? classes["active_button"] : ""}
              onClick={() => setTab("Posts")}
            >
              Posts
            </button>
            <button
              className={tab === "Settings" ? classes["active_button"] : ""}
              onClick={() => setTab("Settings")}
            >
              Settings
            </button>
          </div>
        </div>
        {tab === "About" && (
          <div>
            <About rowData={rowData} onUpdate={getAboutProfileInfo} />
          </div>
        )}

        {tab === "Posts" && (
          <div className={classes["posts_section"]}>
            <Posts showCrossIcon={true} />
          </div>
        )}
        {tab === "Settings" && (
          <div>
            <Settings />
          </div>
        )}
        {tab === "Connections" && (
          <div>
            <Connections />
          </div>
        )}
        {tab === "Documents" && (
          <div>
            <Documents />
          </div>
        )}
        {tab === "Application" && (
          <div>
            <Applications />
          </div>
        )}
      </div>

      <div className={` ${classes["user_account_marketing"]} col-md-3 col-lg-3`}>
        <div className={classes["marketing_section"]}>
          <Marketing />
        </div>
      </div>
    </div>
  );
}

export default UserAccount;