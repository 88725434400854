import classes from './UploadPendingDocumentsModall.module.css';
import cross from '../../../../../Assets/Logo/crose-white.svg';
import upload from '../../../../../Assets/Logo/uploadBlueIcon.svg';
import uploadSuccessful from '../../../../../Assets/Logo/document-icon-blue.svg';
import { Col, Form, Row, Table } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import Loader from '../../../../GlobleShared/Loader/Loader';
import ApplicationService from '../../../../../Services/ApplicationServices';
import { toast } from 'react-toastify';
import StudentServices from '../../../../../Services/StudentServices';
import CommonServices from '../../../../../Services/CommonServices';
import ProfileService from '../../../../../Services/ProfileService';

const UploadPendingDocumentsModall = ({ onClose, applicationId, programId, countryId, parentType = 7, onSubmitPendingDocuments }) => {
    const initial = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [documentTypeList, setDocumentTypeList] = useState([]);
    const [studentDocumentDataList, setStudentDocumentDataList] = useState([]);
    const [pendingDocumentSCount, setPendingDocumentSCount] = useState();
    const inputFileRef = useRef();
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState("");
    const [selectedDocumentTypeId, setSelectedDocumentTypeId] = useState("");
    const [documentName, setDocumentName] = useState("");

    useEffect(() => {
        if (!initial.current) {
            initial.current = true;
    
            if (applicationId && programId && countryId && parentType) {
                loadDocumentTypeAndStudentDocumentData(applicationId, programId, countryId, parentType);
            }
        }
    }, [applicationId, programId, countryId, parentType]);

    const loadDocumentTypeAndStudentDocumentData = async (appId, progmId, citizenshipId, pTypeId, forceRefetch) => {
        setIsLoading(true);
        try {
            let documentTypes = documentTypeList;

            //Pending Document Count
            const PendingDocumentStatus = await ApplicationService.pendingDocumentsCount(appId);
            setPendingDocumentSCount(PendingDocumentStatus);

            //Document Type List
            if(documentTypeList.length === 0 || forceRefetch){
                documentTypes = await ApplicationService.getProgramDocumentList(appId, progmId, citizenshipId);
            }
    
            //Student Document Data
            const studentDocs = await StudentServices.studentDocumentList(appId, pTypeId);

            //Filter logic
            const mergedDocuments = documentTypes.map((docType) => {
                const uploadedDoc = studentDocs.find((doc) => doc.DocumentTypeId === docType.DocumentTypeId);
                console.log("uploadedDoc: ",uploadedDoc);
                return {
                    ...docType,
                    DocPath: uploadedDoc?.DocPath || null,
                    UploadedDate: uploadedDoc ? new Date(uploadedDoc.AddStamp).toLocaleDateString() : null,
                    UploadBy: uploadedDoc?.UploadBy || null,
                };
            });
            setStudentDocumentDataList(mergedDocuments || []);
    
            const filteredDocTypes = documentTypes.filter((item) =>
                studentDocs.every((document) => document.DocumentTypeId !== item.DocumentTypeId)
            );
            setDocumentTypeList(filteredDocTypes || []);
    
        } catch (error) {
            toast.error(error?.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getDocumentTypehandler = (event) => {
        const selectedId = event.target.value;
        setSelectedDocumentTypeId(selectedId);
        
        const document = documentTypeList.find(item => item.DocumentTypeId === Number(selectedId));
        setDocumentName(document ? document.DocumentName : "");
    };

    const fileUpload = (event) => {
        const file = event.target.files[0];

        if (file && (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'application/pdf')) {
            setSelectedFile(file);
            setSelectedFileName(file.name);
        } else {
            toast.error('Supported file format is : JPG, JPEG, PNG and PDF');
            return;
        }
        event.target.value = null;
    };

    const onFileUpload = () => {
        inputFileRef.current.click();
    };

    const triggerFileUpload = () => {
        if (selectedFile && selectedDocumentTypeId) {
            const formData = new FormData();
            formData.append("file", selectedFile);

            setIsLoading(true);
            CommonServices.uploadFile(formData)
                .then((res) => {
                    if (res) {
                        addStudentDocument(res.fileLocation);
                    }
                    setIsLoading(false);
                    setSelectedFile(null);
                    setSelectedFileName("");
                })
                .catch((error) => {
                    setIsLoading(false);
                    toast.error(error.message);
                });
        }
    };

    const addStudentDocument = (filePath) => {
        if (!filePath) {
            return;
        }
        setIsLoading(true);
        const data = {
            DocPath: filePath,
            DocumentId: 0,
            DocumentName: documentName,
            DocumentTypeId: Number(selectedDocumentTypeId),
            DueDate: "",
            ExpiryDate: "",
            ParentId: applicationId,
            ParentType: parentType
        }
        ProfileService.addStudentDocument(data)
            .then((res) => {
                if (res) {
                    loadDocumentTypeAndStudentDocumentData(applicationId, programId, countryId, parentType, true);
                    setSelectedDocumentTypeId("");
                    setDocumentName("");
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    }

    const submitHandler = () => {
        if(pendingDocumentSCount === 0){
            onSubmitPendingDocuments(true);
        }
        else{
            toast.error('Upload all required documents before submit.');
        }
    }

    return (
        <>
            <div className={classes["upload-document-modal-block"]}>
                <div className={classes["upload-document-modal-header"]}>
                    <div className={classes["heading"]}>Upload Document</div>
                    <div><img className={classes["cross-icon"]} src={cross} alt="cross" onClick={onClose} /></div>
                </div>
                <div className={classes["upload-document-modal-body"]}>
                    <Form>
                        <Row className={`${classes["upload-document-section"]} align-items-center mb-4`}>
                            <Col sm={7} className="p-0">
                                <Form.Group>
                                    <Form.Select className={classes["document-name-field"]} value={selectedDocumentTypeId} onChange={getDocumentTypehandler}>
                                        <option disabled value="">Document Name</option>
                                        {documentTypeList && documentTypeList.map((docType) => (
                                            <option key={docType.DocumentTypeId} value={docType.DocumentTypeId}>
                                                {docType.DocumentName}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs="auto" className="p-0">
                                <input
                                    className={classes["file-input"]}
                                    type="file"
                                    aria-label="file upload"
                                    name="file"
                                    ref={inputFileRef}
                                    onChange={fileUpload}
                                />
                                <button type="button" className={`${classes["select-file-btn"]} secondary-button`} onClick={onFileUpload}>Choose File</button>
                            </Col>
                            <Col xs="auto" className="p-0">
                                <button type="button" className={`${classes["upload-file-btn"]} primary-button`} onClick={triggerFileUpload} disabled={(!selectedFile || !selectedDocumentTypeId)}>Upload</button>
                            </Col>
                        </Row>
                    </Form>

                    <div className={classes["upload-document-table"]}>
                        <Table responsive className={classes.customTable}>
                            <thead>
                                <tr>
                                    <th>Attachment</th>
                                    <th>Date Uploaded</th>
                                    <th>Upload By</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {studentDocumentDataList && studentDocumentDataList.length > 0 ? (
                                    studentDocumentDataList.map((document, index) => (
                                        <tr key={document.DocumentTypeId || index}>
                                            <td>{document.DocumentName || 'N/A'}</td>
                                            <td>{document.UploadedDate || 'N/A'}</td>
                                            <td>{document.UploadBy || 'N/A'}</td>
                                            <td>
                                                {document.DocPath ? (
                                                    <div className={classes["action-column"]}>
                                                        <div><img src={uploadSuccessful} alt="Document" /></div>
                                                        <div>Upload Successful</div>
                                                    </div>
                                                ) : (
                                                    <div className={classes["action-column"]}>
                                                        <div><img src={upload} alt="Upload" /></div>
                                                        <div>Upload</div>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4" className={classes.noData}>
                                            No Data Available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>

                    <div className={classes["submit-document-button-block"]}><button type="submit" className={`${classes["submit-btn"]} primary-button`} onClick={submitHandler}>Submit Document</button></div>
                </div>
            </div>
            {isLoading && (
                <Loader></Loader>
            )}
        </>
    )
}

export default UploadPendingDocumentsModall;