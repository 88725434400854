import React, {useState, useRef, useEffect} from "react";
import { Modal } from "react-bootstrap";
import classes from "./SharePostModal.module.css";
import corssIcon from "../../../../Assets/Logo/cross-icon.svg";
import nameInfoImage from "../../../../Assets/Images/name-info-image.png";
import searchIcon from "../../../../Assets/Logo/search-icon-2.svg";
import Loader from "../../../GlobleShared/Loader/Loader";
import ConnectionService from "../../../../Services/ConnectionService";
import PostService from "../../../../Services/PostServices";
import { toast } from "react-toastify";


function SharePostModal({ onShow, onClose, postIdData }) {
  
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [paginated, setPaginated] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLsLoading] = useState(false);
  const initialized = useRef(false)
  const [profilesData, setProfilesData] = useState([]);

  const [postId, setPostId] = useState(postIdData);
  const [postShare, setPostShare] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchInput1, setSearchInput1] = useState("");
  

  const handleChange = (e) => {
    setSearchInput(e.target.value);
    // getConnectionList(page);
  };

  const handleGroupChange = (e) => {
    setSearchInput1(e.target.value);
    // getConnectionList(page);
  };

  const handleSearch=(e) =>{
    getConnectionList(page);
  }

  // call an api to share post
  const createPostShare = async () => {
    const SharePostData = {
      postId: postId,
      postShare: postShare,
      shareCount: postShare?.length,
    };

    setLsLoading(true)
    PostService.sharePost(SharePostData).then(res=>{
      setLsLoading(false);
      onClose();
      
      toast.success(res?.message);
    }).catch(error=>{
      setLsLoading(false)
      toast.success(error?.message);
    })

    console.log(SharePostData);

  };


  


  const getConnectionList=(pageValue)=>{
    let data={
      limit: limit,
      page: pageValue,
      paginated: paginated,
      searchTerm: searchInput,
    }
    setLsLoading(true)
    ConnectionService.getConnectionList(data).then(res=>{

      setProfilesData([...res?.list]);
      setTotalCount(res?.totalCount)
      setLsLoading(false)
    }).catch(error=>{
      setLsLoading(false)
    })
  }

  
  const handleCheckboxChange = (userId, type) => {
    console.log(postShare);
    setPostShare((prevPostShare) => {
      const existingEntry = prevPostShare.find(item => item.entityId === userId && item.entityType === type);
      
      if (existingEntry) {
        return prevPostShare.filter(item => item.entityId !== userId || item.entityType !== type);
      } else {
        return [...prevPostShare, { entityId: userId, entityType: type }];
      }
    });
  };



  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      getConnectionList(page);
    }
   
  }, [searchInput]);



  return (
    <div>
      <Modal
        show={onShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName={classes["modal-custom"]} // Apply the custom class here
      >
        <Modal.Body>
          <div className={classes["cross-icon-position"]}>
            <div className={classes["heading"]}>Share a Post</div>
            <img
              title="Close"
              className={classes["corss-icon"]}
              src={corssIcon}
              alt=""
              onClick={onClose}
            ></img>
          </div>

          {/* main-container */}

          <div className={classes["modal-body-data"]}>
            <div className={classes.main_container}>
              
              {/*for user list  */}
              <div className={classes.card}>
                <div className={`${classes["form-section"]}`}>
                  <div className={classes["invite-section"]}>
                    <div className={classes["heading"]}>Users</div>

                    <div className={`${classes["search_input_content"]}`}>
                      <input
                        type="text"
                        placeholder="Search..."
                        className={`${classes["search-input"]}`}
                        value={searchInput}
                        onChange={handleChange}
                      />
                      <img
                        src={searchIcon}
                        alt="Search"
                        className={`${classes["search-icon"]}`}
                         onClick={handleSearch}
                      />
                    </div>

                    <div className={`${classes["list-content"]}`}>
                      <div className={`${classes["invite-list"]}`}>

                      {profilesData.map((profile, index)  => (

                          <div key={profile?.userConnectionId+"_"+index} className={classes["name-item"]}>
                            <div className={classes["name-info"]}>
                              <img
                                src={(profile?.profileImage) ? (profile?.profileImage): nameInfoImage}
                                alt={profile?.displayName}
                                className={classes["name-image"]}
                              />
                              <span className={classes["name-text"]} >{profile?.displayName}</span>
                            </div>
                            <div className={classes["checkbox-container"]}>
                              <input
                                className={classes["checkbox-input"]}
                                type="checkbox"
                                checked={postShare.some(item => item.entityId === profile?.userConnectionId && item.entityType === 1)}
                                onChange={() => handleCheckboxChange(profile?.userConnectionId, 1)}
                                // onChange={() => handleCheckboxChange(profile?.userConnectionId, 2)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

           {/* for groups */}
           <div className={classes.card}>
                <div className={`${classes["form-section"]}`}>
                  <div className={classes["invite-section"]}>
                    <div className={classes["heading"]}>Groups</div>

                    <div className={`${classes["search_input_content"]}`}>
                      <input
                        type="text"
                        placeholder="Search..."
                        className={`${classes["search-input"]}`}
                        value={searchInput1}
                        onChange={handleGroupChange}
                      />
                      <img
                        src={searchIcon}
                        alt="Search"
                        className={`${classes["search-icon"]}`}
                        onClick={handleSearch}
                      />
                    </div>

                    <div className={`${classes["list-content"]}`}>
                      {/* <div className={`${classes["invite-list"]}`}>

                      {profilesData.map((profile, index)  => (

                          <div key={profile?.userConnectionId} className={classes["name-item"]}>
                            <div className={classes["name-info"]}>
                              <img
                                src={(profile?.profileImage) ? (profile?.profileImage): nameInfoImage}
                                alt={profile?.displayName}
                                className={classes["name-image"]}
                              />
                              <span className={classes["name-text"]} >{profile?.displayName}</span>
                            </div>
                            <div className={classes["checkbox-container"]}>
                              <input
                                className={classes["checkbox-input"]}
                                type="checkbox"
                                checked={postShare.some(item => item.entityId === profile?.userConnectionId && item.entityType === 2)}
                                onChange={() => handleCheckboxChange(profile?.userConnectionId, 2)}
                              />
                            </div>
                          </div>
                        ))}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              

              <div className={classes["share-section"]}>
              <button onClick={createPostShare} className={`${classes["share-btn"]}`}>Share</button>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>

      {isLoading && (
        <Loader></Loader>
      )}
    </div>

  );
}

export default SharePostModal;
