import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthRoutes from '../Routes/AuthRoutes';
import RootLayout from '../Routes/RootLayout';
import UnAuthRoutes from '../Routes/UnAuthRoutes';
import ForgotPassword from '../Authorization/Forgot_Password/ForgotPassword';
import ResetPassword from '../Authorization/Reset_Password/ResetPassword';
function Main(){
    return(
        <div>
        <Routes>
          <Route path="/" element={<RootLayout />}>
            {AuthRoutes}
            {UnAuthRoutes}
            <Route key="forgotPassword" path="/forgot-password" element={<ForgotPassword />} />
            <Route key="resetPassword" path="/reset-password/:userId/:token" element={<ResetPassword />} />
          </Route>
        </Routes>
      </div>
       
    )
}
export default Main;