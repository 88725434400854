import React, { useState, useEffect, useRef } from "react";
import classes from "./AboutModalBody.module.css";
import { Form } from "react-bootstrap";
import corssIcon from "../../../../../../Assets/Logo/cross-icon.svg";
import useLocalStorage from "../../../../../GlobleShared/CustomHooks/useLocalStorage";
import ProfileService from "../../../../../../Services/ProfileService"; 
import { toast } from "react-toastify"; 
import Loader from "../../../../../GlobleShared/Loader/Loader";
function AboutModalBody({ title, onClose, refreshSkills, refreshCompetency, dataLoad, parentListData}) {
const initial = useRef(null)
  //Interests
  const [interests, setInterests] = useState([]);
  const [newInterest, setNewInterest] = useState("");
  const [competency, setCompetency] = useState([]);
  const [newCompetency, setNewCompetency] = useState("");
  const [step, setStep] = useState(1);
  const [ratings, setRatings] = useState(0); 
  const [remarks, setRemarks] = useState(''); 
  const [isLoading, setIsLoading] = useState(false);

  //Skills
  const [suggestedSkills, setSuggestedSkills] = useState([]);
  const [skills, setSkills] = useState([]);
  const [newSkill, setNewSkill] = useState("");
  const [newSkills, setNewSkills] = useState([]);

  //Competency
  const [suggestedCompetencies, setSuggestedCompetencies] = useState([]);
  const [suggestedInterest, setSuggestedInterest] = useState([]);
  const [userDetail] = useLocalStorage("userDetail");
  const [studentId, setStudentId] = useState(userDetail?.refId);

  const handleRatingClick = (rating) => {
    setRatings( rating ? rating: 0 );
  };
  const getSuggestedSkills = () => {
    setIsLoading(true);
    ProfileService.getSkills().then((res)=>{
      setSuggestedSkills(res)
    setIsLoading(false);
  }).catch((error)=>{
    console.log('error: ', error);
    setIsLoading(false);
    });
  }
  const getSuggestedCompetency = () => {
    setIsLoading(true);
    ProfileService.getCompetency().then((res)=>{
      setSuggestedCompetencies(res)
    setIsLoading(false);
  }).catch((error)=>{
    console.log('error: ', error);
    setIsLoading(false);
    });
  }
  const getSuggestedInterest = () => {
    setIsLoading(true);
    ProfileService.getInterests().then((res)=>{
      setSuggestedInterest(res)
    setIsLoading(false);
  }).catch((error)=>{
    console.log('error: ', error);
    setIsLoading(false);
    });
  }
    const addInterest = () => {
      if (newInterest && !interests.includes(newInterest) && !parentListData.includes(newInterest)){
        setInterests([...interests, newInterest]);
        setNewInterest("");
    } else {
      toast.error("Duplicate interest found.");
      }
    };
  const removeInterest = (interest) => {
    setInterests(interests.filter((i) => i !== interest));
  };
  const addSkill = () => {
    if (newSkill && !newSkills.includes(newSkill) && !parentListData.includes(newSkill)) {
        setNewSkills([...newSkills, newSkill]);
        setNewSkill("");
    }
    else{
      toast.error("Duplicate skill found.");
    }
};
const getSkills = () => {
  setIsLoading(true);
    ProfileService.getStudentSkills(studentId)
        .then(res => {
            setSkills(res.map(skill => skill.Skill) || []); // Ensure the skills are correctly mapped
            setIsLoading(false);
        })
        .catch(error => {
            toast.error(error?.message);
            setIsLoading(false);
        });
};
const removeSkill = (skill, type) => {
    if (type === 'old') {
        setSkills(skills.filter(s => s !== skill));
    } else {
        setNewSkills(newSkills.filter(s => s !== skill));
    }
};

const addSuggestedSkill = (skill) => {
    if (!newSkills.includes(skill) && !parentListData.includes(skill)) {
        setNewSkills([...newSkills, skill]);
      }
      else{
        toast.error("Duplicate skill found.");
      }
};
const addSuggestedInterest = (value) => {
    if (!interests.includes(value) && !parentListData.includes(value)) {
      setInterests([...interests, value]);
      }
      else{
        toast.error("Duplicate interest found.");
      }
};
useEffect(() => {
if (!initial.current) {
    initial.current = true;
    getSkills();
    if(title === "Skills"){
      getSuggestedSkills();
    }
    if(title === "Competency"){
      getSuggestedCompetency();
    }
    if(title === "Interests"){
      getSuggestedInterest();
    }
}
}, []);


const handleSave = () => {
  setIsLoading(true);
  const payload = {
    Interest: interests.join(","),
    StudentId: studentId,
  };
  ProfileService.saveStudentInterests(payload)
    .then((res) => {
      toast.success(res.message);
      setIsLoading(false);
      dataLoad(studentId);
      onClose(true);
    })
    .catch((error) => {
      toast.error(error?.message);
      setIsLoading(false);
    });
};
const addCompetencyBySuggestion = (value) =>{
  if (step === 1) {
    if(parentListData.includes(value)) {
      toast.error("Duplicate Competency found.");
      setIsLoading(false);
      return;
    }else{
      setNewCompetency(value);
      setIsLoading(true);
      setStep(2);
      setIsLoading(false);
    }
  }
}
const addCompetency = () => {
  if (step === 1) {
    setIsLoading(true);
    if (newCompetency.length === 0  ){
      toast.error("Please add at least one competency.");
      setIsLoading(false);
      return;
    }
    if(parentListData.includes(newCompetency)) {
      toast.error("Duplicate Competency found.");
      setIsLoading(false);
      return;
    }
    setStep(2);
    setIsLoading(false);
  }
  if (step === 2) {
    setIsLoading(true);
    const payload = {
      Competency: newCompetency,
      Rating: ratings,
      Remark: remarks 
    };
    
    ProfileService.addCompetencyInfo(payload)
    .then((res) => {
      toast.success(res.message);
      setIsLoading(false);
      refreshCompetency();
      setStep(3);
    })
    .catch((error) => {
      toast.error(error?.message);
      setIsLoading(false);
    });
  }
  if(step === 3){
    onClose(true);
  }
};

  const saveSkills = () => {
    if (newSkills.length === 0) {
      toast.error("Please add at least one new skill before saving.");
      return;
    }
      setIsLoading(true);
    const request = {
      Skill: newSkills.join(","),
      StudentId: studentId,
    };

    ProfileService.addStudentSkill(request)
      .then((res) => {
        toast.success(res.message);
        setNewSkill("");
        setNewSkills([]);
        getSkills();
        setIsLoading(false);
        refreshSkills();
        onClose();
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  };
  return (
    <>
    {isLoading && <Loader/>}
      {title === "Interests" && (
        <div>
          <Form>
            <div className={classes["modal-body-block"]}>
              <div className={classes["block-1"]}>
                <div className={classes["search-bar"]}>
                  <input
                    type="text"
                    placeholder="Add your interest...."
                    value={newInterest}
                    onChange={(e) => setNewInterest(e.target.value.trim())}
                  />
                  <button
                    className={`primary-button`}
                    type="button"
                    onClick={addInterest}
                  >
                    Add
                  </button>
                </div>
                <div>
                  <Form.Check
                    type="checkbox"
                    id="custom-checkbox"
                    label="I’m interested in content with these skills"
                  />
                </div>
              </div>
              <div className={classes["block-2"]}>
                {interests.map((interest, index) => (
                  <div key={index} className={classes["added-data"]}>
                    <span className={classes["added-data-text"]}>
                      {interest}
                    </span>
                    <img
                      title="Remove"
                      className={classes["corss-icon"]}
                      src={corssIcon}
                      onClick={() => removeInterest(interest)}
                      alt=""
                    ></img>
                  </div>
                ))}
              </div>
              <div className={classes["block-4"]}>
                {/* <div>
                  <Form.Check
                    type="checkbox"
                    id="custom-checkbox"
                    label="I’m interested in content with these skills"
                  />
                </div>
                <div className={classes["suggested-text"]}>
                  Here are some suggested skills for you:
                </div> */}
                {suggestedInterest.length > 0 && <div className={classes["suggested-skills-list"]}>
                  {suggestedInterest.map((interest, index) => (
                    <div 
                    key={index} 
                    className={classes["suggested-skill"]}
                    onClick={() => addSuggestedInterest(interest.InterestName)} // Add this line
                >
                    {interest.InterestName}
                </div>
                  ))}
                </div>}
              </div>
              <div className={classes["block-3"]}>
                <button
                type="button"
                  className={`primary-button`}
                  onClick={handleSave}
                  disabled={interests.length === 0}
                >
                  {" "}
                  {isLoading ? "Saving..." : "Save"}
                </button>
              </div>
            </div>
          </Form>
        </div>
      )}
      {title === "Skills" && (
        <div>
          <Form>
            <div className={classes["modal-body-block"]}>
              <div className={classes["block-1"]}>
                <div className={classes["search-bar"]}>
                  <input
                    type="text"
                    placeholder="Find your skill...."
                    value={newSkill}
                    onChange={(e) => setNewSkill(e.target.value.trim())}
                  />
                  <button
                    className={`primary-button`}
                    type="button"
                    onClick={addSkill}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className={classes["block-2"]}>
                {/* {skills.map((skill, index) => (
        <div key={index} className={classes["added-data"]}>
            <span className={classes["added-data-text"]}>{skill}</span>
            <img title="Remove" className={classes["corss-icon"]} src={corssIcon} onClick={() => removeSkill(skill, 'old')} alt='' />
        </div>
    ))} */}
                {newSkills.map((skill, index) => (
                  <div key={index} className={classes["added-data"]}>
                    <span className={classes["added-data-text"]}>{skill}</span>
                    <img
                      title="Remove"
                      className={classes["corss-icon"]}
                      src={corssIcon}
                      onClick={() => removeSkill(skill, "new")}
                      alt=""
                    />
                  </div>
                ))}
              </div>
              <div className={classes["block-4"]}>
                <div>
                  <Form.Check
                    type="checkbox"
                    id="custom-checkbox"
                    label="I’m interested in content with these skills"
                  />
                </div>
                <div className={classes["suggested-text"]}>
                  Here are some suggested skills for you:
                </div>
              {suggestedSkills.length > 0   &&
               <div className={classes["suggested-skills-list"]}>
                  {suggestedSkills.map((skill, index) => (
                    <div 
                    key={index} 
                    className={classes["suggested-skill"]}
                    onClick={() => addSuggestedSkill(skill.SkillName)} // Add this line
                >
                    {skill.SkillName}
                </div>
                  ))}
                </div>}
              </div>
              <div className={classes["block-3"]}>
                <button
                  className={`primary-button`}
                  type="button"
                  onClick={saveSkills}
                  disabled={newSkills.length === 0}
                >
                  Save
                </button>
              </div>
            </div>
          </Form>
        </div>
      )}

      {title === "Competency" && (
        <div>
          <Form>
            {step === 1 && 
            <div className={classes["modal-body-block"]}>
              <div className={classes["block-1"]}>
                <div className={classes["search-bar"]}>
                  <input type="text" placeholder="Add your competency...."  value={newCompetency} onChange={(e) => setNewCompetency(e.target.value.trim())} />
                  <button
                    className={`primary-button`}
                    type="button"
                    onClick={addCompetency}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className={classes["block-2"]}>
                {/* {competency.map((competency, index) => (
                <div key={index} className={classes["added-data"]}>
                  <span className={classes["added-data-text"]}>
                    {competency}
                  </span>
                  <img
                    title="Remove"
                    className={classes["corss-icon"]}
                    src={corssIcon}
                    alt=""
                  ></img>
                </div>
             ))} */}
              </div>
              <div className={classes["block-4"]}>
                <div>
                  <Form.Check
                    type="checkbox"
                    id="custom-checkbox"
                    label="I’m interested in content with these competency"
                  />
                </div>
                <div className={classes["suggested-text"]}>
                  Here are some suggested competencies for you:
                </div>
              {suggestedCompetencies.length > 0 &&
                <div className={classes["suggested-skills-list"]}>
                  {suggestedCompetencies.map((suggested, index) => (
                    <div key={index} className={classes["suggested-skill"]} onClick={()=>addCompetencyBySuggestion(suggested.CompetencyName)}>
                      {suggested.CompetencyName}
                    </div>
                  ))}
                </div>}
              </div>
              <div className={classes["block-3"]}>
                <button type="button" className={`primary-button`}  disabled={newCompetency === ""}>
                  Save
                </button>
              </div>
            </div>}
            {step === 2 && (
               <div className={classes["modal-body-block"]}>

                 <div className={classes["block-1"]}>
                 <div className="text-center">
                   <span className={classes["competencyTitle"]}>{newCompetency}</span>
                 </div>
                   <div className={`${classes["rating-container"]} d-flex`}>
                   {[...Array(11)].map((_, index) => (
                          <div
                            key={index}
                            className={`${classes.ratingNumber} ${index <= ratings ? classes.activeColors : ""}`} 
                            onClick={() => handleRatingClick(index)}
                          >
                            {index}
                          </div>
                        ))}
                   </div>
                    <div className={`${classes["ratingLabel"]}`}>
                     <div>Novice</div>
                     <div>Average</div>
                     <div>Master</div>
                   </div>
                 </div>

                 {/* Remarks section */}
                 <div className={classes["block-2"]}>
                 <div className={classes["remark-textarea-block"]}>
                   <label className={classes["remarksLabel"]}>How did you get this competency:</label>
                   <textarea
                     className={classes["remarksInput"]}
                     placeholder="Type Here..."
                     value={remarks}
                     onChange={(e) => setRemarks(e.target.value)}
                     />
                 </div>
                     </div>

                 {/* Save button */}
                 <div className={classes["block-3"]}>
                   <button type="button" className={`primary-button`} onClick={addCompetency} disabled={!remarks || ratings === null}>
                     Save
                   </button>
                 </div>
               </div>
              )}
              {step === 3 && 
              <div className="text-center">
               <div className={classes['competencyText']}>{newCompetency}</div>
               <div className={classes['ratingsText']}>{ratings}</div>
               <div className={classes['remarksText']}>{remarks}</div>
              <div className={classes['footerBtn']}> 
                <button className={`primary-button`} type="button" onClick={addCompetency}>Close</button>
              </div>
              </div>
              }
          </Form>
        </div>
      )}
    </>
  );
}

export default AboutModalBody;
